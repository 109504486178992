import { Col, DatePicker, Form, Input, Row, Select, Tag, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { AccountReceiveContext } from '../context';
import QuotationModal from '../modal/quotation/quotation-modal';
import moment from 'moment';
import SummaryQuotation from '../components/summary-approved';
import ActionColumnBilling from '../components/action-column';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import { style } from 'glamor';
import { useEffect } from 'react';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'ddd, MMM DD YYYY';

const PendingAR = () => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const { isOpenQuotation, reportDataPending, summaryData, customerList, selectedRowKeys, listCreate, teamList } = state;
  const {
    seIsOpenQuotation,
    setReportDataPending,
    setSelectedRowKeys,
    setSelectAllRow,
    setSelectKeysTable,
    setListCreate,
    setIsFetch,
  } = setState;
  const {
    handleOpenModalQuotationPending,
    handleCustomerPopupScroll,
    handleOpenModalQuotationInvoice,
    handleOpenModalBillingTaskPending,
  } = func;
  const {
    quotationList,
    quotationTotal,
    paginationPage,
    loading,
    rangePickerDate,
    rangePickerDueDate,
    customerName,
    blTypeList,
    type,
  } = reportDataPending;
  const intl = useIntl();
  const statuslist = [
    { Id: 'invoiced', statusName: intl.formatMessage({ id: 'ARInvoiced', defaultMessage: 'Invoiced' }) },
    { Id: 'pending', statusName: intl.formatMessage({ id: 'ARPendingforInvoice', defaultMessage: 'Pending for Invoice' }) },
  ];
  console.log('vvvvvvvv', type, customerName);
  useEffect(() => {
    if (_.get(reportDataPending, 'customerName') === undefined) {
      setListCreate([]);
      setSelectedRowKeys([]);
      console.log('LogCustom', _.get(reportDataPending, 'customerName'));
    }
  }, [_.get(reportDataPending, 'customerName')]);

  const handleFieldSort = (value) => {
    setReportDataPending((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataPending((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataPending((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataPending((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataPending((prev) => ({ ...prev, rangePickerDate: value }));
  };

  const handleSelectDueDateRangePicker = (value) => {
    setReportDataPending((prev) => ({ ...prev, rangePickerDueDate: value }));
  };

  const handleApply = (value) => {
    setReportDataPending((prev) => ({ ...prev, paginationPage: 1 }));
    setIsFetch((event) => !event);
  };

  const handleReset = (value) => {
    setReportDataPending((prev) => ({
      ...prev,
      invoiceNo: '',
      saleOrderNo: '',
      team: '',
      rangePickerDate: [],
      paginationPage: 1,
      rangePickerDueDate: [],
    }));
    setIsFetch((event) => !event);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('invoiceLabelDocumentNo', 'Document No.'),
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('invoiceLabelType', 'Type'),
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: messageLabel('invoiceLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      render: (text, record, index) => _.get(record, 'customerName'),
    },
    {
      title: messageLabel('billingLabelItem', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: true,
      render: (text, record, index) => _.get(record, 'totalItem') || '0',
    },
    {
      title: messageLabel('invoiceLabelTotal', 'Total'),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      sorter: true,
      width: 200,
      align: 'right',
      // render: (text, record, index) => (
      //   // _.get(record, 'total') ? (
      //   <span>{numberFormatDecimal(_.get(record, 'totalNum'))}</span>
      // ),
      // ) : null,
    },
    {
      title: messageLabel('invoiceLabelDueDate', 'Due Date'),
      dataIndex: 'dueDateTxt',
      key: 'dueDateTxt',
      sorter: true,
    },
    {
      title: messageLabel('invoiceLabelTeam', 'Team'),
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      render: (text, record, index) => _.get(record, 'team') || '-',
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issueBy',
      key: 'issueBy',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('invoiceLabelIssued Date', 'Issued Date'),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: true,
      //   render: (text, record, index) =>
      //     _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format(dateFormat) : null,
    },
  ];

  // const columns = [
  //   {
  //     title: '#',
  //     dataIndex: 'index',
  //     key: 'index',
  //     align: 'center',
  //     fixed: 'left',
  //     width: 70,
  //   },
  //   // {
  //   //   title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
  //   //   dataIndex: 'quotationNo',
  //   //   key: 'quotationNo',
  //   //   sorter: true,
  //   //   fixed: 'left',
  //   //   width: 150,
  //   // },
  //   {
  //     title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
  //     dataIndex: 'saleOrderNo',
  //     key: 'saleOrderNo',
  //     sorter: true,
  //     fixed: 'left',
  //     width: 150,
  //     ellipsis: true,
  //   },
  //   {
  //     title: messageLabel('quotationLabelCustomer', 'Customer'),
  //     dataIndex: 'customerName',
  //     key: 'customerName',
  //     sorter: true,

  //     // width: 200,
  //   },
  //   {
  //     title: messageLabel('ARStatus', 'Status'),
  //     dataIndex: 'statusTxt',
  //     key: 'statusTxt',
  //     sorter: true,
  //     render: (text, record, index) => (
  //       <span>
  //         <p
  //           style={{
  //             // backgroundColor: record['statusColor'],
  //             backgroundColor: _.get(record, 'statusColor'),
  //             color: 'white',
  //             textAlign: 'center',
  //             borderRadius: '10px',
  //             padding: '0px 15px',
  //           }}
  //         >
  //           {record.statusTxt}
  //         </p>
  //       </span>
  //     ),
  //   },
  //   {
  //     title: messageLabel('quotationLabelTotal', 'Total'),
  //     dataIndex: 'totalTxt',
  //     key: 'totalTxt',
  //     sorter: true,
  //     width: 200,
  //     align: 'right',
  //   },
  //   {
  //     title: messageLabel('ARRemark', 'Remark'),
  //     dataIndex: 'remark',
  //     key: 'remark',
  //     sorter: true,
  //     width: 200,
  //     render: (text, record, index) => (_.get(record, 'remark') ? _.get(record, 'remark') : '-'),
  //   },
  //   {
  //     title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
  //     dataIndex: 'issueBy',
  //     key: 'issueBy',
  //     sorter: true,
  //     // render: (text, record, index) => '',
  //   },
  //   {
  //     title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
  //     dataIndex: 'issueDateTxt',
  //     key: 'issueDateTxt',
  //     sorter: true,
  //     // render: (text, record, index) =>
  //     //   _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('ddd, MMM DD YYYY') : null,
  //   },
  // ];

  const setShowColumn = {
    index: true,
    invoiceNo: true,
    customerName: true,
    totalItem: true,
    billingNo: true,
    statusTxt: true,
    totalTxt: true,
    issueBy: true,
    issueDateTxt: true,
    dueDateTxt: true,
    saleOrderNo: true,
    type: true,
  };

  const setShowColumnArr = [
    'index',
    'invoiceNo',
    'customerName',
    'totalItem',
    'billingNo',
    'statusTxt',
    'totalTxt',
    'issueBy',
    'issueDateTxt',
    'dueDateTxt',
    'saleOrderNo',
    'type',
  ];

  const ignoreColumn = ['team'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);

  const handleChange = (value, key) => {
    setReportDataPending((prev) => ({ ...prev, [key]: value }));
    setListCreate([]);
  };

  const handleChangeCustomer = (value, key) => {
    setReportDataPending((prev) => ({ ...prev, [key]: value, paginationPage: 1 }));
    setListCreate([]);
  };

  const advancedFilter = (action, value) => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'billingDocumentNo', defaultMessage: 'Document No.' })} req={false} />}
            >
              <Input
                allowClear
                value={_.get(value, 'invoiceNo')}
                onChange={(e) => handleChange(e.target.value, 'invoiceNo')}
                placeholder={intl.formatMessage({ id: 'billingDocumentNoPlaceholder', defaultMessage: 'Enter Document No.' })}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'SO No.' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter SO No.' })}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'billingDueDate', defaultMessage: 'Due Date' })} req={false} />}
            >
              <RangePicker
                style={{ width: '100%' }}
                placeholder={
                  intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                  ' ~ ' +
                  intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
                }
                value={rangePickerDueDate || ''}
                format="DD/MM/YYYY"
                onChange={handleSelectDueDateRangePicker}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'billingTeam', defaultMessage: 'Team' })} req={false} />}>
              <Select
                allowClear
                value={_.get(value, 'team') || undefined}
                onChange={(e) => handleChange(e, 'team')}
                placeholder={intl.formatMessage({ id: 'billingTeamPH', defaultMessage: 'Select Team' })}
              >
                {_.map((teamList), (item) => {
                  return <Option key={item.value}>{item.title}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'billingIssuedDate', defaultMessage: 'Issued Date' })} req={false} />}
            >
              <RangePicker
                style={{ width: '100%' }}
                placeholder={
                  intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                  ' ~ ' +
                  intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
                }
                value={rangePickerDate || ''}
                format="DD/MM/YYYY"
                onChange={handleSelectRangePicker}
                allowClear={true}
              />
              {/* <Select
                allowClear
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onChange={(e) => handleChange(e, 'customerName')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select> */}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handleApply()}>
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        {_.get(record, 'type') === 'Task' ? (
          <div>
            <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalBillingTaskPending(record, 'view')}>
              <FormattedMessage id="BLCreateBillingNote" defaultMessage="Create Billing Note" />
            </Button>
          </div>
        ) : (
          <div>
            <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalQuotationPending(record, 'view')}>
              <FormattedMessage id="BLCreateBillingNote" defaultMessage="Create Billing Note" />
            </Button>
          </div>
        )}
        {/* {_.get(record, 'statusTxt') !== 'Pending for Invoice' && (
          <div>
            <Button
              style={{ width: 150 }}
              type="link"
              ghost
              onClick={() => {
                handleOpenModalQuotationPending(record, 'viewinvoice');
                handleOpenModalQuotationInvoice(record, 'viewinvoice');
              }}
            >
              <FormattedMessage id="btnView" defaultMessage="View" />
            </Button>
          </div>
        )} */}

        {/* <div>
          <Button style={{ width: 150 }} type="link" ghost onClick={() => handleOpenModalQuotationPending(record, 'view')}>
            <FormattedMessage id="BLCreateBillingNote" defaultMessage="Create Billing Note" />
          </Button>
        </div> */}
      </div>
    );
  };

  const summary = {
    within3days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin3days';
    }),
    within7days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin7days';
    }),
    within15days: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'pendingwithin15days';
    }),
    overdue: _.filter(summaryData, (o) => {
      return o.summaryTypeCode === 'overdue';
    }),
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('pending start', selectedRowKeys, selectedRows, quotationList, listCreate);
      let selectList = [];
      setSelectedRowKeys(selectedRowKeys);

      for (let i = 0; _.size(selectedRowKeys) > i; i++) {
        const checkItemAdd = _.filter(listCreate, (o) => o.invoiceId === selectedRowKeys[i]); // check item Status
        console.log('peding roung', i + 1, '-----------------------------------------------------------');
        console.log('pending 1 Check', checkItemAdd, _.size(checkItemAdd) === 0, listCreate, selectedRowKeys[i]);
        if (_.size(checkItemAdd) === 0) {
          // not item Status (Add item)
          const item = _.filter(selectedRows, (item) => item.invoiceId === selectedRowKeys[i]);
          console.log('pending 2 Item', item);
          if (_.size(item) > 0) {
            console.log('pending 3 add item', item);
            selectList = _.size(selectList) ? [...selectList, item[0]] : item;
          }
        } else {
          const filterItem = _.filter(listCreate, (item) => item.invoiceId === selectedRowKeys[i]);
          if (_.size(filterItem)) {
            console.log('pending 4 delete item', filterItem);
            selectList = _.size(selectList) ? [...selectList, filterItem[0]] : filterItem;
          }
        }
      }

      console.log('pending end SelectList', selectList, selectedRowKeys);

      setListCreate(selectList);

      // _.map(selectedRows, (item) => {
      //   console.log('selectedRows: ', selectedRows);
      //   selectList.push(selectedRows);
      // });
      // setListCreate(selectList);
    },
    getCheckboxProps: (record) => ({
      disabled: customerName === '', // Column configuration not to be checked
      invoiceId: record.invoiceId,
    }),
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      {/* <div style={{ paddingTop: 20 }}>
        <SummaryQuotation summary={summary} />
      </div> */}
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <LabeRequireForm text={intl.formatMessage({ id: 'billingLabelType', defaultMessage: 'Type' })} req={false} />
              <Select
                style={{ marginLeft: '10px', paddingRight: '10px', width: '140px' }}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  // handleChange(option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, 'customerName')
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                placeholder={intl.formatMessage({ id: 'billingHintLabelType', defaultMessage: 'Select Type' })}
                onChange={(e) => handleChangeCustomer(e, 'type')}
              >
                {_.map(blTypeList, (item) => (
                  <Option key={item.code} value={item.code}>
                    {item.txt}
                  </Option>
                ))}
              </Select>
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              <Select
                style={{ marginLeft: '10px', width: '50%' }}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  // handleChange(option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, 'customerName')
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                placeholder={intl.formatMessage({ id: 'VoucherCustomerPlaceHolder', defaultMessage: 'Select Customer' })}
                onPopupScroll={handleCustomerPopupScroll}
                onSearch={(e) => handleChangeCustomer(e, 'customerName')}
                onChange={(e) => handleChangeCustomer(e, 'customerName')}
              >
                {_.map(customerList, (item, index) => (
                  <Option key={item.customerId} value={item.customerId}>
                    {item.customerName}
                  </Option>
                ))}
              </Select>
              {/* <RangePicker
                placeholder={
                  intl.formatMessage({ id: 'ARStartDate', defaultMessage: 'Start Date' }) +
                  ' ~ ' +
                  intl.formatMessage({ id: 'AREndDate', defaultMessage: 'End Date' })
                }
                value={rangePickerDate}
                format={dateFormat}
                onChange={handleSelectRangePicker}
                allowClear={true}
              /> */}

              <ActionColumnBilling
                columns={columns}
                setShowColumn={setShowColumn}
                newDataColumns={newDataColumns}
                listArrayColumns={listArrayColumns}
                defaultShowColumn={defaultShowColumn}
                newColumns={newColumns}
                setNewDataColumns={setNewDataColumns}
                setListArrayColumns={setListArrayColumns}
                setDefaultShowColumn={setDefaultShowColumn}
                textErrorSelectColumn={textErrorSelectColumn}
                setTextErrorSelectColumn={setTextErrorSelectColumn}
                visible={visible}
                setVisible={setVisible}
                columnMinimum={1}
              />
            </div>
          }
        >
          {advancedFilter(setReportDataPending, reportDataPending)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.invoiceId}
          rowSelection={customerName && type ? rowSelection : undefined}
          columns={newDataColumns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default PendingAR;
