import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import styled from 'styled-components';
import { Popover, Button } from 'antd';
import { PageSettings } from '../../config/page-settings';
import { Link, useHistory } from 'react-router-dom';

const ItemMasterTable = (props) => {
  const { loading, dataSource, columns, total, paginationPage, scrollTableX, fnc } = props;
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const history = useHistory();
  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const TotalItem = styled.div`
    display: flex;
    position: relative;
    margin-top: -42px;
    margin-left: 10px;
  `;

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };


  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost
                      onClick={() => {
                        history.push('/main/inventory/item-masters/item-detail/' + record.itemMasterId);
                      }}
                    >
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => fnc.handleOpenModal(record)}>
                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                    </Button>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => fnc.handleDelete(record)}>
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button>
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div className="maintenance-report scroll-sm" onMouseLeave={handleMouseLeave} >
      <Table
        className="maintenance-report-table"
        loading={loading}
        rowKey={(record) => record.index}
        dataSource={dataSource}
        columns={columns}
        onChange={fnc.handleChange}
        scroll={{ x: scrollTableX, y: `calc(100vh - 374px)` }}
        // scroll={{ x: scrollTableX, y: `calc(100vh - 370px)` }}
        pagination={{
          total: total,
          showTotal: showTotal,
          current: paginationPage,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: fnc.handlePagination,
          onShowSizeChange: fnc.handleSizeChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {dataSource && dataSource.length !== 0 ? (
        <TotalItem>
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </TotalItem>
      ) : null}
    </div>
  );
};

export default ItemMasterTable;
