import React, { useState, useEffect, useContext } from 'react';
import { DownOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Col, Row, Form, Select, DatePicker, Input, Dropdown, Menu, Button, Card } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import './css/index.css';
import moment from 'moment';
import { AllticketContext } from '../allticket-context';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Filtter = (props) => {
  const intl = useIntl();
  const { state, setState, fnc } = useContext(AllticketContext);
  const { ticketData, changeTab, isLoading, fillter } = state;
  const { setChangeStatus, setFillter, setChangeTab, setPage } = setState;
  const { getApiSearch, changeToApproval, changeToReject, getApiApprove, getApiReject } = fnc;
  const { columns, form, tab } = props;
  const [rangeDate, setRangeDate] = useState(false);
  const paddingFilter = '2px 16px 0px';
  const statusLsit = _.get(ticketData, 'data.statusList');

  const handleChangeDate = (value) => {
    setRangeDate(value);
    setFillter({
      ...fillter,
      ['pmdatestart']: moment(_.get(value, '[0]')).format(dateFormat),
      ['pmdateto']: moment(_.get(value, '[1]')).format(dateFormat),
    });
    handleFillter(value);
  };

  const handleFillter = (value, code) => {
    console.log('valueFill', value);
    setPage(1)
    setFillter({ ...fillter, [code]: value });
  };
  return (
    <div className="filterBackgrounds scroll-sm">
      <Form>
        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketTicketNoForm" defaultMessage="Ticket No." />

              <Input
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllTicketTicketNoEnterForm', defaultMessage: 'Enter Ticket No.' })}
                onChange={(e) => handleFillter(e.target.value, 'reno')}
                allowClear
              ></Input>
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketSubmittedDate" defaultMessage="Submitted Date" />
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllTicketSelectSubmittedDate', defaultMessage: 'Select Submitted Date' })}
                format="DD/MM/YYYY"
                onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'startdate')}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllTicketRequiredDate" defaultMessage="Required Date" />
              <DatePicker
                className="inputWidth"
                placeholder={intl.formatMessage({ id: 'AllTicketSelectRequiredDate', defaultMessage: 'Select Required Date' })}
                format="DD/MM/YYYY"
                allowClear={true}
                onChange={(e) => handleFillter(moment(e).format('YYYY-MM-DD'), 'enddate')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} style={{ padding: paddingFilter }}>
            <Form.Item>
              <FormattedMessage id="AllticketStatusForm" defaultMessage="Status" />
              <Select
                placeholder={intl.formatMessage({ id: 'AllticketEndStatueSelectForm', defaultMessage: 'Select Status' })}
                // onSelect={(e) => handleFillter(e, 'status')}
                onChange={(e) => handleFillter(e, 'status')}
                allowClear={true}
              >
                {statusLsit &&
                  _.map(statusLsit, (item, index) => {
                    return (
                      <Option key={_.get(item, 'code')} value={_.get(item, 'code')}>
                        {_.get(item, 'name')}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const FillterForm = Form.create({
  name: 'filter_form',
  mapPropsToFields() { },
})(Filtter);

export default FillterForm;
