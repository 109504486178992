import { Card } from 'antd';
import React from 'react';

const ItemAssignee = (props) => {
  const { id, lot, assignee, time, bgColor, bgMap } = props;

  return (
    <Card
      style={{
        backgroundColor: bgMap ? '#EDF7EF' : '#fff',
        border: 0,
        borderRadius: '8px',
        height: '9.24vh',
        alignContent: 'center'
      }}
      bodyStyle={{ padding: '12px', boxShadow: 0 }}
    >
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <div
          style={{
            // width: 60,
            // height: 50,
            backgroundColor: bgColor || '#808080',
            borderRadius: 4,
            border: 'unset',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 12,
            padding: '5px'
          }}
        >
          <span className='dashboard-font28' style={{ color: '#fff', fontWeight: 'bold' }}>
            {id}
          </span>
        </div>

        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 4,
            }}
          >
            <div style={{ maxWidth: '24.1vw', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 'bold' }}>
              <span className='dashboard-font18' style={{ marginRight: '1.9vw', fontWeight: 'normal' }}>From </span>
              <span className='dashboard-font18'>
                Lot {lot}
              </span>
            </div>
            <span className='dashboard-font18' style={{ position: 'absolute', right: '15px' }}>{time} min</span>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='dashboard-font18' style={{ marginRight: '0.6vw'}}>Assignee </span>
            <span className='dashboard-font18' style={{ fontWeight: 'bold', maxWidth: '20vw', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {assignee}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ItemAssignee;
