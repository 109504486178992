import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Card, Row, Col, Avatar, Badge, Icon, Divider, Modal, Menu, Dropdown, Tag } from 'antd';
import ModalEditCustomer from '../../../components/edit-customer/index';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Button03 from '../../../components/v2/button_03';
import styles from './css/information.css';
import _ from 'lodash';
import httpClient from '../../../components/axiosClient';
import { successNotification, errorNotification, warningNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';
import { useHistory } from 'react-router-dom';
import EditbusinessPartner from '../../../components/edit-business-partner/index';
import ResetPassword from '../../../components/modal-reset-password';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import imageCompany from '../../../components/image/Company.svg'
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { getBusinessTag } from '../../../components/add-customer/controller/controller';

export default ({ informationData, setTriggerAddContact, triggerAddContact, settriggerDeleteImg, triggerDeleteImg }) => {
  // const { informationData, setTriggerAddContact, triggerAddContact } = props;

  const memComId = localStorage.getItem('memComId');
  const history = useHistory();
  const intl = useIntl();
  const imageRef = useRef();
  const comCode = localStorage.getItem('comCode');
  const profileImg = _.get(informationData, 'profileImg');
  const customerShort = _.get(informationData, 'customerShortName');
  const customerName = _.get(informationData, 'customerName');
  const businessType = _.get(informationData, 'businessType');
  const customerType = _.get(informationData, 'customerType');
  const address = _.get(informationData, 'address');
  const locationName = _.get(informationData, 'locationName')
  const createdBy = _.get(informationData, 'createdBy');
  const createdDate = _.get(informationData, 'createdDate');
  const namePrivacy = _.get(informationData, 'selectedPrivacy.name');
  const codePrivacy = _.get(informationData, 'selectedPrivacy.code');
  const teamPrivacy = _.get(informationData, 'selectedPrivacy.orgIdLists');
  const customerId = _.get(informationData, 'customerId');

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [formRefEdit, setFormRefEdit] = useState(null);
  const [infoData, setInfoData] = useState(null);

  const [imageCustomer, setImageCustomer] = useState();
  const [imageFile, setImageFile] = useState();
  const [newImg, setNewImg] = useState(undefined);

  const [LocationData, setLocationData] = useState({});
  const [cusDataBodyApi, setCusDataBodyApi] = useState({});
  const [customerIdApi, setCustomerIdApi] = useState('');

  const [cusInformation, setCusInformation] = useState({});

  const [checkImgDefault, setCheckImgDefault] = useState(false);
  const [privacyData, setprivacyData] = useState();
  const [nameAuto, setNameAuto] = useState([]);
  const [visibleResetPassword, setVisibleResetPassword] = useState(false);
  const [visible, setVisible] = useState(false);

  console.log('cusInformation', cusInformation, informationData);

  useEffect(() => {
    setCusInformation(informationData)
  }, [informationData]);

  const handleEdit = () => {
    setVisible(false)
    setInfoData(informationData);
    setVisibleEdit(true);
  };

  const handleCancel = () => {
    setInfoData(null);
    setVisibleEdit(false);
    formRefEdit.resetFields();
    setImageFile('');
    setImageCustomer();
    setNewImg(undefined);
    imageRef.current.value = null;
    setCheckImgDefault(false);
    setNameAuto([
      {
        name: _.get(informationData, 'createdBy'),
        memComId: Number(_.get(informationData, 'createdById')),
      },
    ]);
  };

  const handleSaveEdit = async () => {
    // ใช้ค่านี้ส่งรูป imageCustomer //
    formRefEdit.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let cusName = infoData.customerName.includes(values.cusCode);

      try {
        const body = {
          comCode: comCode,
          customerName: values.cusName,
          customerShortName: values.cusCode,
          businessTypeId: values.busType,
          customerTypeId: values.cusType,
          address: cusDataBodyApi.description,
          fullAddress: cusDataBodyApi.address,
          locationName: cusDataBodyApi.addressName,
          lat: cusDataBodyApi.lat,
          lng: cusDataBodyApi.lng,
          // description: cusDataBodyApi.description,
          profileImg: undefined,
          phone: undefined,
          contacts: [],
          selectedPrivacy: privacyData.privacy !== undefined ? privacyData.privacy : undefined,
          privacyTeamLists: privacyData.team !== undefined ? privacyData.team : undefined,
          createdBy: values.owner,
          // addressBooks: [
          //   {
          //     customerAddressBookId: customerIdApi,
          //     addressName: cusDataBodyApi.addressName,
          //     fullAddress: cusDataBodyApi.address,
          //     address: cusDataBodyApi.address,
          //     description: cusDataBodyApi.description,
          //     lat: cusDataBodyApi.lat,
          //     lng: cusDataBodyApi.lng,
          //     district: undefined,
          //     province: undefined,
          //     postCode: undefined,
          //     subDistrict: undefined,
          //   }
          // ],
        };

        const response = await httpClient.put(
          `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
          body
        );
        if (response.status === 200) {
          successNotification(response.data.status.messageEdit);
          if (response.status && checkImgDefault == true) {
            if (imageCustomer) {
              let formData = new FormData();
              formData.append('file', imageCustomer);

              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
                  formData
                );

                setVisibleEdit(false);
                setCheckImgDefault(false);
              } catch (error) {}
            } else {
              const body = {
                profileImg: 'noimage',
              };
              try {
                const resUploadImage = await httpClient.put(
                  `/v3/business-partner/manager/company/${comCode}/customer-informations/${infoData.customerId}`,
                  body
                );

                setVisibleEdit(false);
                setCheckImgDefault(false);
              } catch (error) {}
            }
          }
          //setVisibleEdit(false);
        } else {
          errorNotification(response.data.status.messageEdit);
        }
      } catch (error) {
        // errorNotification(error.response);
        errorNotification(error.response.data.status.message);
      }

      setTriggerAddContact(!triggerAddContact);
      setImageFile('');
      setImageCustomer();
      setNewImg(undefined);
      imageRef.current.value = null;
      setVisibleEdit(false);
      formRefEdit.resetFields();
      setNameAuto([]);
    });
  };

  const saveFormRefEdit = useCallback((node) => {
    if (node !== null) {
      setFormRefEdit(node);
    }
  }, []);

  const openMap = () => {
    if (informationData && informationData !== undefined) {
      if (informationData.lat !== null && informationData.lng !== null) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${_.get(informationData, 'lat')}, ${_.get(informationData, 'lng')}`,
          '_blank'
        );
      }
    }
  };

  const handleDelete = async () => {
    setVisible(false)
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTableDeleteConfirmText', defaultMessage: 'Are you sure to delete customer ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
          if (response.status == 200) {
            setTriggerAddContact((current) => !current);
            successNotification(response.data.status.message);
            history.push('/main/crm/customers');
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) {
          errorNotification(error.response.data.status.message);
        }
      },
    });
  };

  const handleOpenResetPassword = () => {
    setVisibleResetPassword(true);
  };

  const handleEditStatus = async (statusCode) => {
    setVisible(false)

    const body = {
      statusCode: statusCode,
    }

    const response = await httpClient.put(
      `/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`,
      body
    );

    if(response.status === 200) {
      setTriggerAddContact((current) => !current);
    }

    console.log('handleEditStatus', response)

  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card
            title={intl.formatMessage({ id: 'customerInformationCardHeader', defaultMessage: 'Customer Information' })}
            // className="monitor-main-card"
            extra={
              <Dropdown
                overlay={
                  <Menu>
                    {_.get(cusInformation, 'customerStatus') && 
                    _.filter(_.get(cusInformation, 'customerStatus'), (status) => 
                      status.canChangeTo === true
                    ).map((item, index) => 
                      <Menu.Item key={index}>
                      {/* {_.get(cusInformation, 'createdById') === memComId || checkPermissionAction('P8PG2C1', 'P8PG2C1A2') ? ( */}
                      <Button01 key={index} ghost type="link" style={{ padding: 'unset', textAlign: 'left' }} onClick={() => handleEditStatus(item.statusCode)}>
                        {/* <FormattedMessage id="btnInformationOpportunity" defaultMessage="Opportunity" /> */}
                        {item.statusTxt}
                      </Button01>
                      {/* ) : null} */}
                    </Menu.Item>
                    )}
                   


                    <Divider style={{ margin: '0px' }} />
                    <Menu.Item key="11">
                      {_.get(cusInformation, 'createdById') === memComId || checkPermissionAction('P8PG2C1', 'P8PG2C1A2') ? (
                        <Button01 key="11" ghost type="link" style={{ padding: 'unset', textAlign: 'left' }} onClick={handleEdit}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button01>
                      ) : null}
                    </Menu.Item>
                    <Menu.Item key="12">
                      {checkPermissionAction('P8PG1C1', 'P8PG1C1A4') ? (
                        <Button02 key="12" ghost type="link" style={{ padding: 'unset', textAlign: 'left' }} onClick={handleDelete}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button02>
                      ) : null}
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                onVisibleChange={setVisible}
                visible={visible}
                placement="bottomRight"
                className="ant-dropdown-custom"
              >
                <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button_01>
              </Dropdown>
            }
          >
            <Row gutter={[16, 16]}>
              <Col span={6} className="avatar-alignment">
                <Avatar size={160} src={_.get(cusInformation, 'profileImg')} />
              </Col>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <p className="customer-style">
                      {customerShort ? customerShort : null}
                      <Badge color="#1D3557" className="custom-badge" />
                      {_.get(cusInformation, 'customerName')}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="customer-text-value-style">
                      <img src={imageCompany} style={{width:'17px',height:'17px', marginBottom: '3px', filter: 'sepia(1) saturate(5000%) hue-rotate(190deg) brightness(1.2)'}}/>
                      {' ' +
                        (_.get(cusInformation, 'customerType.customerTypeName') || '-') +
                        ', ' +
                        (_.get(cusInformation, 'businessType.businessTypeName') || '-')}
                    </div>
                  </Col>
                </Row>
                <Row >
                  <Col span={24}>
                    <Tag className="tag-center-style" color={'#1890FF'} style={{ textAlign: 'center', marginTop: '10px' }}>
                      {_.get(cusInformation, 'statusTxt') ? _.get(cusInformation, 'statusTxt') : undefined}
                    </Tag>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextTeam" defaultMessage="Team" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'Organization.orgName') || '-'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextOwner" defaultMessage="Owner" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'createdBy') || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextTaxNo" defaultMessage="Tax No." />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'taxNo') || '-'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="customer-text-main-style">
                      <FormattedMessage id="customerInformationTextBranch" defaultMessage="Branch" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'branchCode') || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextPhone" defaultMessage="Phone" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'phone') || '-'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextUsername" defaultMessage="Username" />
                    </div>
                    <div className="customer-text-value-style">{(_.get(cusInformation, 'username') || _.get(cusInformation, 'phone')) || '-'}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextSource" defaultMessage="Source" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'source') || '-'}</div>
                  </Col>
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextReference" defaultMessage="Reference" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'reference') || '-'}</div>
                  </Col>
                </Row>

                <Row className="customer-text-information-padding" style={{ marginTop: '20px' }}>
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextCreditBalance" defaultMessage="Credit Balance" />
                    </div>
                    <div className="customer-text-value-style">
                      {numberFormatter(_.get(cusInformation, 'creditBalance') || 0) + ' THB'}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="customer-text-main-style">
                      <FormattedMessage id="customerInformationTextCreditDay" defaultMessage="Credit Day" />
                    </div>
                    <div className="customer-text-value-style">{_.get(cusInformation, 'creditDay')}</div>
                  </Col>
                </Row>
                <Row className="customer-text-information-padding">
                  <Col span={12}>
                    <div className="customer-text-main-style ">
                      <FormattedMessage id="customerInformationTextTag" defaultMessage="Tag" />
                    </div>
                    <div className="customer-text-value-style">
                      {/* <Tag color="#fff" style={{ color: '#6390CF', border: '1px solid' }}>
                          Lead
                        </Tag>
                        <Tag color="#fff" style={{ color: '#6390CF', border: '1px solid' }}>
                          Ope
                        </Tag> */}

                      {_.map(_.get(cusInformation, 'tags'), (item) => {
                        return <Tag color="#fff" style={{ color: item.tagColor, border: '1px solid' }}>
                          {item.tagName}
                        </Tag>
                      })} 
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="address-style" style={{fontSize: '12px'}}>
                      <FormattedMessage id="customerInformationTextAddress" defaultMessage="Address" />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="customer-text-value-style">
                      <Icon
                        type="environment"
                        style={{
                          fontSize: '12px',
                          color: '#6390CF',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                          cursor: 'pointer',
                        }}
                        onClick={() => openMap()}
                      />{' '}
                      {locationName} <Badge color="#000000" className="badge-text" />{' '} {address}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Divider type="horizontal" style={{ marginBottom: '12px', marginTop: '12px' }} />
                  </Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <div className="customer-text-main-style" style={{ marginBottom: '8px' }}>
                      <FormattedMessage id="ModalCustomerCustomerRemark" defaultMessage="Remark" />
                    </div>
                    <div className="customer-text-value-style">
                      {_.get(cusInformation, 'description') || '-'}
                    </div>
                  </Col>

                  <Col span={6} style={{ marginTop: '26px', textAlign: 'end', cursor: 'pointer' }}>
                    <p className="customer-text-main-style" onClick={handleOpenResetPassword}>
                      <FormattedMessage id="customerInformationTextResetPassword" defaultMessage="Reset Password" />{' '}
                    </p>
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={24} style={{ marginTop: '22px', textAlign: 'end', cursor: 'pointer' }}>
                    <p className="customer-text-main-style" onClick={handleOpenResetPassword}>
                      <FormattedMessage id="customerInformationTextSetPassword" defaultMessage="Set Password" />{' '}
                    </p>
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Card>

          {/* <ModalEditCustomer
            ref={saveFormRefEdit}
            visible={visibleEdit}
            onCancel={handleCancel}
            onCreate={handleSaveEdit}
            informationData={infoData}
            image={{
              imageRef,
              imageFile,
              setImageFile,
              imageCustomer,
              setImageCustomer,
              newImg,
              setNewImg
            }}
            setCustomer={{
              setLocationData,
              setCusDataBodyApi,
              setCustomerIdApi
            }}
            setCheckImgDefault={setCheckImgDefault}
            checkImgDefault={checkImgDefault}
            setprivacyData={setprivacyData}
            privacyData={privacyData}
            nameAuto={nameAuto}
            setNameAuto={setNameAuto}
          /> */}

          <EditbusinessPartner
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            setTrigger={setTriggerAddContact}
            CustomerData={{
              valuePrivacy: _.get(infoData, 'valuePrivacy'),
              Organization: { orgId: _.get(infoData, 'Organization.orgId'), orgName: _.get(infoData, 'Organization.orgName') },
              address: _.get(infoData, 'address'),
              businessType: _.get(infoData, 'businessType.businessTypeName'),
              businessTypeId: _.get(infoData, 'businessType.businessTypeId'),
              comCode: _.get(infoData, 'comCode'),
              createdAt: _.get(infoData, 'createdDate'),
              createdBy: _.get(infoData, 'createdBy'),
              createdById: _.get(infoData, 'createdById'),
              customerCode: _.get(infoData, 'customerShortName'),
              customerId: _.get(infoData, 'customerId'),
              customerName: _.get(infoData, 'customerName'),
              customerType: _.get(infoData, 'customerType.customerTypeName'),
              customerTypeId: _.get(infoData, 'customerType.customerTypeId'),
              description: _.get(infoData, 'description'),
              index: 1,
              indexDate: undefined,
              lat: _.get(infoData, 'lat'),
              lng: _.get(infoData, 'lng'),
              locationName: _.get(infoData, 'locationName'),
              phone: _.get(infoData, 'phone'),
              position: '',
              profileImg: _.get(infoData, 'profileImg'),
              profileImgDefault: _.get(infoData, 'profileImgDefault'),
              profileImgHashFile: _.get(infoData, 'profileImgHashFile'),
              taxNo: _.get(infoData, 'taxNo'),
              branchCode: _.get(infoData, 'branchCode'),
              source: _.get(infoData, 'source'),
              creditDay: _.get(infoData, 'creditDay'),
              creditBalance: _.get(infoData, 'creditBalance'),
              reference: _.get(infoData, 'reference'),
              tags: _.get(infoData, 'tags')
            }}
            setCustomerData={setInfoData}
          />

          <ResetPassword id={customerId} visible={visibleResetPassword} setVisible={setVisibleResetPassword} />
        </div>
      )}
    </PageSettings.Consumer>
  );
};
