import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Company from '../pages/login/index';
import Dashboard from '../pages/dashboard/dashboard';
import Users from '../pages/users/user/users_ex';
import UserDetail from '../pages/users/user/index';
import MyProfile from '../pages/menu/manageAccount/manageAccount';
import Role from '../pages/role/Role';
import Permission from '../pages/permission/Permission';
import Organization from '../pages/organization/index-new';
import TimeAttendance from '../pages/report/time-attendance/index';
import OverTimeReport from '../pages/report/over-time-report/index';
import LeaveReport from '../pages/report/leave-report/index';
import OutsideReport from '../pages/report/outside-report/index';
import ResourceAssetExpenseReportForm from '../pages/report/asset-expense-report';
import TaskReport from '../pages/transportation/report/task-report/index';
import MapSpeedTracking from '../pages/transportation/map-speed-tracking/index';
import MapSpeedTrackingForce from '../pages/workforce/map-speed-tracking/index';
import ShiftTypeIndex from '../../src/pages/setting/index';
import Holidays from '../../src/pages/setting/holiday/index';
import Customer from '../../src/pages/customer/index';
import RequestType from '../pages/setting/request-type/index';
import TaskType from '../pages/setting/task-type/index';
import Notification from '../pages/notification/index';
import CustomerIndex from '../../src/pages/customer/index';
import CustomerDetail from '../../src/pages/customer/user-detail/index';
import TravelReport from '../pages/report/travel-expense-report/index';
import Invoice from '../pages/invoice/index';
import SaleOrder from '../pages/sale-order/index';
import EquipmentReport from '../pages/report/equipment-tools-report/index';
import License from '../pages/license/index';
import MaintenanceReport from '../pages/maintenance-report/index';
import ItemMaster from '../pages/item-master/index';
import ItemMasterDetail from '../pages/item-master/item-master-detail';
import Vendor from '../pages/vendor/index';
import Skill from '../pages/setting/skill/index';
import VendorDetail from '../pages/vendor/user-detail/index';
import ItemPage from '../pages/item/index';
import ServiceArea from '../pages/service-area/index';
import CompanyProfile from '../pages/setting/company/index';
import Engagement from '../pages/engagement/index';
import Quotation from '../pages/quotation/index';
import TravelExprense from '../pages/travel-expenses/index';
import BenefitDeductReport from '../pages/report/benefit-deduct-report/index';
import PageExpense from '../pages/expense';

//-------Import Transport --------
import TransportTation from '../pages/transportation-new/index';
import TravelReportTranSport from '../pages/transportation/report/travel-expense-report/index';
import MemberResourceTask from '../pages/transportation/report/travel-expense-by-member/index';
import TaskReportTranSport from '../pages/transportation/report/task-report/index';
import GasExpenseTransport from '../pages/transportation/report/gas-expense-report/index';
import TaskAssigmentTransport from '../pages/transportation/report/task-assigment/index';
import TaskTypeTransport from '../pages/transportation/setting/task-type/index';
import MaintenanceReportTransport from '../pages/transportation/asset-maintenent/index';
import TravelExprenseTranSport from '../pages/transportation/travel-expenses/index';
import TaskDistance from '../pages/task-distance/index';
import PlanningComponent from '../pages/load-and-route/index';
import OrderComponent from '../pages/orders/index';
import DeliveryOrder from '../pages/delivery-order';
import Allowance from '../pages/setting/allowance';
import Size from '../pages/size';
import PageTripAllowance from '../pages/transporation-setting/trip-allowance';
import PageAllowance from '../pages/transporation-setting/allowance';
import PageFreight from '../pages/transporation-setting/freight';

//-------Import Workforce --------
import Workforce from '../pages/workforce-new/index';
import TravelExprenseWorkForce from '../pages/workforce/travel-expenses/index';
import TravelReportWorkForce from '../pages/workforce/report/travel-expense-report/index';
import TaskReportWorkForce from '../pages/workforce/report/task-report/index';
import TaskTypeWorkForce from '../pages/workforce/setting/task-type/index';
import MaintenanceReportWorkForce from '../pages/workforce/asset-maintenent/index';
import TaskAssigmentTransportWorkForce from '../pages/workforce/report/task-assigment/index';

//-------Import WorkDesk --------
import WorkDesk from '../pages/workdesk/index';
import TravelExprenseorkDesk from '../pages/workdesk/travel-expenses/index';
import TravelReportWorkDesk from '../pages/workdesk/report/travel-expense-report/index';
import TaskReportWorkDesk from '../pages/workdesk/report/task-report/index';
import TaskTypeWorkDesk from '../pages/workdesk/setting/task-type/index';
import MaintenanceReportWorkDesk from '../pages/workdesk/asset-maintenent/index';
import AssetSummaryReport from '../pages/asset-summary-report/index';
import ProjectPages from '../pages/project';
import ProjectDetail from '../pages/project/project-detail';
import AssetDetailList from '../pages/asset-summary-report/asset-detail';
import WarehousePage from '../pages/warehouse';
import UOMPage from '../pages/uom/index';
import Point from '../pages/point';
import WarehouseReportInstock from '../pages/warehouse-report/instock';
import WarehouseReportItemRequest from '../pages/warehouse-report/itemrequest';
import WarehouseReportItemMovement from '../pages/warehouse-report/item-movement';

//-------Import Request --------
import Approval from '../pages/approval';
import ItemRequest from '../pages/item-request';
import FinePage from '../pages/fine/index';
import Gasprice from '../pages/gasprice';
import MyRequest from '../pages/myrequest';
import AllTicket from '../pages/allticket';
import RepairTicketReport from '../pages/report/repair-ticket-report';
import QuotationProviderPage from '../pages/quotation-new';

//-------Import Request --------

import PerchesOrder from '../pages/po';
import { SaleOrderProvider } from '../pages/sale-order-new/context';
import SaleOrderProviderPage from '../pages/sale-order-new';
import ReciveItemPO from '../pages/receive-Item-po';
import AccountReceivablePage from '../pages/account-receivable';
import ARReport from '../pages/ar-report';
import ARReportNew from '../pages/ar-report-new';
import VoucherPage from '../pages/voucher';
import TranferItemMainPage from '../pages/tranferitem';
import AccoutingReportReceive from '../pages/accouting-report/receive';
import AccoutingReportReconcile from '../pages/accouting-report/reconcile';
import AllOrder from '../pages/allorder';
import InvoiceProviderPage from '../pages/invoice-new';
import ItemSalesReport from '../pages/customer-report/item-sales-report';
import BillingNotePage from '../pages/billing-note';
import WarehouseReportReceiveItemFromPoForm from '../pages/warehouse-report/receive-item-from-po';
import ItemPurchaseReport from '../pages/item-purchase-report';
import SalesOrderReport from '../pages/customer-report/sales-order-report';
import PageResourceAllowance from '../pages/resource-allowance';
import PagePettyCashDetail from '../pages/petty-cash/detail';
import PagePettyCash from '../pages/petty-cash/main';
import PageMemberExpense from '../pages/member-expense';
import DeliveryOrderNew from '../pages/delivery-order-new';
import OverviewProviderPage from '../pages/taeco-overview';

const routes = [
  {
    path: '/main/not-found',
    title: '',
    component: () => <div></div>,
  },

  //------------------------------- DashBord ------------------------------------------//
  {
    menuCode: 'dashboard',
    path: '/main/dashboard',
    title: <FormattedMessage id="menuDashboard" defaultMessage="Home" />,
    component: () => <Dashboard />,
    pages: 'P1PG1',
    actions: 'P1PG1A1',
    licenseCode: 'lc_dashboard',
  },
  //------------------------------- DashBord ------------------------------------------//

  //------------------------------- Resource ------------------------------------------//
  {
    menuCode: 'organization',
    path: '/main/resource/organization',
    title: <FormattedMessage id="menuOrganization" defaultMessage="Organization" />,
    component: () => <Organization />,
    exact: true,
    pages: 'P3PG1',
    actions: 'P3PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    path: '/main/resource/organization/users-detail/:user_id',
    title: <FormattedMessage id="menuUserDetail" defaultMessage="Users Detail" />,
    component: () => <UserDetail />,
    pages: 'P3PG1C5',
    actions: 'P3PG1C5A4',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'asset',
    path: '/main/resource/asset',
    title: <FormattedMessage id="menuAessetSummary" defaultMessage="Asset Summary Report" />,
    component: () => <AssetSummaryReport />,
    exact: true,
    pages: 'P36PG1',
    actions: 'P36PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'asset',
    path: '/main/resource/asset/asset-detail/:id',
    title: <FormattedMessage id="menuAessetSummary" defaultMessage="Asset Summary Report" />,
    component: () => <AssetDetailList />,
    exact: true,
    pages: 'P36PG1',
    actions: 'P36PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'time-attendance',
    path: '/main/resource/report/time-attendance',
    title: <FormattedMessage id="menuTimeAttendance" defaultMessage="Time Attendance" />,
    component: () => <TimeAttendance />,
    pages: 'P4S1PG1',
    actions: 'P4S1PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'overt-time',
    path: '/main/resource/report/overtime',
    title: <FormattedMessage id="menuOverTimeReport" defaultMessage="Over Time Report" />,
    component: () => <OverTimeReport />,
    pages: 'P4S2PG1',
    actions: 'P4S2PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'leave',
    path: '/main/resource/report/leave-report',
    title: <FormattedMessage id="menuLeaveReport" defaultMessage="Leave Report" />,
    component: () => <LeaveReport />,
    exact: true,
    pages: 'P4S3PG1',
    actions: 'P4S3PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'outside',
    path: '/main/resource/report/outside',
    title: <FormattedMessage id="reportOutsideReportTitle" defaultMessage="Outside Report" />,
    component: () => <OutsideReport />,
    pages: 'P4S4PG1',
    actions: 'P4S4PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'asset-expense-report',
    path: '/main/resource/report/asset-expense-report',
    title: <FormattedMessage id="reportAssetExpenseReportTitle" defaultMessage="Asset Expense Report" />,
    component: () => <ResourceAssetExpenseReportForm />,
    pages: 'P82PG1',
    actions: 'P82PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'equipment',
    path: '/main/resource/report/borrow',
    title: 'Equipment & Tools Report',
    component: () => <EquipmentReport />,
    pages: 'P4S7PG1',
    actions: 'P4S7PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'benefit-deduct-report',
    path: '/main/resource/report/benefit-deduct',
    title: <FormattedMessage id="reportBenefitReportTitle" defaultMessage="Benefit & Deduct Report" />,
    component: () => <BenefitDeductReport />,
    pages: 'P37PG1',
    actions: 'P37PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'request-type',
    path: '/main/resource/setting/request-type',
    title: <FormattedMessage id="menuRequestType" defaultMessage="Request Type" />,
    component: () => <RequestType />,
    pages: 'P6S1PG1',
    actions: 'P6S1PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'shift-type',
    path: '/main/resource/setting/shift-type',
    title: <FormattedMessage id="menuShiftType" defaultMessage="Shift Type" />,
    component: () => <ShiftTypeIndex />,
    pages: 'P6S2PG1',
    actions: 'P6S2PG1A1',
    licenseCode: 'lc_resource',
  },

  {
    menuCode: 'holidays',
    path: '/main/resource/setting/holidays',
    title: <FormattedMessage id="menuHolidays" defaultMessage="Holidays" />,
    component: () => <Holidays />,
    pages: 'P6S4PG1',
    actions: 'P6S4PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'skill',
    path: '/main/resource/setting/skill',
    title: <FormattedMessage id="menuSkill" defaultMessage="Skill" />,
    component: () => <Skill />,
    pages: 'P15PG1',
    actions: 'P15PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'service-area',
    path: '/main/resource/setting/service-area',
    title: <FormattedMessage id="menuServiceArea" defaultMessage="Service Area" />,
    component: () => <ServiceArea />,
    pages: 'P18PG1',
    actions: 'P18PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'fine',
    path: '/main/resource/fine',
    title: <FormattedMessage id="menuFine" defaultMessage="Fine" />,
    component: () => <FinePage />,
    exact: true,
    pages: 'P51PG1',
    actions: 'P51PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'resource-allowance',
    path: '/main/resource/allowance',
    title: <FormattedMessage id="menuResourceAllowance" defaultMessage="Allowance" />,
    component: () => <PageResourceAllowance />,
    exact: true,
    pages: 'P3PG1',
    actions: 'P3PG1A1',
    licenseCode: 'lc_resource',
  },
  {
    menuCode: 'member-expense',
    path: '/main/resource/member-expense',
    title: <FormattedMessage id="menuMemberExpense" defaultMessage="Member Expense" />,
    component: () => <PageMemberExpense />,
    exact: true,
    pages: 'P3PG1',
    actions: 'P3PG1A1',
    licenseCode: 'lc_resource',
  },
  //------------------------------- Resource ------------------------------------------//

  //------------------------------- CRM -----------------------------------------------//
  {
    menuCode: 'crm-customer',
    path: '/main/crm/customers',
    title: <FormattedMessage id="menuCustomer" defaultMessage="Customer" />,
    component: () => <CustomerIndex />,
    exact: true,
    pages: 'P8PG1',
    actions: 'P8PG1A1',
    licenseCode: 'lc_crm',
  },

  {
    path: '/main/crm/customers/customer-detail/:customer_id',
    title: <FormattedMessage id="menuCustomerDetail" defaultMessage="Customer Detail" />,
    component: () => <CustomerDetail />,
    pages: 'P8PG1C1',
    actions: 'P8PG1C1A1',
    licenseCode: 'lc_crm',
  },

  {
    menuCode: 'crm-engagement',
    path: '/main/crm/engagement',
    title: <FormattedMessage id="menuEngagement" defaultMessage="Engagement" />,
    component: () => <Engagement />,
    pages: 'P26PG1',
    actions: 'P26PG1A1',
    licenseCode: 'lc_crm',
  },
  
  {
    menuCode: 'order-report',
    path: '/main/crm/allorder',
    title: <FormattedMessage id="menuOrderReport" defaultMessage="All Order" />,
    component: () => <AllOrder />,
    exact: true,
    pages: 'P71PG1',
    actions: 'P71PG1A1',
    licenseCode: 'lc_crm',
  },
 
  //------------------------------- CRM -----------------------------------------------//

  //------------------------------- Sales -----------------------------------------------//

  {
    menuCode: 'transportation-order',
    path: '/main/sales/store',
    title: <FormattedMessage id="menuTransportationOrder" defaultMessage="Store" />,
    // component: () => <OverviewProviderPage />,
    component: () => <DeliveryOrder />,
    pages: 'P58PG1',
    actions: 'P58PG1A1',
    licenseCode: 'lc_sales',
  },

  {
    menuCode: 'crm-quotation',
    path: '/main/sales/quotation',
    title: <FormattedMessage id="menuQuotation" defaultMessage="Quotation" />,
    component: () => <QuotationProviderPage />,
    pages: 'P28PG1',
    actions: 'P28PG1A1',
    licenseCode: 'lc_sales',
  },

  {
    menuCode: 'crm-sale-order',
    path: '/main/sales/sale-order',
    title: <FormattedMessage id="menuSaleOrder" defaultMessage="Sale Order" />,
    component: () => <SaleOrderProviderPage />,
    pages: 'P11PG1',
    actions: 'P11PG1A1',
    licenseCode: 'lc_sales',
  },

  {
    menuCode: 'accounting-invoice',
    path: '/main/sales/invoice',
    title: <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />,
    component: () => <InvoiceProviderPage />,
    pages: 'P10PG1',
    actions: 'P10PG1A1',
    licenseCode: 'lc_sales',
  },

  {
    menuCode: 'item-sale-report',
    path: '/main/sales/report/item-sales',
    title: <FormattedMessage id="menuItemSaleReport" defaultMessage="Item Sales Report" />,
    component: () => <ItemSalesReport />,
    // exact: true,
    pages: 'P73PG1',
    actions: 'P73PG1A1',
    licenseCode: 'lc_sales',
  },

  {
    menuCode: 'sale-order-report',
    path: '/main/sales/report/sale-order-report',
    title: <FormattedMessage id="menuSaleOrderReport" defaultMessage="Sale Orders Report" />,
    component: () => <SalesOrderReport />,
    pages: 'P84PG1',
    actions: 'P84PG1A1',
    licenseCode: 'lc_sales',
  },


  //------------------------------- Sales -----------------------------------------------//


  //------------------------------- Transporation -------------------------------------//
  // {
  //   menuCode: 'transportation-monitor',
  //   path: '/main/transporation/monitor',
  //   title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
  //   component: () => <Monitor pageCode='P5' LangCode='' />,
  //   pages: 'P5PG1',
  //   actions: 'P5PG1A1',
  //   licenseCode: 'lc_transportation'
  // },
  {
    menuCode: 'transportation-monitor',
    path: '/main/transporation/monitor',
    title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
    component: () => <TransportTation pageCode="P5" LangCode="" />,
    pages: 'P5PG1',
    actions: 'P5PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    menuCode: 'travel-expenses',
    path: '/main/transporation/travel-expenses',
    title: <FormattedMessage id="menuTravelExpenses" defaultMessage="Travel Expense" />,
    component: () => <TravelExprenseTranSport />,
    pages: 'P50PG1',
    actions: 'P50PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    path: '/main/monitor/map/speed-tracking/:id',
    title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
    component: () => <MapSpeedTracking />,
    pages: 'P5PG1',
    actions: 'P5PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'transportation-planning',
    path: '/main/transporation/planning',
    title: <FormattedMessage id="menuPlaning" defaultMessage="Load Planning" />,
    component: () => <PlanningComponent />,
    // component: () => <DeliveryOrderNew />,
    pages: 'P34PG1',
    actions: 'P34PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'delivery-order-list',
    path: '/main/transporation/delivery-orders',
    title: <FormattedMessage id="menuDeliveryOrderList" defaultMessage="Delivery Orders" />,
    component: () => <DeliveryOrderNew />,
    pages: 'P85PG1',
    actions: 'P85PG1A1',
    licenseCode: 'lc_transportation',
  },
  // {
  //   menuCode: 'transportation-order',
  //   path: '/main/transporation/orders',
  //   title: <FormattedMessage id="menuTransportationOrder" defaultMessage="Orders" />,
  //   component: () => <OrderComponent />,
  //   pages: 'P38PG1',
  //   actions: 'P38PG1A1',
  //   licenseCode: 'lc_transportation',
  // },

  {
    menuCode: 'resourcetasksummary',
    path: '/main/transporation/report/resourc-task-summary',
    title: <FormattedMessage id="reportResourceTaskSummaryReportViewTitle" defaultMessage="Resource Task Summary Report" />,
    component: () => <TravelReportTranSport />,
    pages: 'P4S6PG1',
    actions: 'P4S6PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    menuCode: 'driver-task-summary',
    path: '/main/transporation/report/driver-task-summary',
    title: <FormattedMessage id="menuDriverTaskSummary" defaultMessage="Driver Expenses Report" />,
    component: () => <MemberResourceTask />,
    pages: 'P35PG1',
    actions: 'P35PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    menuCode: 'task-report',
    path: '/main/transporation/report/task',
    title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
    component: () => <TaskReportTranSport />,
    pages: 'P4S5PG1',
    actions: 'P4S5PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'task-type',
    path: '/main/transporation/setting/task-type',
    title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
    component: () => <TaskTypeTransport />,
    pages: 'P6S3PG1',
    actions: 'P6S3PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'task-distance',
    path: '/main/transporation/setting/task-distance',
    title: <FormattedMessage id="menuTaskDistance" defaultMessage="Task Distance" />,
    component: () => <TaskDistance />,
    pages: 'P6S5PG1',
    actions: 'P6S5PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    menuCode: 'allowance-setting',
    path: '/main/transporation/setting/allowance',
    title: <FormattedMessage id="menuAllowanceSetting" defaultMessage="Allowance" />,
    component: () => <PageAllowance />,
    pages: 'P77PG1',
    actions: 'P77PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'trip-allowance-setting',
    path: '/main/transporation/setting/trip-allowance',
    title: <FormattedMessage id="menuTripAllowanceSetting" defaultMessage="Trip Allowance" />,
    component: () => <PageTripAllowance />,
    pages: 'P78PG1',
    actions: 'P78PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'gas-price-setting',
    path: '/main/transporation/setting/freight',
    title: <FormattedMessage id="menuGasPriceSetting" defaultMessage="Freight" />,
    component: () => <PageFreight />,
    pages: 'P79PG1',
    actions: 'P79PG1A1',
    licenseCode: 'lc_transportation',
  },

  {
    menuCode: 'transportation-asset-maintenance',
    path: '/main/transporation/maintenace',
    title: <FormattedMessage id="menuMaintenanceReport" defaultMessage="Maintenance Report" />,
    component: () => <MaintenanceReportTransport />,
    pages: 'P21PG1',
    actions: 'P21PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'gas-expense-report',
    path: '/main/transporation/report/gas-expense',
    title: <FormattedMessage id="menuGasExpenseReport" defaultMessage="Gas Expense" />,
    component: () => <GasExpenseTransport />,
    pages: 'P27PG1',
    actions: 'P27PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'task-assignment-report',
    path: '/main/transporation/report/task-assignment',
    title: <FormattedMessage id="menuTaskAssignmentReport" defaultMessage="Task Assigment Report" />,
    component: () => <TaskAssigmentTransport />,
    // pages: 'P35PG1A1',
    // actions: 'P27PG1A1',
    pages: 'P40PG1',
    actions: 'P40PG1A1',
    licenseCode: 'lc_transportation',
  },
  // {
  //   menuCode: 'allowance',
  //   path: '/main/transporation/setting/allowance',
  //   title: <FormattedMessage id="menuAllowance" defaultMessage="Allowance" />,
  //   component: () => <Allowance />,
  //   pages: 'P46PG1',
  //   actions: 'P46PG1A1',
  //   licenseCode: 'lc_transportation',
  // },
  {
    menuCode: 'gas-price',
    path: '/main/transporation/setting/gasprice',
    title: <FormattedMessage id="menuGasprice" defaultMessage="Gas Price" />,
    component: () => <Gasprice />,
    pages: 'P6S3PG1',
    actions: 'P6S3PG1A1',
    licenseCode: 'lc_transportation',
  },
  {
    menuCode: 'parcel-size',
    path: '/main/transporation/setting/size',
    title: <FormattedMessage id="menuParcelSize" defaultMessage="Size" />,
    component: () => <Size />,
    pages: 'P59PG1',
    actions: 'P59PG1A1',
    licenseCode: 'lc_transportation',
  },

  //------------------------------- Transporation -------------------------------------//

  //------------------------------- WorkForce -------------------------------------//
  {
    menuCode: 'workforce-monitor',
    path: '/main/workforce/monitor',
    title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
    component: () => <Workforce pageCode="P22" LangCode="WorkForce" />,
    pages: 'P22PG1',
    actions: 'P22PG1A1',
    licenseCode: 'lc_workforce',
  },

  {
    menuCode: 'travel-expenses',
    path: '/main/workforce/travel-expenses',
    title: <FormattedMessage id="menuTravelExpenses" defaultMessage="Travel Expense" />,
    component: () => <TravelExprenseWorkForce />,
    pages: 'P5PG1',
    actions: 'P5PG1A1',
    licenseCode: 'lc_workforce',
  },

  {
    path: '/main/workforce/map/speed-tracking/:id',
    title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
    component: () => <MapSpeedTrackingForce />,
    pages: 'P5PG1',
    actions: 'P5PG1A1',
    licenseCode: 'lc_workforce',
  },
  {
    menuCode: 'workforce-resourcetasksummary',
    path: '/main/workforce/report/resourc-task-summary',
    title: <FormattedMessage id="reportResourceTaskSummaryReportViewTitle" defaultMessage="Resource Task Summary Report" />,
    component: () => <TravelReportWorkForce />,
    pages: 'P24PG1',
    actions: 'P24PG1A1',
    licenseCode: 'lc_workforce',
  },
  {
    menuCode: 'workforce-task-report',
    path: '/main/workforce/report/task',
    title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
    component: () => <TaskReportWorkForce />,
    pages: 'P23PG1',
    actions: 'P23PG1A1',
    licenseCode: 'lc_workforce',
  },

  {
    menuCode: 'workforce-task-type',
    path: '/main/workforce/setting/task-type',
    title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
    component: () => <TaskTypeWorkForce />,
    pages: 'P25PG1',
    actions: 'P25PG1A1',
    licenseCode: 'lc_workforce',
  },
  {
    menuCode: 'transportation-asset-maintenance',
    path: '/main/workforce/maintenace',
    title: <FormattedMessage id="menuMaintenanceReport" defaultMessage="Maintenance Report" />,
    component: () => <MaintenanceReportWorkForce />,
    pages: 'P21PG1',
    actions: 'P21PG1A1',
    licenseCode: 'lc_workforce',
  },
  {
    menuCode: 'task-assignment-report-w',
    path: '/main/workforce/report/task-assignment',
    title: <FormattedMessage id="menuTaskAssignmentReportW" defaultMessage="Task Assigment Report" />,
    component: () => <TaskAssigmentTransportWorkForce />,
    pages: 'P41PG1',
    actions: 'P41PG1A1',
    licenseCode: 'lc_workforce',
  },
  //------------------------------- WorkForce -------------------------------------//

  //------------------------------- WorkDesk -------------------------------------//
  {
    menuCode: 'workdesk-monitor',
    path: '/main/workdesk/monitor',
    title: <FormattedMessage id="menuMonitor" defaultMessage="Monitor" />,
    component: () => <WorkDesk pageCode="P30" LangCode="WorkDesk" />,
    pages: 'P30PG1',
    actions: 'P30PG1A1',
    licenseCode: 'lc_workdesk',
  },

  // {
  //   menuCode: 'travel-expenses',
  //   path: '/main/workdesk/travel-expenses',
  //   title: (
  //     <FormattedMessage
  //       id="menuTravelExpenses"
  //       defaultMessage="Travel Expense"
  //     />
  //   ),
  //   component: () => <TravelExprenseWorkForce />,
  //   pages: 'P5PG1',
  //   actions: 'P5PG1A1',
  //   licenseCode: 'lc_workdesk',
  // },
  {
    menuCode: 'workdesk-resourcetasksummary',
    path: '/main/workdesk/report/resourc-task-summary',
    title: <FormattedMessage id="reportResourceTaskSummaryReportViewTitle" defaultMessage="Resource Task Summary Report" />,
    component: () => <TravelReportWorkDesk />,
    pages: 'P32PG1',
    actions: 'P32PG1A1',
    licenseCode: 'lc_workdesk',
  },
  {
    menuCode: 'workdesk-task-report',
    path: '/main/workdesk/report/task',
    title: <FormattedMessage id="reportTaskReportViewTitle" defaultMessage="Task Report" />,
    component: () => <TaskReportWorkDesk />,
    pages: 'P31PG1',
    actions: 'P31PG1A1',
    licenseCode: 'lc_workdesk',
  },

  {
    menuCode: 'workdesk-task-type',
    path: '/main/workdesk/setting/task-type',
    title: <FormattedMessage id="menuTaskType" defaultMessage="Task Type" />,
    component: () => <TaskTypeWorkDesk />,
    pages: 'P33PG1',
    actions: 'P33PG1A1',
    licenseCode: 'lc_workdesk',
  },

  {
    menuCode: 'transportation-asset-maintenance',
    path: '/main/workdesk/maintenace',
    title: <FormattedMessage id="menuMaintenanceReport" defaultMessage="Maintenance Report" />,
    component: () => <MaintenanceReportWorkDesk />,
    pages: 'P21PG1',
    actions: 'P21PG1A1',
    licenseCode: 'lc_workdesk',
  },
  //------------------------------- WorkDesk -------------------------------------//

  //------------------------------- Sourcing ------------------------------------------//
  {
    menuCode: 'sourcing-vendor',
    path: '/main/sourcing/vendors',
    title: 'Vendor',
    component: () => <Vendor />,
    exact: true,
    pages: 'P14PG1',
    actions: 'P14PG1A1',
    licenseCode: 'lc_sourcing_procurement',
  },

  {
    path: '/main/sourcing/vendors/vendor-detail/:vendor_id',
    title: <FormattedMessage id="menuVendorDetail" defaultMessage="Vendor Detail" />,
    component: () => <VendorDetail />,
    pages: 'P14PG2',
    actions: 'P14PG2A1',
    licenseCode: 'lc_sourcing_procurement',
  },

  {
    menuCode: 'sourcing-procurement-po',
    path: '/main/sourcing/po',
    title: <FormattedMessage id="menuPO" defaultMessage="Purchase Order" />,
    component: () => <PerchesOrder />,
    exact: true,
    pages: 'P56PG1',
    actions: 'P56PG1A1',
    licenseCode: 'lc_sourcing_procurement',
  },

  {
    menuCode: 'item-purchase-report',
    path: '/main/sourcing/report/item-purchase',
    title: <FormattedMessage id="menuItemPurchaseReport" defaultMessage="Item Purchase Report" />,
    component: () => <ItemPurchaseReport />,
    exact: true,
    pages: 'P76PG1',
    actions: 'P76PG1A1',
    licenseCode: 'lc_sourcing_procurement',
  },
  //------------------------------- Sourcing ------------------------------------------//

  //------------------------------- Inventory -----------------------------------------//
  {
    menuCode: 'inventory-item-masterdata',
    path: '/main/inventory/item-masters',
    title: <FormattedMessage id="menuItemMaster" defaultMessage="Item Master" />,
    component: () => <ItemMaster />,
    exact: true,
    pages: 'P16PG1',
    actions: 'P16PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    path: '/main/inventory/item-masters/item-detail/:item_master_id',
    title: <FormattedMessage id="menuItemMaster" defaultMessage="Item Master" />,
    component: () => <ItemMasterDetail />,
    pages: 'P16PG1',
    actions: 'P16PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'inventory-item',
    path: '/main/inventory/item',
    title: <FormattedMessage id="menuItem" defaultMessage="Item" />,
    component: () => <ItemPage />,
    pages: 'P11PG1',
    actions: 'P11PG1A1',
    licenseCode: 'lc_inventory',
  },

  {
    menuCode: 'inventory-asset-maintenance',
    path: '/main/inventory/maintenace',
    title: <FormattedMessage id="menuMaintenanceReport" defaultMessage="Maintenance Report" />,
    component: () => <MaintenanceReport pageCode="P13" />,
    pages: 'P13PG1',
    actions: 'P13PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'warehouse-sub',
    path: '/main/inventory/warehouse-sub',
    title: <FormattedMessage id="menuWarehouseParent" defaultMessage="Warehouse" />,
    component: () => <WarehousePage />,
    exact: true,
    pages: 'P52PG1',
    actions: 'P52PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'uom',
    path: '/main/inventory/setting/uom',
    title: <FormattedMessage id="menuUOM" defaultMessage="Unit of Measures (UOM)" />,
    component: () => <UOMPage />,
    pages: 'P1PG1',
    actions: 'P1PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'itemRequest',
    path: '/main/inventory/item-request',
    title: <FormattedMessage id="menuItemRequest" defaultMessage="Item Request" />,
    component: () => <ItemRequest />,
    pages: 'P45PG1',
    actions: 'P45PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'receive-item-po',
    path: '/main/inventory/receiveItemfromPO',
    title: <FormattedMessage id="menuReceiveItemPo" defaultMessage="Receive Item from PO" />,
    component: () => <ReciveItemPO />,
    pages: 'P60PG1',
    actions: 'P60PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'warehouse-transfer',
    path: '/main/inventory/tranferitem',
    title: <FormattedMessage id="menuWarehouseTransfer" defaultMessage="Tranfer Item" />,
    component: () => <TranferItemMainPage />,
    pages: 'P65PG1',
    actions: 'P65PG1A1',
    licenseCode: 'lc_inventory',
  },
  {
    menuCode: 'warehouse-report-instock',
    path: '/main/inventory/report/instock',
    title: <FormattedMessage id="menuWarehouseInstock" defaultMessage="In Stock Report" />,
    component: () => <WarehouseReportInstock />,
    exact: true,
    pages: 'P66PG1',
    actions: 'P66PG1A1',
    licenseCode: 'lc_inventory',
  },

  {
    menuCode: 'warehouse-report-itemrequest',
    path: '/main/inventory/report/item-request',
    title: <FormattedMessage id="menuWarehouseItemRequest" defaultMessage="Item Request" />,
    component: () => <WarehouseReportItemRequest />,
    exact: true,
    pages: 'P67PG1',
    actions: 'P67PG1A1',
    licenseCode: 'lc_inventory',
  },

  {
    menuCode: 'item-movement-report',
    path: '/main/inventory/report/item-movement',
    title: <FormattedMessage id="menuWarehouseItemMovement" defaultMessage="Item Movement Report" />,
    component: () => <WarehouseReportItemMovement />,
    exact: true,
    pages: 'P72PG1',
    actions: 'P72PG1A1',
    licenseCode: 'lc_inventory',
  },

  {
    menuCode: 'po-receive-report',
    path: '/main/inventory/report/receive-item-from-po',
    title: <FormattedMessage id="menuPoReciveReport" defaultMessage="Receive Item from PO Report" />,
    component: () => <WarehouseReportReceiveItemFromPoForm />,
    exact: true,
    pages: 'P75PG1',
    actions: 'P75PG1A1',
    licenseCode: 'lc_inventory',
  },


  //------------------------------- Inventory -----------------------------------------//

  //------------------------------- Accounting ----------------------------------------//
  // {
  //   menuCode: 'accounting-invoice',
  //   path: '/main/accounting/invoice',
  //   title: <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />,
  //   component: () => <Invoice />,
  //   pages: 'P10PG1',
  //   actions: 'P10PG1A1',
  //   licenseCode: 'lc_accounting_finance',
  // },

  {
    menuCode: 'accounting-expense',
    path: '/main/accounting/expense',
    title: <FormattedMessage id="menuExpense" defaultMessage="Expense" />,
    component: () => <PageExpense />,
    pages: 'P64PG1',
    actions: 'P64PG1A1',
    licenseCode: 'lc_accounting_finance',
  },

  {
    menuCode: 'petty-cash',
    path: '/main/accounting/petty-cash',
    title: <FormattedMessage id="menuPettyCash" defaultMessage="Petty Cash" />,
    component: () => <PagePettyCash />,
    exact: true,
    pages: 'P83PG1',
    actions: 'P83PG1A1',
    licenseCode: 'lc_accounting_finance',
  },
  {
    path: '/main/accounting/petty-cash/detail/:id',
    title: <FormattedMessage id="menuPettyCashDetail" defaultMessage="Petty Cash Details" />,
    component: () => <PagePettyCashDetail />,
    pages: 'P83PG1',
    actions: 'P83PG1A1',
    licenseCode: 'lc_accounting_finance',
  },
  {
    menuCode: 'report-accounting-receive-payment',
    path: '/main/accounting/report/receive-payment',
    title: <FormattedMessage id="menuReportReceivePayment" defaultMessage="Receive and Payment" />,
    component: () => <AccoutingReportReceive />,
    pages: 'P68PG1',
    actions: 'P68PG1A1',
    licenseCode: 'lc_accounting_finance',
  },

  {
    menuCode: 'report-accounting-reconcile',
    path: '/main/accounting/report/reconcile',
    title: <FormattedMessage id="menuReportReconcile" defaultMessage="Reconcile Report" />,
    component: () => <AccoutingReportReconcile />,
    pages: 'P68PG1',
    actions: 'P68PG1A1',
    licenseCode: 'lc_accounting_finance',
  },

  //------------------------------- Accounting ----------------------------------------//

  //------------------------------- Notification --------------------------------------//
  {
    menuCode: 'notification',
    path: '/main/notification',
    title: <FormattedMessage id="menuNotification" defaultMessage="Notification" />,
    component: () => <Notification />,
    pages: 'P9PG1',
    actions: 'P9PG1A1',
    licenseCode: 'lc_notification',
  },

  //------------------------------- Notification --------------------------------------//

  //------------------------------- Company ------------------------------------------//

  {
    menuCode: 'company-profile',
    path: '/main/company/company-profile',
    title: <FormattedMessage id="menuCompanyProfile" defaultMessage="Company Profile" />,
    component: () => <CompanyProfile />,
    pages: 'P19PG1',
    actions: 'P19PG1A1',
    licenseCode: 'lc_company',
  },

  {
    menuCode: 'license',
    path: '/main/company/license',
    title: <FormattedMessage id="menuLicense" defaultMessage="License" />,
    component: () => <License />,
    exact: true,
    pages: 'P12S1PG1',
    actions: 'P12S1PG1A1',
    licenseCode: 'lc_company',
  },

  //------------------------------- Company ------------------------------------------//

  //------------------------------- User Mangaement ------------------------------------------//

  {
    menuCode: 'users',
    path: '/main/user-management/users',
    title: <FormattedMessage id="menuUser" defaultMessage="Users" />,
    component: () => <Users />,
    exact: true,
    pages: 'P2S1PG1',
    actions: 'P2S1PG1A1',
    licenseCode: 'lc_user_management',
  },
  {
    path: '/main/user-management/users/users-detail/:user_id',
    title: <FormattedMessage id="menuUserDetail" defaultMessage="Users Detail" />,
    component: () => <UserDetail />,
    pages: 'P2S1PG2',
    actions: 'P2S1PG2A1',
    licenseCode: 'lc_user_management',
  },
  {
    path: '/main/user-management/my-profile/:user_id',
    title: <FormattedMessage id="menuMyProfile" defaultMessage="My Profile" />,
    component: () => <MyProfile />,
    pages: 'user-management',
    actions: 'my-profile',
    licenseCode: 'lc_user_management',
  },
  {
    menuCode: 'role',
    path: '/main/user-management/role',
    title: <FormattedMessage id="menuRole" defaultMessage="Role" />,
    component: () => <Role />,
    pages: 'P2S2PG1',
    actions: 'P2S2PG1A1',
    licenseCode: 'lc_user_management',
  },
  {
    menuCode: 'permission',
    path: '/main/user-management/permission',
    title: <FormattedMessage id="menuPermissions" defaultMessage="Permission" />,
    component: () => <Permission />,
    pages: 'P2S3PG1',
    actions: 'P2S3PG1A1',
    licenseCode: 'lc_user_management',
  },
  {
    menuCode: 'project-profile',
    path: '/main/project/list',
    title: <FormattedMessage id="menuProject" defaultMessage="Project" />,
    exact: true,
    component: () => <ProjectPages />,
    pages: 'P39PG1',
    actions: 'P39PG1A1',
    licenseCode: 'lc_project',
  },
  {
    menuCode: 'Project Details',
    path: '/main/project/list/project-detail/:project_id',
    title: <FormattedMessage id="menuProjectDetail" defaultMessage="Project Detail" />,
    component: () => <ProjectDetail />,
    pages: 'P39PG2',
    actions: 'P39PG2A1',
    licenseCode: 'lc_project',
  },

  //------------------------------- Request ------------------------------------------//
  {
    menuCode: 'approve-request',
    path: '/main/request/approval',
    title: <FormattedMessage id="menuApproveRequestSub" defaultMessage="To Review" />,
    component: () => <Approval />,
    exact: true,
    pages: 'P49PG2',
    actions: 'P49PG2A1',
    licenseCode: 'lc_request',
  },
  {
    menuCode: 'my-request',
    path: '/main/request/myrequest',
    title: <FormattedMessage id="menuMyrequest" defaultMessage="My Request" />,
    component: () => <MyRequest />,
    exact: true,
    pages: 'P49PG1',
    actions: 'P49PG1A1',
    licenseCode: 'lc_request',
  },

  //------------------------------- Ticket ------------------------------------------//

  {
    menuCode: 'ticket-all',
    path: '/main/ticket/allticket',
    title: <FormattedMessage id="menuTicketAll" defaultMessage="All Ticket" />,
    component: () => <AllTicket />,
    exact: true,
    pages: 'P53PG1',
    actions: 'P53PG1A1',
    licenseCode: 'lc_ticket',
  },
  {
    menuCode: 'repair-ticket-report',
    path: '/main/ticket/report/repiar-ticket',
    title: <FormattedMessage id="menuRepairTicketReport" defaultMessage="Repair Ticket" />,
    component: () => <RepairTicketReport />,
    exact: true,
    pages: 'P53PG1',
    actions: 'P53PG1A1',
    licenseCode: 'lc_ticket',
  },

  //------------------------------- Campaign ------------------------------------------//
  {
    menuCode: 'point',
    path: '/main/campaign/point',
    title: <FormattedMessage id="menuPoint" defaultMessage="Point" />,
    component: () => <Point />,
    exact: true,
    pages: 'P54PG1',
    actions: 'P54PG1A1',
    licenseCode: 'lc_campaign',
  },
  {
    menuCode: 'voucher',
    path: '/main/campaign/voucher',
    title: <FormattedMessage id="menuVoucher" defaultMessage="Voucher" />,
    component: () => <VoucherPage />,
    exact: true,
    pages: 'P63PG1',
    actions: 'P63PG1A1',
    licenseCode: 'lc_campaign',
  },

  //------------------------------- Account ------------------------------------------//

  {
    menuCode: 'accounting-receivable',
    path: '/main/account/account-receivable',
    title: <FormattedMessage id="accountingReceivable" defaultMessage="Account Receivable" />,
    component: () => <BillingNotePage />,
    pages: 'P61PG1',
    actions: 'P61PG1A1',
    licenseCode: 'lc_accounting_finance',
  },
  {
    menuCode: 'accounting-receivable-report',
    path: '/main/account/account-receivable-report',
    title: <FormattedMessage id="accountingReceivableReport" defaultMessage="AR Report" />,
    component: () => <ARReportNew />,
    pages: 'P62PG1',
    actions: 'P62PG1A1',
    licenseCode: 'lc_accounting_finance',
  },

  //------------------------------- Order ------------------------------------------//
  // {
  //   menuCode: 'order-report',
  //   path: '/main/order/allorder',
  //   title: <FormattedMessage id="menuOrderReport" defaultMessage="All Order" />,
  //   component: () => <AllOrder />,
  //   exact: true,
  //   pages: 'P71PG1',
  //   actions: 'P71PG1A1',
  //   licenseCode: 'lc_order',
  // },

  //------------------------------- User Mangaement ------------------------------------------//
  // {
  //   menuCode: 'warehouse-sub',
  //   path: '/main/warehouse-parent/warehouse-sub',
  //   title: <FormattedMessage id="menuWarehouseParent" defaultMessage="Warehouse" />,
  //   component: () => <WarehousePage />,
  //   exact: true,
  //   pages: 'P1PG1',
  //   actions: 'P1PG1A1',
  //   licenseCode: 'lc_warehouse',
  // },

   //------------------------------- Dashboard -----------------------------------------------//

   {
    menuCode: 'overview-taeco',
    path: '/main/dashboard-taego/overview',
    title: <FormattedMessage id="menuOverviewTaeco" defaultMessage="Overview" />,
    component: () => <OverviewProviderPage />,
    pages: 'P1PG1',
    actions: 'P1PG1A1',
    licenseCode: 'lc_dashboard_taeco',
  },


  //------------------------------- Dashboard -----------------------------------------------//

];

export default routes;
