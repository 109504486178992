import React, { useEffect, useState, useContext, useRef } from 'react';
import { Table, Card, Avatar, Button, Modal, Popover, Dropdown, Menu, Tabs, Tag } from 'antd';
import httpClient from '../../../components/axiosClient';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/customer.css';
import { PageSettings } from '../../../config/page-settings';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Addcustomer from '../../../components/add-business-partner/index';
import _ from 'lodash';
import EditbusinessPartner from '../../../components/edit-business-partner/index';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { StickyContainer } from 'react-sticky';
import CustomerAction from './action';
import { useHistory } from 'react-router-dom';
import { getTabCustomer } from '../../../controllers/customer-vendor/customer-vendor';
import CutomerTable from './customer-table';
import { getBusinessTag, getBusinessType, getCustomerType } from '../../../components/add-customer/controller/controller';
import moment from 'moment';
import ModalDashboard from '../../../components/modal-dashboard';

const { TabPane } = Tabs

export const CustomerContext = React.createContext()

export default () => {
  const { checkPermissionAction } = useContext(PageSettings);
  const intl = useIntl();
  const memComId = localStorage.getItem('memComId');

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => record.index,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      width: 70,
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'profileImg',
      key: 'profileImg',
      render: (text, record, index) => <Avatar src={record.profileImg} />,
      fixed: 'left',
      width: 70,
    },
    {
      title: <FormattedMessage id="customerTableColumnCode" defaultMessage="Code" />,
      dataIndex: 'customerCode',
      key: 'customerCode',
      fixed: 'left',
      width: 160,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnName" defaultMessage="Name" />,
      dataIndex: 'customerName',
      key: 'customerName',
      width: 300,
      sorter: 'true',
      sortDirections: ['descend', 'ascend'],
      // render: (text, record, index) => {
      //   if (record.customerName) {
      //     return (
      //       <PageSettings.Consumer>
      //         {({ setPageSidebarTitle, checkPermissionAction }) =>
      //           checkPermissionAction('P8PG2', 'P8PG2A1') ? (
      //             <List.Item.Meta
      //               key={index}
      //               title={
      //                 <Link
      //                   onClick={() => setPageSidebarTitle(['Customer Detail'])}
      //                   style={{ fontWeight: 'initial' }}
      //                   to={'/main/crm/customers/customer-detail/' + record.customerId}
      //                 >
      //                   {record.customerName}
      //                 </Link>
      //               }
      //             />
      //           ) : (
      //             record.customerName
      //           )
      //         }
      //       </PageSettings.Consumer>
      //     );
      //   }
      // },
    },
    {
      title: <FormattedMessage id="customerTableColumnBusinessTeam" defaultMessage="Team" />,
      dataIndex: 'team',
      key: 'team',
      // sorter: 'false',
      width: 200,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => _.get(record, 'Organization.orgName')
    },
    {
      title: <FormattedMessage id="customerTableColumnBusinessType" defaultMessage="Business Type" />,
      dataIndex: 'businessType',
      key: 'businessType',
      sorter: 'true',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnCustomerType" defaultMessage="Customer Type" />,
      dataIndex: 'customerType',
      key: 'customerType',
      sorter: 'true',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: <FormattedMessage id="customerTableColumnAddress" defaultMessage="Address" />,
    //   dataIndex: 'address',
    //   key: 'address',
    //   width: 450,
    //   ellipsis: true,
    // },
    {
      title: <FormattedMessage id="customerTableColumnBranch" defaultMessage="Branch" />,
      dataIndex: 'branchCode',
      key: 'branchCode',
      sorter: 'true',
      width: 250,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnSource" defaultMessage="Source" />,
      dataIndex: 'source',
      key: 'source',
      sorter: 'true',
      width: 250,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: <FormattedMessage id="customerTableColumnCreatedBy" defaultMessage="Created By" />,
    //   dataIndex: 'createdBy',
    //   key: 'createdBy',
    //   sorter: 'true',
    //   width: 250,
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: <FormattedMessage id="customerTableColumnCreatedDate" defaultMessage="Created Date" />,
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   sorter: 'true',
    //   width: 200,
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: <FormattedMessage id="customerTableColumnReference2" defaultMessage="Reference" />,
      dataIndex: 'reference',
      key: 'reference',
      sorter: 'true',
      width: 250,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <FormattedMessage id="customerTableColumnTag" defaultMessage="Tag" />,
      dataIndex: 'tags',
      key: 'tags',
      // sorter: 'true',
      width: 200,
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => 
        _.size(_.get(record, 'tags')) > 0 
          ? _.map(_.get(record, 'tags'), (tag) => 
              <Tag style={{color: '#6490CF', backgroundColor: '#fff', borderColor: '#6490CF'}}>
                {tag}
              </Tag>
            )
        : '-'
    },
    {
      title: <FormattedMessage id="customerTableColumnCreatedDate" defaultMessage="Created Date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: 'true',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const setShowColumn = {
    index: true,
    profileImg: true,
    customerCode: true,
    customerName: true,
    team: true,
    businessType: true,
    customerType: true,
    // address: true,
    // createdBy: true,
    // createdAt: true,
    // options: false,
    branchCode: true,
    source: true,
    reference: true,
    tags: true,
    createdAt: true,
  };

  const setShowColumnArr = [
    'index',
    'profileImg',
    'customerCode',
    'customerName',
    'businessType',
    'customerType',
    'team',
    // 'address',
    // 'createdBy',
    // 'createdAt',
    // 'options',
    'branchCode',
    'source',
    'reference',
    'tags',
    'createdAt',
  ];
  const [keyTabMonitor, setKeyTabMonitor] = useState()
  const ignoreColumn = ['options'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const comCode = localStorage.getItem('comCode');
  const [customerData, setCustomerData] = useState();
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [actionColumns, setActionColumns] = useState([...newColumns]);
  const [fieldChang, setFieldChang] = useState('');
  const [selectSearchData, setSelectSearchData] = useState('all');
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [visibleCustomer, setvisibleCustomer] = useState(false);
  const [triggerCustomer, setTriggerCustomer] = useState(false);
  const [customerDataDefalse, setCustomerDataDefalse] = useState({});
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [searchGroup, setSearchGroup] = useState({})
  const [trigger, setTrigger] = useState(false)
  const history = useHistory()
  const [tabList, setTabList] = useState([])
  const [listBusinessTag, setListBusinessTag] = useState([])
  const [customerType, setCustomerType] = useState([]);
  const [businessType, setBusinessType] = useState([]);

  useEffect(() => {
    const getInitial = async () => {
      const response = await getTabCustomer()
      const isDefault = _.find(_.get(response, 'data.data'), (item) => { return item.isDefault === true })
      setTabList(_.get(response, 'data.data'))
      setKeyTabMonitor(_.get(isDefault, 'stageCode'))

      const getTagData = await getBusinessTag()
      setListBusinessTag(_.get(getTagData, 'data.data'))

      const getCustomerTypeTnfo = await getCustomerType();
      setCustomerType(getCustomerTypeTnfo);

      const getBusinessTypeTnfo = await getBusinessType();
      setBusinessType(getBusinessTypeTnfo);

    }
    getInitial()
  }, [])


  useEffect(() => {
    getAndSearchData();
  }, [
      selectSearchData, 
      fieldChang, 
      paginationPage, 
      paginationSize, 
      orderSort, 
      fieldSort, 
      triggerCustomer, 
      trigger, 
    ]);

    useEffect(() => {
      setPaginationPage(1)
      getAndSearchData();
    }, [_.get(searchGroup, 'searchDate')])

    useEffect(() => {
    setSearchGroup({})
    setPaginationPage(1)

    setTrigger((event) => !event)
    }, [keyTabMonitor])


  const getAndSearchData = async () => {
    const body = {
      // searchBy: selectSearchData,
      // searchVal: fieldChang,
      page: fieldChang === '' ? paginationPage : 1,
      limit: paginationSize,
      orderBy: orderSort,
      orderField: fieldSort,

      startDate: _.get(searchGroup, 'searchDate[0]') ? moment(_.get(searchGroup, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
      endDate: _.get(searchGroup, 'searchDate[1]') ? moment(_.get(searchGroup, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
      customerName: _.get(searchGroup, 'customerName'),
      customerCode: _.get(searchGroup, 'customerCode'),
      businessTypeId: _.get(searchGroup, 'businessType'),
      customerTypeId: _.get(searchGroup, 'customerType'),
      branchCode: _.get(searchGroup, 'branch'),
      source: _.get(searchGroup, 'source'),
      tags: _.get(searchGroup, 'tag'),
      statusCode: keyTabMonitor,

    };

    console.log('SearchGroup', searchGroup, body, keyTabMonitor)

    try {
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/search-v2`, body);
      console.log('customerDataList', response)
      if (response.status == 200) {
        setCustomerData(response.data.customers);
        setTotal(response.data.allCustomers)
      } else {
      }
    } catch (error) {
      return;
    }
  };

  const handleExport = async () => {
    const body = {
      page: 1,
      limit: total,
      orderBy: orderSort,
      orderField: fieldSort,

      startDate: _.get(searchGroup, 'searchDate[0]') ? moment(_.get(searchGroup, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
      endDate: _.get(searchGroup, 'searchDate[1]') ? moment(_.get(searchGroup, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
      customerName: _.get(searchGroup, 'customerName'),
      customerCode: _.get(searchGroup, 'customerCode'),
      businessTypeId: _.get(searchGroup, 'businessType'),
      customerTypeId: _.get(searchGroup, 'customerType'),
      branchCode: _.get(searchGroup, 'branch'),
      source: _.get(searchGroup, 'source'),
      tags: _.get(searchGroup, 'tag'),
      statusCode: keyTabMonitor,
      
      viewFormat: [
        {
          colName: intl.formatMessage({ id: 'customerTableColumnCode', defaultMessage: 'Code' }),
          sequence: 1,
          colCode: 'customerCode',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnName', defaultMessage: 'Name' }),
          sequence: 2,
          colCode: 'customerName',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnBusinessTeam', defaultMessage: 'Team' }),
          sequence: 3,
          colCode: 'Organization.orgName',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnBusinessType', defaultMessage: 'Business Type' }),
          sequence: 4,
          colCode: 'businessType',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnCustomerType', defaultMessage: 'Customer Type' }),
          sequence: 5,
          colCode: 'customerType',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnBranch', defaultMessage: 'Branch' }),
          sequence: 6,
          colCode: 'branchCode',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnSource', defaultMessage: 'Source' }),
          sequence: 7,
          colCode: 'source',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnReference2', defaultMessage: 'Reference' }),
          sequence: 8,
          colCode: 'reference',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnTag', defaultMessage: 'Tag' }),
          sequence: 9,
          colCode: 'tags',
        },
        {
          colName: intl.formatMessage({ id: 'customerTableColumnCreatedDate', defaultMessage: 'Created Date' }),
          sequence: 10,
          colCode: 'createdAt',
        },
      ],
    };

    try {
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/export`, body);
      console.log('ExportCustomer', response.data);
      if (response.status == 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      return;
    }
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationPage(current);
    setPaginationSize(size);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handlerDelete = (customerId) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'customerTableDeleteConfirmText', defaultMessage: 'Are you sure to delete customer ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/v3/business-partner/manager/company/${comCode}/customer-informations/${customerId}`);
          if (response.status == 200) {
            setSelectSearchData('all');
            setTriggerCustomer((current) => !current);
            successNotification(response.data.status.message);
          } else {
            errorNotification(response.data.status.message);
          }
        } catch (error) { }
      },
    });
  };

  const handleEditCustomer = (record) => {
    setCustomerDataDefalse(record);
    setVisibleEdit(true);
  };

  const linkToView = (record) => {
    history.push('/main/crm/customers/customer-detail/' + record.customerId);
  };

  const menuColumn = () => {
    return (
      <Menu>
        {checkPermissionAction('P8PG1C1', 'P8PG1C1A2') ? (
          <Menu.Item
            key="1"
            onClick={() => {
              setvisibleCustomer(true);
            }}
          >
            <Button key="1" ghost type="link">
              <FormattedMessage id="customerActionButtonAddCustomer" defaultMessage="Add Customer" />
            </Button>
          </Menu.Item>
        ) : null}
        <Menu.Item key="2" onClick={() => handleExport()}>
          <Button key="2" ghost type="link">
            <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const callback = (key) => {
    setKeyTabMonitor(key);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P8PG1C1', 'P8PG1C1A1') ? (
          <CustomerContext.Provider
            value={{
              searchGroup,
              setSearchGroup,
              paginationPage,
              setPaginationPage,
              setShowColumn,
              newDataColumns,
              listArrayColumns,
              defaultShowColumn,
              newColumns,
              setNewDataColumns,
              setListArrayColumns,
              setDefaultShowColumn,
              textErrorSelectColumn,
              setTextErrorSelectColumn,
              keyTabMonitor,
              visible,
              setVisible,
              setTrigger,
              actionColumns,
              customerData,
              handleChange,
              linkToView,
              handleEditCustomer,
              handlerDelete,
              memComId,
              total,
              handlePagination,
              handleSizeChange,
              listBusinessTag,
              customerType,
              businessType,
              keyTabMonitor
            }}
          >
            <Card
              className="customer-card"
              bodyStyle={{ padding: 'unset', overflowY: 'auto', height: 'calc(100vh - 120px)' }}
            >
              <StickyContainer>
                <Tabs
                  className="customer-tabs"
                  size="large"
                  defaultActiveKey="1"
                  onTabClick={callback}
                  activeKey={keyTabMonitor}
                  animated={false}
                  tabBarExtraContent={
                    <div className="customer-extra-tabs">
                      <Dropdown
                        overlay={menuColumn}
                        trigger={['click']}
                        placement="bottomRight"
                        className="ant-dropdown-custom"
                      >
                        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => { }}>
                          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                        </Button_01>
                      </Dropdown>
                    </div>
                  }
                >
                  {
                    _.map(tabList, (item) => (
                      <TabPane tab={item.stageName} key={item.stageCode}>
                        <CutomerTable tabListName={item.stageName}/>
                      </TabPane>
                    ))
                  }
                </Tabs>
              </StickyContainer>

              <Addcustomer visible={visibleCustomer} setVisible={setvisibleCustomer} setTrigger={setTriggerCustomer} />

              <EditbusinessPartner
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                setTrigger={setTriggerCustomer}
                CustomerData={customerDataDefalse}
                setCustomerData={setCustomerDataDefalse}
              />
            </Card>
          </CustomerContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
