import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import LabeRequireForm from '../../../../../components/label-required-form';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import CollapseCustom from '../../../../../components/collapse-custom';
import { getEmployeeAndTeamType } from '../../../../../controllers/project-new/member';

const { Option } = Select;
const InputGroup = Input.Group;

const FilterSetting = ({
  search,
  setSearch,
  handleApply,
  handleReset,
  onFilterOrder,
  extra,
  searchMemberValue,
  handleSearchMember,
  handleSubmitSearch,
  handleResetSearch,
}) => {
  const intl = useIntl();

  const [dataOptionEmAndTeamType, setDataOptionEmAndTeamType] = useState([]);
  useEffect(() => {
    const getType = async () => {
      try {
        const response = await getEmployeeAndTeamType();
        console.log(response, 'Fetched Response');
        setDataOptionEmAndTeamType(response);
      } catch (error) {
        console.error('Error fetching employee and team types:', error);
      }
    };

    getType();
  }, []);

  const formFilter = () => {
    return (
      <Form layout="vertical" className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'projectDetailSettingColumnsName', defaultMessage: 'Name' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHName',
                  defaultMessage: 'Enter Name',
                })}
                onChange={(e) => handleSearchMember('name', e.target.value)}
                value={_.get(searchMemberValue, 'name')}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'projectDetailSettingColumnsMemberType', defaultMessage: 'Member Type' })}
                  req={false}
                />
              }
            >
              <Select
                value={_.get(searchMemberValue, 'memberType') || undefined}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHMemberType',
                  defaultMessage: 'Select Member Type',
                })}
                onChange={(e) => handleSearchMember('memberType', e || null)}
              >
                <Option value="team">Team</Option>
                <Option value="user">User</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'projectDetailSettingColumnsEmployAndType', defaultMessage: 'Employee or Team Type' })}
                  req={false}
                />
              }
            >
              <Select
                value={_.get(searchMemberValue, 'emOrTeamType') || undefined}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHEmployeeOrTeamType',
                  defaultMessage: 'Select Employee or Team Type',
                })}
                onChange={(e) => handleSearchMember('emOrTeamType', e || null)}
              >
                {_.map(dataOptionEmAndTeamType, (item, index) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'projectDetailSettingColumnsPhone', defaultMessage: 'Phone' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHPhone',
                  defaultMessage: 'Enter Phone',
                })}
                onChange={(e) => handleSearchMember('phone', e.target.value)}
                value={_.get(searchMemberValue, 'phone')}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'projectDetailSettingColumnsEmail', defaultMessage: 'Email' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHEmail',
                  defaultMessage: 'Enter Email',
                })}
                onChange={(e) => handleSearchMember('email', e.target.value)}
                value={_.get(searchMemberValue, 'email')}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'projectDetailSettingColumnsPriority', defaultMessage: 'Priority' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'projectDetailSettingPHPriority',
                  defaultMessage: 'Enter Priority',
                })}
                onChange={(e) => handleSearchMember('priority', e.target.value)}
                value={_.get(searchMemberValue, 'priority')}
              />
            </Form.Item>
          </Col>

          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="back"
                btnsize="wd_df"
                onClick={() => {
                  handleResetSearch();
                }}
              >
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02
                style={{ margin: '0px 0px 0px 10px' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleSubmitSearch()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })} extra={extra ? extra : null}>
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default FilterSetting;
