import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { getGenderAndPosition } from './add-user/controller/controller-api.js';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Row,
  Col,
  Typography,
  Icon,
  List,
  Form,
  notification,
  Button,
  Modal,
  Input,
  Select,
  DatePicker,
  ConfigProvider,
  Divider,
} from 'antd';
import Button03 from '../../../components/v2/button_03';
import Button01 from '../../../components/v2/button_01';
import { motion } from 'framer-motion';
import Card from '../../../components/v2/card';
import { useAppContext } from '../../../includes/indexProvider';
import AppAvatar from '../../../components/avatar';
import AppSwitch from '../../../components/switch';
import ModalResetPassword from './personalInfomation-resetPassword';
import styled from 'styled-components';
import HttpClient from '../../../components/httpClient';
import clientNew from '../../../components/axiosClient';
import { PageSettings } from '../../../config/page-settings';
import Resizer from 'react-image-file-resizer';
import httpClients from '../../../components/httpClient';
// import { file } from 'babel-types';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import 'moment/locale/th';
import _ from 'lodash';
import pathUrt from '../../../function/pathUrl';
import ModalEditUser from '../../../components/modal-edit-user-new';

import TaskIcon from '../../../components/image/TaskBlack.svg';
import LineIcon from '../../../components/image/line.svg';

import cssStyle from './css/personalInfomation.css';

const client = HttpClient();
const { Title } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const BagIcon = () => <img src={TaskIcon} style={{ width: '18px', color: '#707070' }}></img>;
const LineIconn = () => <img src={LineIcon} style={{ width: '18px', color: '#707070' }}></img>;

const PersanalFormEdit = (props) => {
  const mApp = useContext(PageSettings);

  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const intl = useIntl();
  const app = useAppContext();
  const switchStatus = app.state.show.switch;
  const [personalData, setPersonalData] = useState([]);
  const [getStatus, setGetStatus] = useState(false);
  const [imageProfile, setImageProfile] = useState('');
  const [visibleModalResetPassword, setVisibleModalResetPassword] = useState(false);
  const [personalMemId, setPersonalMemId] = useState('');

  const [dataDropDown, setDataDropDown] = useState([]);

  const [visibleEdit, setVisibleEdit] = useState(false);

  const today = moment(new Date());
  const dateFormat = 'DD/MM/YYYY';
  const dateFormatNew = 'ddd, MMM DD, YYYY';

  // รับค่าภาษามาแสดง
  const labelShow = props.labelShow;
  const langValue = app.state.langValue;
  const [locale, setLocale] = useState(enUS);

  const langValueState = _.get(app, 'state.langValue');
  moment.locale(langValueState);
  const comCode = localStorage.getItem('comCode');
  const checKForm = props.checkRoute;

  const checkPathUrl = pathUrt('/main/user-management/my-profile/');

  useEffect(() => {
    changeLocale();
  }, []);

  useEffect(() => {
    getGenderAndPosition(comCode, setDataDropDown);
  }, [comCode]);

  const changeLocale = () => {
    // let codeLang = langValue ? langValue : 'EN';
    let changeLang = langValueState === 'EN' ? enUS : thTH;
    setLocale(changeLang);

    if (changeLang == 'EN') {
      moment.locale('en');
    } else if (changeLang == 'TH') {
      moment.locale('th-th');
    }
  };
  const param = useParams();

  const userId = props.user_id;

  const imageRef = useRef();
  const datePicker = useRef();
  const [selectedFile, setSelectedFile] = useState();

  const searchUserIndex = `${imageProfile}`.indexOf('/img/user-img');
  const searchNonIndex = `${imageProfile}`.indexOf('/img/non-img');

  const editFieldRef = useRef();
  const [refreshPersanal, setRefreshPersanal] = useState(false);

  const [checkedResigDate, setCheckResignDate] = useState(false);
  const [checkedStartDate, setCheckStartDate] = useState(false);

  // get api data user ใช้ใน card personal Infomation
  useEffect(() => {
    fetchData();
  }, [imageProfile, refreshPersanal, userId]);

  const fetchData = async () => {
    let result = await clientNew.get(`/v2/users/${props.memComId}/${userId}?comId=${mApp.comId}`);
    setPersonalMemId(_.get(result, 'data[0].mem_id'));
    const user = result.data;

    if (user.length > 0) {
      setData(user);
      setGetStatus(user[0].status_work === 'Enable' ? true : false);
      setImageProfile(user[0].profile_img);
      if (checkPathUrl === true) {
        localStorage.setItem('userAvatar', user[0].default_profile_img);
      }
    }
  };

  const setData = (users) => {
    for (let i = 0; i < users.length; i++) {
      users[i].fullname = users[i].fullname == null ? '-' : users[i].fullname;

      users[i].birthday = users[i].birthday_new == null ? '-' : users[i].birthday_new;

      users[i].gender = users[i].gender == null ? '-' : users[i].gender;

      users[i].phone = users[i].phone == null ? '-' : users[i].phone;

      // users[i].id_card = users[i].id_card == null ? '-'
      //     : (users[i].id_card = (
      //       <span
      //       >
      // <NumberFormat
      //   value={`${users[i].id_card}`}
      //   displayType={'text'}
      //   format="#-####-#####-##-#"
      //   id='idd'
      // />
      //       </span>
      //     ));
    }
    setPersonalData(users);
  };

  // Up date status_work
  const handleChange = async (checked) => {
    setGetStatus(checked);
    let status_work = checked ? 'Enable' : 'Disable';
    let dataSwitch = {
      user_id: userId,
      updated_by: props.memComId,
      status_work: status_work,
    };
    const response = await clientNew.post('/v2/update/status/', dataSwitch);
    if (response.status === 200) {
      successNotification(response.data.data);
    }
  };

  const saveLeaveEdit = () => {
    const editDefault = editFieldRef.current.props.defaultValue;
    const editCurrent = editFieldRef.current.props.value;

    validateFields(async (err, value) => {
      if (editDefault !== editCurrent && editCurrent !== '') {
        const body = {
          name: value.name,
          lastname: value.lastname,
          emp_code: value.emp_code,
          phone: value.phone,
          //email: value.email,
          line: value.line,
          facebook: value.facebook,
          id_card: value.id_card,
        };
        apiCall(body);
      }
    });
  };

  const saveLeaveEditEmail = () => {
    const editDefault = editFieldRef.current.props.defaultValue;
    const editCurrent = editFieldRef.current.props.value;

    validateFields(async (err, value) => {
      // if (err) {
      //   setEditEmailId();
      //   setEditing();
      // } else {
      if (editDefault !== editCurrent && editCurrent !== '') {
        var emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (editCurrent.match(emailFormat)) {
          const body = {
            email: value.email,
          };
          apiCall(body);
        } else {
        }
      } else {
      }
      // }
    });
  };

  const onStartDateChange = async (e) => {
    const resignDate = getFieldValue(`resignDate`);

    if (e === null) {
      const body = {
        start_date: null,
      };
      apiCall(body);
    } else {
      const formateStartDate = moment(e, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
      const formateDueDate = moment(resignDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');

      if (formateDueDate < formateStartDate) {
        setCheckStartDate(!checkedStartDate);
        return;
      } else {
        setCheckStartDate();
        const body = {
          start_date:
            moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') !== undefined ? moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        };
        apiCall(body);
      }
    }
  };

  const onResignDateChange = async (e) => {
    const startDate = getFieldValue(`startDate`);

    if (e === null) {
      const body = {
        resign_date: null,
      };
      apiCall(body);
    } else {
      const formateStartDate = moment(startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
      const formateDueDate = moment(e, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');

      if (formateDueDate < formateStartDate) {
        setCheckResignDate(!checkedResigDate);
        return;
      } else {
        setCheckResignDate();
        const body = {
          resign_date:
            moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') !== undefined ? moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
        };
        apiCall(body);
      }
    }
  };

  const onBirthDateChange = async (e) => {
    if (e === null) {
      const body = {
        birthday: null,
      };
      apiCall(body);
    } else {
      const body = {
        birthday: moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') !== undefined ? moment(e._d, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      };
      apiCall(body);
    }
  };

  const onGenderChange = async (gender) => {
    const body = {
      gender: gender ? gender : '',
    };
    apiCall(body);
  };

  const onPositionChange = (pos) => {
    const body = {
      position_id: pos ? pos : null,
    };
    apiCall(body);
  };

  const apiCall = async (body) => {
    try {
      const response = await clientNew.post(`/v3/resource/manager/member/${props.user_id}`, body);
      if (response.status === 200) {
        setCheckResignDate();
        setRefreshPersanal(!refreshPersanal);
        successNotification(response.data.message);
      } else {
        errorNotification(response.data.message);
      }
    } catch (e) {
      errorNotification(e.response.data.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.error({
      message: message,
    });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);
        const fileChange = dataURLtoFile(image, file.name.toLowerCase());

        let formData = new FormData();
        formData.append('file', file.size < 92160 ? file : fileChange);
        formData.append('memComId', props.user_id);

        try {
          const results = await clientNew.post(`/resource/manager/upload/members-profile`, formData);

          if (results.status === 200) {
            openNotificationSuccess(results.data.message);
            fetchData();
          }
        } catch (error) {
          errorNotification(intl.formatMessage({ id: 'UserManagementAddImageFail', defaultMessage: 'This file is not supported' }));
        }
      } else {
        errorNotification(intl.formatMessage({ id: 'UserManagementAddImageFail', defaultMessage: 'This file is not supported' }));
        // openNotificationError("This file is not supported");
      }
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const showDeleteConfirm = (memComId) => {
    confirm({
      className: 'userProfile-modal-confirm',
      title: 'Are you sure delete this picture?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'dashed',
      },
      async onOk() {
        try {
          const result = await clientNew.delete(`/resource/manager/members-profile/${memComId}`);
          if (result.status === 200) {
            setImageProfile(result.data.newPath);
            openNotificationSuccess(result.data.message);
          } else {
            openNotificationError(result.data.message);
          }
        } catch (error) {
          if (error) {
            openNotificationError(error.res.data.message);
          }
        }
        imageRef.current.value = null;
      },
      onCancel() {},
    });
  };

  const openNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const openNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  const handleEdit = () => {
    setVisibleEdit(true);
  };

  let mapUserView = personalData.map((item, key) => {
    // console.log("view data",item.lastname);
    return (
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div key={key}>
            <Row>
              <Title className="title-name-new" level={4}>
                <span className="title-span-name-no-pointer">{item.name}</span>
                &nbsp;&nbsp;
                <span className="title-span-name-no-pointer">{item.lastname}</span>
              </Title>
            </Row>
            <Row>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalEmail" defaultMessage="Email" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.email ? item.email : '-'}</span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalEmployeeCode" defaultMessage="Employee Code" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.emp_code ? item.emp_code : '-'}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div>
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalEmployeeType" defaultMessage="Employee Type" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">
                      {_.get(item.employee_type_master, 'employeeTypeName') ? _.get(item.employee_type_master, 'employeeTypeName') : '-'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalStartDateEndDate" defaultMessage="Start Date - End Date" />
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="lbl-value-list">{item.start_date}</span>
                      &nbsp;{item.resign_date ? '-' : null}&nbsp;
                      <span className="lbl-value-list">{item.resign_date}</span>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalCivilNationalID" defaultMessage="Civil National ID" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.id_card ? item.id_card : '-'}</span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalBirthdayDate" defaultMessage="Birthday Date" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.birthday ? item.birthday : '-'}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalGender" defaultMessage="Gender" />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.gender ? item.gender : '-'}</span>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="between-list-user-detail">
                  <div>
                    <span className="lbl-title-list">
                      <FormattedMessage id="userMangementPersanalPhoneNo." defaultMessage="Phone No." />
                    </span>
                  </div>
                  <div>
                    <span className="lbl-value-list">{item.phone ? item.phone : '-'}</span>
                  </div>
                </div>
              </Col>
            </Row>
            {(comCode === process.env.REACT_APP_CUSTOMER_TO_KEYCLOAK || true) && (
              <Row>
                <Col span={12}>
                  <div className="between-list-user-detail">
                    <div>
                      <span className="lbl-title-list">
                        <FormattedMessage id="userMangementPersanalUsername" defaultMessage="Username" />
                      </span>
                    </div>
                    <div>
                      <span className="lbl-value-list">{item.username ? item.username : item.phone}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        )}
      </PageSettings.Consumer>
    );
  });

  return (
    <div>
      <PageSettings.Consumer>
        {({ checkPermissionAction }) => (
          <div>
            <Form>
              <Card
                // className="card-personal"
                title={<FormattedMessage id="userMgntUsersDetailTitle" defaultMessage="Personal Information" />}
                extra={
                  <div className="extra-user-details">
                    {checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A2') ? (
                      switchStatus === false ? null : (
                        <AppSwitch className="ant-switch-checked" onChange={handleChange} checked={getStatus} />
                      )
                    ) : null}
                    {checkPathUrl === true ? (
                      <div>
                        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button01>
                      </div>
                    ) : checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A6') ? (
                      <div>
                        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleEdit}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button01>
                      </div>
                    ) : null}
                  </div>
                }
              >
                <Row>
                  <Col span={8}>
                    {/* <div className="col-md-8 image-container"> */}
                    <div className="image-container">
                      <AppAvatar
                        size={150}
                        icon={'user'}
                        //src={`${process.env.REACT_APP_IMG_HOST}` + imageProfile}
                        src={imageProfile}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    {/* {checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A3') ? (
                      <>{mapUser}</>
                    ) : ( */}
                    <>{mapUserView}</>
                    {/* )} */}
                    {checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A3') ? (
                      checkPathUrl === true ? null : (
                        <div className="personal-reset-password" onClick={() => setVisibleModalResetPassword(true)}>
                          <span className="personal-reset-password-span">
                            <FormattedMessage id="userMangementPersanalResetPassword" defaultMessage="Reset Password" />
                          </span>
                        </div>
                      )
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </Form>
            {checkPermissionAction('P2S1PG2C1', 'P2S1PG2C1A3') ? (
              <ModalResetPassword
                visibleModalResetPassword={visibleModalResetPassword}
                setVisibleModalResetPassword={setVisibleModalResetPassword}
                personalMemId={personalMemId}
              />
            ) : null}

            <ModalEditUser visible={visibleEdit} setVisible={setVisibleEdit} data={personalData} setRefreshTable={setRefreshPersanal} />
          </div>
        )}
      </PageSettings.Consumer>
    </div>
  );
};

export default Form.create({
  name: 'form_persanal',
  mapPropsToFields(props) {},
})(PersanalFormEdit);
// styled Component
