import { Card, DatePicker, Icon, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { OverviewContext } from '../context';
import moment from 'moment';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { Option } = Select;

const Talaadthai = ({ fullscreenPageRef }) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(OverviewContext);
  const [dataTeacoMarket, setTeacoMarket] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { filterDate } = state;
  const { setFilterDate, setFilterDateDuration } = setState;

  const [itemOption, setItemOption] = useState([
    {
      value: 'today',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiToday', defaultMessage: 'Today' }),
    },
    {
      value: 'thisweek',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiThisWeek', defaultMessage: 'This Week' }),
    },
    {
      value: 'thismonth',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiThisMonth', defaultMessage: 'This Month' }),
    },
    {
      value: 'duration',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiDuration', defaultMessage: 'Duration' }),
    },
  ]);

  const onChangeDate = (value) => {
    if (value === 'duration') {
      setIsModalVisible(true);
    } else {
      setFilterDate(value);
      setFilterDateDuration([]);
    }
  };

  const handleOk = () => {
    const [startDate, endDate] = dateRange;

    const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
    const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD');

    setFilterDate('duration');
    setFilterDateDuration([formattedStartDate, formattedEndDate]);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDateRange([]);
  };

  const handleFullscreen = () => {
    if (isFullscreen) {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    } else {
      // Enter fullscreen
      if (fullscreenPageRef.current) {
        if (fullscreenPageRef.current.requestFullscreen) {
          fullscreenPageRef.current.requestFullscreen();
        } else if (fullscreenPageRef.current.mozRequestFullScreen) {
          // Firefox
          fullscreenPageRef.current.mozRequestFullScreen();
        } else if (fullscreenPageRef.current.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          fullscreenPageRef.current.webkitRequestFullscreen();
        } else if (fullscreenPageRef.current.msRequestFullscreen) {
          // IE/Edge
          fullscreenPageRef.current.msRequestFullscreen();
        }
      }
      setIsFullscreen(true);
    }
  };

  return (
    <>
      <Card
        title={
          <span>
            <Icon type="shop" style={{ marginRight: 8, fontSize: 16 }} />
            {intl.formatMessage({ id: 'taecoTableTalaadthai', defaultMessage: 'Talaadthai' })}
          </span>
        }
        bodyStyle={{ display: 'none' }}
        extra={
          <div style={{ display: 'inline-flex' }}>
            <Select
              placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefModel', defaultMessage: 'Select Item' })}
              defaultActiveFirstOption={false}
              showSearch
              allowClear
              filterOption={false}
              defaultValue={'today'}
              value={filterDate}
              onChange={(value) => {
                if (value) {
                  onChangeDate(value);
                } else {
                  setFilterDate('today');
                  setFilterDateDuration([]);
                }
              }}
              onSelect={(value) => {
                onChangeDate(value);
              }}
              style={{ width: '200px' }}
            >
              {_.map(itemOption, (item) => (
                <Option key={item.value} value={item.value}>
                  {item.lable}
                </Option>
              ))}
            </Select>
            <Button_01
              key="submit"
              type="primary"
              style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '40%' }}
              onClick={handleFullscreen}
            >
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Icon type="fullscreen" style={{ marginRight: 8, fontSize: 24 }} />
                <FormattedMessage id="btnFullScreen" defaultMessage="Full Screen" />
              </span>
            </Button_01>
          </div>
        }
      ></Card>

      <Modal
        title={intl.formatMessage({ id: 'taecoTableTalaadthaiSelectDuration', defaultMessage: 'Select Duration' })}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
            <FormattedMessage id="btnClose" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOk()}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          value={dateRange.length ? [moment(dateRange[0], 'DD/MM/YYYY'), moment(dateRange[1], 'DD/MM/YYYY')] : []}
          onChange={(dates, dateStrings) => setDateRange(dateStrings)}
        />
      </Modal>
    </>
  );
};

export default Talaadthai;
