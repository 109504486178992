import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Form, Divider, Table, Row, Col, DatePicker, Select, Input, AutoComplete, Popover, Button, Icon, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import './index.css';
import _ from 'lodash';
import moment from 'moment';
import { getVendor } from '../../../../controllers/customer-vendor/customer-vendor';
import { useDebounce } from '../../../../controllers/debounce';
import { fncGetUsers } from '../../../../controllers/user/users';
import LabeRequireForm from '../../../../components/label-required-form';
import Button_01 from '../../../../components/v2/button_01';
import Button_02 from '../../../../components/v2/button_02';
import ModalPurchase from '../purchase';
import ModalReciveItemPO from '../receive';
import ModalFormReceiveLotOrSerial from '../receive-form';
import { ReciveItemPOContext } from '../../reciveitempo-context';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { createReceivePOData, getItemPOID, updateCancel } from '../../../../controllers/receice-po/receive';
import { getQueueMessages } from '../../../../hooks/use-queue-api';

const { Option } = Select;
const { Search } = Input;

const ModalReceiveItem = (props) => {
  const { state, setState, fnc } = useContext(ReciveItemPOContext);
  const { totalWarhouse, typeview } = state;
  const { setWarehouseSerch, setTrigger, setTypeView } = setState;
  const { handleCustomerPopupScroll } = fnc;
  const { visible, setVisible, form, warehouseList, numbertypeList } = props;
  const { getFieldDecorator, setFieldsValue, resetFields, validateFields } = form;
  const intl = useIntl();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: true,
      render: (text, record, index) => <span>{(index + 1).toString()}</span>,
    },
    {
      title: intl.formatMessage({ id: 'recivePOItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      fixed: true,
      sorter: (a, b) => a.cost - b.cost,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'recivePOItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: intl.formatMessage({ id: 'recivePOReceiveQty', defaultMessage: 'Receive Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: (a, b) => a.cost - b.cost,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnLotNo', defaultMessage: 'Lot No. or Serial No.' }),
      dataIndex: 'lotSerialNo',
      key: 'lotSerialNo',
      sorter: (a, b) => a.lotSerialNo - b.lotSerialNo,
      width: 200,
    },

    {
      title: intl.formatMessage({ id: 'recivePOManufacturingDate', defaultMessage: 'Manufacturing Date' }),
      dataIndex: 'menufacturingDate',
      key: 'menufacturingDate',
      width: 200,
      sorter: (a, b) => a.menufacturingDate - b.menufacturingDate,
      render: (text, record, index) =>
        _.get(record, 'menufacturingDate') === '-' || _.get(record, 'menufacturingDateTxt') === '-'
          ? '-'
          : moment(record.menufacturingDate).format('DD/MM/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'modalReceiveItemColumnExpiredDate', defaultMessage: 'Expired Date' }),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      width: 200,
      sorter: (a, b) => a.expiredDate - b.expiredDate,
      render: (text, record, index) =>
        _.get(record, 'expiredDate') === '-' || _.get(record, 'expiredDateTxt') === '-'
          ? '-'
          : moment(record.expiredDate).format('DD/MM/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'modalItemMasterFormItemTextFDA', defaultMessage: 'FDA' }),
      dataIndex: 'fda',
      key: 'fda',
      width: 150,
      sorter: (a, b) => a.fda - b.fda,
    },
  ];
  const ref = useRef();

  const [visiblePurchase, setvisiblePurchase] = useState(false);
  const [visibleLotNoTable, setvisibleLotNoTable] = useState(false);
  const [visibleLotNo, setvisibleLotNo] = useState(false);
  const [lotNo, setLotNo] = useState([]);
  const [searhByCode, setSearchByCode] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [itemCodeList, setItemCodeList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [podata, setPOdata] = useState([]);
  const [vendorFilterdata, setVendorFilterdata] = useState([]);
  const [binLocationList, setBinLocationList] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const debounceCode = useDebounce(searhByCode, 500);
  const debounceName = useDebounce(searchByName, 500);
  const [paginationPage, setPaginationPage] = useState(1);
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [defaultData, setDefaultData] = useState();
  const [itemData, setItemData] = useState();
  const [scrollTableX, setScrollTableX] = useState(1300);
  const [viewData, setViewData] = useState([]);
  const [reciveQTY, setReciveQTY] = useState(0);
  const [popast, setPOPast] = useState('');
  const [itemDataList, setItemDataList] = useState([]);
  const [tempitemDataList, setTempItemDataList] = useState([]);
  const comCode = localStorage.getItem('comCode');
    const [loading, setLoading] = useState(false);

  console.log('POTEST', lotNo);

  useEffect(() => {
    async function getPoItem() {
      try {
        console.log('POTEST', typeview);

        const response = await getItemPOID(_.get(typeview, 'movementId'));
        setViewData(_.get(response, 'data.data'));
        setLotNo(_.get(response, 'data.data.itemPoList'));

      } catch (error) {

      }
    }

    if (_.size(typeview) > 0 && visible) {
      getPoItem();
    }
  }, [visible]);

  useEffect(() => {
    async function getUser() {
      try {
        const body = {
          searchBy: '',
          searchVal: 'all',
          page: 1,
          limit: 10000,
        };
        const memComId = localStorage.getItem('memComId');
        const response = await fncGetUsers(memComId, null, true);
        const vendorResponse = await getVendor(body);
        setUserData(response);
        setVendorData(vendorResponse.customers);
      } catch (error) {}
    }
    if (visible) {
      getUser();
    }
  }, [visible]);

  useEffect(() => {
    if (_.size(podata) > 0) {
      setFieldsValue({ ['purchase']: _.get(podata, 'poNo') });
    }
  }, [podata]);

  useEffect(() => {
    if (_.size(vendorFilterdata) > 0) {
      setFieldsValue({ ['vendor']: _.get(vendorFilterdata, 'vendorSupplierId') });
    }
  }, [vendorFilterdata]);

  useEffect(() => {
    if (visible) {
      validateFields(['item'], (errors, values) => {
        console.log('ruleRRor', errors);
        if (errors) {
          console.log('Validation failed:', errors);
        } else {
          console.log('Validation passed:', values);
        }
      });
    }
  }, [lotNo]);

  // useEffect(() => {
  //   if (visible) {
  //     setReciveQTY
  //   }
  // }, [reciveQTY])

  const handleSave = () => {
    validateFields(async (err, values) => {
      console.log('selectitem', values);

      if (err) {
        return;
      }
      setLoading(true)
      if (_.size(lotNo) <= 0) {
        errorNotification(intl.formatMessage({ id: 'ValidateAddITem', defaultMessage: 'Please Add Item' }));
        return;
      }
      const body = {
        warehouseId: values.warehouse,
        actionById: values.reciveBy,
        receivedDate: moment(values.receivedDate).format('YYYY-MM-DD'),
        vendorOrSupplierId: values.vendor,
        remark: values.remark,
        itemPoList: _.map(lotNo, (item) => {
          return {
            poNo: values.purchase,
            poId: _.get(podata, 'poId'),
            uomId: item.uomId,
            itemMasterId: item.itemMasterId,
            no: item.no,
            // expiredDate: moment(item.expiredDate).format('YYYY-MM-DD'),
            expiredDate: _.get(item, 'expiredDate') !== '-' ? _.get(item, 'expiredDate') : undefined,
            fda: item.fda,
            // menufacturingDate: moment(item.menufacturingDate).format('YYYY-MM-DD'),
            menufacturingDate: _.get(item, 'menufacturingDate') !== '-' ? _.get(item, 'menufacturingDate') : undefined,
            numberType: item.lotNoSelect,
            qty: parseFloat(item.qty),
            itemPoId: _.get(item, 'itemPoId'),
          };
        }),
      };
      const payload = {
        apiMethod: 'post',
        urlQueue: `/queue/manager/company/${comCode}/warehouseitemmove`,
        apiUrl: `${process.env.REACT_APP_URL_MANAGER}/warehouse-manager/v1/warehouse/manager/company/${comCode}/web/warehouse/receiveitempo`,
        payload: body,
      };

      try {
        const response = await getQueueMessages(payload);
        // const response = await createReceivePOData(body);
        if (response.status.code === 200) {
          successNotification(_.get(response, 'status.message'));
          // successNotification(response.data.status.message);
          setVisible(false);
          setLoading(false)
          resetFields();
          setLotNo([]);
          setPOdata([]);
          setVendorFilterdata([]);
          setTrigger((current) => !current);
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.status.message'));
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setLotNo([]);
    setPOdata([]);
    setVendorFilterdata([]);
    setTypeView([]);
    setViewData([]);
  };

  const handleCreate = () => {
    setvisibleLotNo(true);
  };

  const handleSearchCode = _.debounce((value) => {
    setSearchByCode(value || '');
  }, 500);

  const handleSearchName = _.debounce((value) => {
    setSearchByName(value || '');
  }, 500);

  const handleSelectCode = (value) => {
    if (value) {
      const filterArr = itemCodeList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemName: _.get(filterArr[0], 'itemName'),
      });
    }
  };

  const handleSelectName = (value) => {
    if (value) {
      const filterArr = itemNameList.filter((item) => item.itemMasterId === value);
      setSelectItem(_.get(filterArr, '[0]'));
      setFieldsValue({
        itemCode: _.get(filterArr[0], 'itemCode'),
      });
    }
  };

  const handleChange = (pagination, sorter, extra) => {
    setPaginationPage(pagination.current);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleEditLotNo = (data) => {
    setvisibleLotNo(true);
    setDefaultData(data);
  };

  const handleDeleteLotNo = (value) => {
    const filterData = _.filter(lotNo, (item) => {
      return item.id !== value;
    });
    const filterdelteqty = _.filter(lotNo, (item) => {
      return item.id === value;
    });
    const filterqty = _.filter(tempitemDataList, (item) => {
      return item.itemMasterId === _.get(itemData, 'itemMasterId');
    });
    console.log('calcalQTY', filterdelteqty, '/', filterqty);

    const calqty = _.get(filterqty, '[0].received') - _.get(filterdelteqty, '[0].qty');
    //----------------------------------------------------
    const filterRecive = _.filter(itemDataList, (item) => {
      return item.itemMasterId !== _.get(itemData, 'itemMasterId');
    });
    const setobj = [
      {
        fda: '',
        itemCode: _.get(itemData, 'itemCode'),
        itemName: _.get(itemData, 'itemName'),
        itemMasterId: _.get(itemData, 'itemMasterId'),
        qty: _.get(itemData, 'qty'),
        receiveQty:
          calqty === 0
            ? _.get(itemData, 'received') + ' / ' + _.get(itemData, 'qty') + ' ' + _.get(itemData, 'uomQtyTxt')
            : calqty + ' / ' + _.get(itemData, 'qty') + ' ' + _.get(itemData, 'uomQtyTxt'),
        received: calqty === 0 ? _.get(itemData, 'received') : calqty,
        uomId: _.get(itemData, 'uomId'),
        uomQtyTxt: _.get(itemData, 'uomQtyTxt'),
      },
    ];
    setTempItemDataList(filterRecive.concat(setobj));
    setItemDataList(filterRecive.concat(setobj));
    setLotNo(filterData);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          content={
            <div style={{ display: 'grid' }}>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditLotNo(record)}>
                  <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                </Button>
              </div>
              <div>
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDeleteLotNo(record.id)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              </div>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const checkView = [
    _.size(viewData) > 0 ? (
      <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnBack" defaultMessage="Back" />
      </Button_02>
    ) : null,
    // _.size(viewData) > 0 && _.get(viewData, 'canCancel') === true ? (
    //   <Button_01
    //     key="submit"
    //     type="primary"
    //     style={{ margin: '0px 0px 0px 10px', width: '18%' }}
    //     onClick={() => {
    //       updateStatus();
    //     }}
    //   >
    //     <FormattedMessage id="btnCancelRecevice" defaultMessage="Cancel Recevice" />
    //   </Button_01>
    // ) : null,
    _.size(viewData) === 0 ? (
      <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
      </Button_02>
    ) : null,
    _.size(viewData) === 0 ? (
      <Button_01
        key="submit"
        type="primary"
        btnsize="wd_df"
        onClick={() => {
          handleSave();
        }}
      >
        <FormattedMessage id="btnSave" defaultMessage="Save" />
      </Button_01>
    ) : null,
  ];

  const openTicketModal = () => {
    setvisiblePurchase(true);
  };

  const closeTicketModal = () => {
    setvisiblePurchase(false);
  };

  const validatorAddItem = (rule, value, callback) => {
    console.log('sizelotno', _.size(lotNo));
    if (_.size(lotNo) <= 0) {
      console.log('ruletest');
      callback(intl.formatMessage({ id: 'ValidateAddITem', defaultMessage: 'Please Add Item' }));
    } else {
      console.log('ruletestPass');
      callback();
    }
  };

  const updateStatus = async (data) => {
    setLoading(true)
    const payload = {
      apiMethod: 'put',
      urlQueue: `/queue/manager/company/${comCode}/warehouseitemmove`,
      // apiUrl: updateCancel(_.get(viewData,'movementId')),
      apiUrl: `${
        process.env.REACT_APP_URL_MANAGER
      }/warehouse-manager/v1/warehouse/manager/company/${comCode}/web/warehouse/cancel-receiveitempo/${_.get(viewData, 'movementId')}`,
    };

    try {
      const response = await getQueueMessages(payload);
      // const response = await createReceivePOData(body);
      if (response.status.code === 200) {
        successNotification(_.get(response, 'status.message'));
        // successNotification(response.data.status.message);
        setTrigger((current) => !current);
        handleCancel();
        setLoading(false)
      }
    } catch (error) {
      errorNotification(error.response.data.status.message);
      setLoading(false)
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalReceiveItemHeader', defaultMessage: 'Receive Item' })}
      visible={visible}
      onCancel={handleCancel}
      width={767}
      centered={true}
      style={{ marginTop: '25px' }}
      bodyStyle={{ padding: 'unset', overflowY: 'auto', height: 'calc(100vh - 160px)' }}
      footer={checkView}
    >
      <Spin spinning={loading}>
        <div style={{ padding: '24px 24px 0px' }}>
          <Form form={form} layout="vertical" className="modalReceiveItemForm">
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'recivePOToWarehouse', defaultMessage: 'To Warehouse' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(viewData, 'warehouseName')
                    : getFieldDecorator('warehouse', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'recivePOToWarehouseValidate',
                              defaultMessage: 'Please Select To Warehouse',
                            }),
                          },
                        ],
                      })(
                        <Select
                          placeholder={intl.formatMessage({ id: 'recivePOToWarehousePlaceHolder', defaultMessage: 'Select To Warehouse' })}
                          showSearch
                          onPopupScroll={handleCustomerPopupScroll}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          allowClear
                          onSearch={(e) => {
                            setWarehouseSerch(e);
                          }}
                        >
                          {_.map(warehouseList, (item) => (
                            <Option key={item.warehouseId}>{item.warehouseName}</Option>
                          ))}
                        </Select>
                      )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalReceiveVendorText', defaultMessage: 'Vendor or Supplier' })}
                      req={false}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(viewData, 'vendorOrSupplier')
                    : getFieldDecorator('vendor', {
                        initialValue: _.size(vendorFilterdata) > 0 ? _.get(vendorFilterdata, 'vendorSupplierId') : undefined,
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'modalReceiveVendorValidate',
                              defaultMessage: 'Please Select Vendor or Supplier',
                            }),
                          },
                        ],
                      })(
                        <Select
                          placeholder={intl.formatMessage({
                            id: 'modalReceiveVendorPlaceholeder',
                            defaultMessage: 'Select Vendor or Supplier',
                          })}
                          showSearch
                          onChange={(e) => setVendorFilterdata({ vendorSupplierId: e })}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {_.map(vendorData, (item) => (
                            <Option key={item.customerId}>{item.customerName}</Option>
                          ))}
                        </Select>
                      )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'recivePOPurchaseOrderNo', defaultMessage: 'Purchase Order No.' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(typeview, 'poNo')
                    : getFieldDecorator('purchase', {
                        initialValue: _.size(viewData) > 0 ? _.get(podata, 'poNo') : undefined,
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'recivePOValidatePurchaseOrderNo',
                              defaultMessage: 'Plese Select Purchase Order No.',
                            }),
                          },
                        ],
                      })(
                        <Search
                          className="allticketinputBasket"
                          readOnly={true}
                          style={{ width: '100%', marginTop: '2px', cursor: 'pointer' }}
                          placeholder={intl.formatMessage({
                            id: 'recivePOPlaceholderPurchaseOrderNo',
                            defaultMessage: 'Select Purchase Order No.',
                          })}
                          onSearch={openTicketModal}
                          onClick={openTicketModal}
                          enterButton
                        />
                      )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'recivePOReceivedby', defaultMessage: 'Received by' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(viewData, 'receivedBy')
                    : getFieldDecorator('reciveBy', {
                        initialValue: localStorage.getItem('memComId') ? localStorage.getItem('memComId') : undefined,
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'modalReceiveActionByValidate', defaultMessage: 'Please Select Action By' }),
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          placeholder={intl.formatMessage({ id: 'modalReceiveActionByPlaceholeder', defaultMessage: 'Select Action By' })}
                        >
                          {_.map(userData, (item) => (
                            <Option key={item.mem_com_id}>{item.fullname}</Option>
                          ))}
                        </Select>
                      )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalReceiveReceivedDateText', defaultMessage: 'Received Date' })}
                      req={_.size(viewData) > 0 ? false : true}
                    />
                  }
                >
                  {_.size(viewData) > 0
                    ? _.get(viewData, 'receivedDateTxt')
                    : getFieldDecorator('receivedDate', {
                        initialValue: moment(),
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'modalReceiveReceivedDateValidate',
                              defaultMessage: 'Please Select Received Date',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'ddd, DD MMM YYYY'}
                          placeholder={intl.formatMessage({
                            id: 'modalReceiveReceivedDatePlaceholeder',
                            defaultMessage: 'Select Received Date',
                          })}
                        />
                      )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'modalReceiveRemarkText', defaultMessage: 'Remark' })} req={false} />
                  }
                >
                  {_.size(viewData) > 0 ? _.get(viewData, 'remark') : getFieldDecorator('remark', {})(<Input style={{ width: '100%' }} />)}
                </Form.Item>
              </Col>
            </Row>

            <div style={{ padding: '24px 0px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="modalReceiveItemTextLotNo">
                <FormattedMessage id="modalReceiveItemColumnLotNo" defaultMessage="Lot No. or Serial No." />
              </div>
              <div>
                {_.size(viewData) > 0 ? null : (
                  <Button_01
                    key="submit"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => setvisibleLotNoTable(true)}
                    disabled={_.size(podata) > 0 ? false : true}
                  >
                    <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  </Button_01>
                )}
              </div>
            </div>
          </Form>
        </div>

        <Divider type="horizontal" style={{ marginTop: '16px' }} />
        <div style={{ padding: '0 24px 24px 24px' }} onMouseLeave={handleMouseLeave}>
          <Table
            className="custom-table-claim"
            columns={columns}
            dataSource={lotNo}
            onChange={handleChange}
            scroll={{ x: scrollTableX, y: `calc(100vh - 357px)` }}
            pagination={{
              total: _.size(lotNo),
              current: paginationPage,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (!event.target.href) {
                    const { x, y } = ref.current.getBoundingClientRect();
                    if (_.size(viewData) === 0) {
                      setVisiblePopoverTable(true);
                      setOffsetPopover([event.pageX - x, event.pageY - y]);
                      setRecordPopover(record);
                    }
                  }
                },
              };
            }}
          />
          {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
          {_.size(lotNo) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${_.size(lotNo) || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Spin>
      <ModalPurchase
        visibleTicket={visiblePurchase}
        closeTicketModal={closeTicketModal}
        requestDetailData={podata}
        setRequestDetailData={setPOdata}
        vendorFilterdata={vendorFilterdata}
        setVendorFilterdata={setVendorFilterdata}
        setLotNo={setLotNo}
      />

      <ModalReciveItemPO
        setvisibleLotNoTable={setvisibleLotNoTable}
        visibleLotNoTable={visibleLotNoTable}
        handleCreate={handleCreate}
        setItemData={setItemData}
        podata={podata}
        setReciveQTY={setReciveQTY}
        setPOPast={setPOPast}
        popast={popast}
        itemDataList={itemDataList}
        setItemDataList={setItemDataList}
        lotNo={lotNo}
      />

      <ModalFormReceiveLotOrSerial
        visible={visibleLotNo}
        setVisible={setvisibleLotNo}
        setvisibleLotNoTable={setvisibleLotNoTable}
        lotNo={lotNo}
        setLotNo={setLotNo}
        binLocationList={binLocationList}
        defaultData={defaultData}
        setDefaultData={setDefaultData}
        numbertypeList={numbertypeList}
        itemData={itemData}
        setReciveQTY={setReciveQTY}
        reciveQTY={reciveQTY}
        itemDataList={itemDataList}
        setItemDataList={setItemDataList}
        setItemData={setItemData}
        tempitemDataList={tempitemDataList}
        setTempItemDataList={setTempItemDataList}
      />
    </Modal>
  );
};

const ModalFormReceiveItem = Form.create({
  name: 'modal_form',
})(ModalReceiveItem);

export default ModalFormReceiveItem;
