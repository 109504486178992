import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Popover, Modal } from 'antd';
import Button01 from '../../../components/v2/button_01';
import { useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import _ from 'lodash';
import { PageSettings } from '../../../config/page-settings';
import { getSearchStakeHolder } from '../../../controllers/project/project-api';
import index from '../../setting/request-type';
import StakeholderSource from './list-stakeholder';
import { MoreOutlined } from '@ant-design/icons';
import { handleCreateSkill } from '../../../controllers/skill/add-edit-delete';

const ProjectStakeholder = (props) => {
  const {
    handleDeleteStakeholder,
    setVisible,
    totalstake,
    stakedata,
    handleDeleteSkateholder,
    paginationPage,
    handlePagination,
    handleSizeChange,
  } = props;

  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({
        id: `stakeholderLabelname`,
        defaultMessage: 'Name',
      }),
      dataIndex: 'stackholderName',
      key: 'stackholderName',
      width: 200,
    },
    {
      title: intl.formatMessage({
        id: `stakeholderLabelposition`,
        defaultMessage: 'Position',
      }),
      dataIndex: 'stackholderPosition',
      key: 'stackholderPosition',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `stakeholderLabelemail`,
        defaultMessage: 'Email',
      }),
      dataIndex: 'stackholderEmail',
      key: 'stackholderEmail',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({
        id: `stakeholderLabelphone`,
        defaultMessage: 'Phone',
      }),
      dataIndex: 'stackholderPhone',
      key: 'stackholderPhone',
      width: 150,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      align: 'center',
      render: (text, record, index) => {
        return (
          <div>
            <Popover
              placement="rightTop"
              content={
                <div>
                  <>
                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) => (
                        <div>
                          {checkPermissionAction('P39PG2C2', 'P39PG2C2A3') ? (
                            <Button
                              style={{ width: 100 }}
                              type="link"
                              ghost
                              onClick={() => handleDeleteSkateholder(record.projectStackholderId, 'delete')}
                            >
                              <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </PageSettings.Consumer>
                  </>
                </div>
              }
            >
              <MoreOutlined />
            </Popover>
          </div>
        );
      },

      // render: (text, record, index) => {
      //   return <MoreOutlined onClick={() => alert(record.index)} />;
      // },
    },
  ];

  const actionAddStakeholder = (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P39PG2C2', 'P39PG2C2A2') ? (
          <Button01 fontsize="sm" type="primary" onClick={() => setVisible(true)}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        ) : null
      }
    </PageSettings.Consumer>
  );

  return (
    <div >
      {/* <Card title={intl.formatMessage({ id: `stakeholderLabelStakeholder`, defaultMessage: 'Stakeholder' })} extra={actionAddStakeholder}> */}

        <StakeholderSource
          columns={columns}
          stakedata={stakedata}
          totalstake={totalstake}
          paginationPage={paginationPage}
          handlePagination={handlePagination}
          handleSizeChange={handleSizeChange}
        />
      {/* </Card> */}
    </div>
  );
};

export default ProjectStakeholder;
