import httpClient from '../../../../../components/axiosClient';
import moment from 'moment';
import { successNotification, errorNotification, warningNotification } from '../../../../../components/v2/notification.js'

const addNewUserControl = async (value, newImage, comCode, positonObject) => {

  const body = {
    name: value.name,
    empId: value.empId,
    email: value.mail && value.mail !== "" ? value.mail : undefined,
    phone: value.phone,
    startDate: moment(value.startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD'),
    endDate: value.endDate ? moment(value.endDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD') : undefined,
    gender: value.gender,
    birtday: value.birthDate ? moment(value.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
    idcard: value.id,
    position: positonObject ? positonObject.positionName : undefined,
    positionId: positonObject ? positonObject.positionId : null,
    facebook: value.facebook,
    lineid: value.lineId,
    orgId: value.team,
    employeeTypeId: value.employeeType,
    username: value.username ? value.username : ''
  }
  try {
    const response = await httpClient.post(`/v3/resource/manager/company/${comCode}/createusers`, body)
    if (response.status === 200) {
      const memproId = response.data.data.mem_pro_id;
      if (memproId) {
        let formData = new FormData();
        formData.append('file', newImage);
        //formData.append('memComId', props.user_id);
        const resUploadImage = await httpClient.post(`/v3/resource/manager/company/${comCode}/users/update/photo?memproid=${memproId}`, formData)
      }
      successNotification(response.data.messsage);
      return response
    }
    else {
      warningNotification(response.message);
    }
  } catch (e) {
    errorNotification(e.response.data.messsage ? e.response.data.messsage : e.response.data.error);
    return e.response
  }
}


const getGenderAndPosition = async (comCode, setDataDropDown) => {
  try {
    const response = await httpClient.get(`/v3/resource/manager/company/${comCode}/getselect`)
    if (response.status === 200) {
      return setDataDropDown(response.data);
    }
    else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export { addNewUserControl, getGenderAndPosition };