import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import { Button, Divider, Modal } from 'antd';
import TableProjectDetail from '../component/table';
import { getGasList, getReimbursementList } from '../../../../controllers/project-new/project-setting';
import ModalSelectItemProject from '../../../../components/modal-select-item-project';
import ModalReimbursementForm from './modal-reimbursement';
import ModalGasForm from './modal-gas';

const ProjectDetailSetting = (props) => {
  const { id } = props;
  const intl = useIntl();
  const [listReimbursement, setListReimbursement] = useState([]);
  const [triggerReimbursement, setTriggerReimbursement] = useState(false);
  const [tableChangeReimbursement, setTableChangeReimbursement] = useState({ page: 1, pageSize: 5, orderBy: null, orderField: null });
  const [openReimbursement, setOpenReimbursement] = useState(false);
  const [recordReimbursement, setRecordReimbursement] = useState({});

  const [listGas, setListGas] = useState([]);
  const [triggerGas, setTriggerGas] = useState(false);
  const [tableChangeGas, setTableChangeGas] = useState({ page: 1, pageSize: 5, orderBy: null, orderField: null });
  const [openGas, setOpenGas] = useState(false);
  const [recordGas, setRecordGas] = useState({});

  useEffect(() => {
    const getReimbursement = async () => {
      const body = {};
      const response = await getReimbursementList(body);
      setListReimbursement(response);
    };

    if (id) getReimbursement();
  }, [id, triggerReimbursement]);

  useEffect(() => {
    const getGas = async () => {
      const body = {};
      const response = await getGasList(body);
      setListGas(response);
    };

    if (id) getGas();
  }, [id, triggerGas]);

  const columnsReimbursement = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsReimbursement', defaultMessage: 'Reimbursement' }),
      dataIndex: 'reimbursement',
      key: 'reimbursement',
      sorter: true,
      width: 200,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsLimit', defaultMessage: 'Limit' }),
      dataIndex: 'limit',
      key: 'limit',
      sorter: true,
      width: 100,
      align: 'right',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 350,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsUpdateBy', defaultMessage: 'Update by' }),
      dataIndex: 'updateBy',
      key: 'updateBy',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updateDate',
      key: 'updateDate',
      sorter: true,
      width: 150,
    },
  ];

  const columnsGas = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasType', defaultMessage: 'Gas Type' }),
      dataIndex: 'gasType',
      key: 'gasType',
      sorter: true,
      width: 150,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasprice', defaultMessage: 'Gas Price' }),
      dataIndex: 'gasPrice',
      key: 'gasPrice',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEffectiveStartDate', defaultMessage: 'Effective Start Date' }),
      dataIndex: 'effectiveStartDate',
      key: 'effectiveStartDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEffectiveEndDate', defaultMessage: 'Effective End Date' }),
      dataIndex: 'effectiveEndDate',
      key: 'effectiveEndDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasUpdateBy', defaultMessage: 'Update by' }),
      dataIndex: 'updateBy',
      key: 'updateBy',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updateDate',
      key: 'updateDate',
      sorter: true,
      width: 150,
    },
  ];

  const handleOpen = (code) => {
    if (code === 'reimbursement') {
      setOpenReimbursement(true);
    } else {
      setOpenGas(true);
    }
  };

  const contentActionReimbursement = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => editReimbursement(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteReimbursement(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const editReimbursement = (record) => {
    setRecordReimbursement({ ...record, action: 'edit' });
    setOpenReimbursement(true);
  };

  const deleteReimbursement = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `projectDetailModalConfirmDeleteProject`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        //   const body = {
        //       projectId: id,
        //       project_upload_id: _.get(record, 'project_upload_id')
        //   }
        //   const response = await deleteAttachment(body);
        //   console.log('project delete', response)
        //   if (response.status === 200) {
        //     setTrigger((current) => !current);
        //     successNotification(response.message);
        //   } else {
        //     errorNotification(response.message);
        //   }
      },
      onCancel() {},
    });
  };

  const contentActionGas = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => editGas(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteGas(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const editGas = (record) => {
    setRecordGas({ ...record, action: 'edit' });
    setOpenGas(true);
  };

  const deleteGas = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `projectDetailModalConfirmDeleteProject`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        //   const body = {
        //       projectId: id,
        //       project_upload_id: _.get(record, 'project_upload_id')
        //   }
        //   const response = await deleteAttachment(body);
        //   console.log('project delete', response)
        //   if (response.status === 200) {
        //     setTrigger((current) => !current);
        //     successNotification(response.message);
        //   } else {
        //     errorNotification(response.message);
        //   }
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
        <span className="item-title-text">
          <FormattedMessage id="projectDetailSettingLableReimbursement" defaultMessage="Reimbursement" />
        </span>
        <div>
          <Button01 fontsize="sm" type="primary" onClick={() => handleOpen('reimbursement')}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0px' }} />
      <div>
        <TableProjectDetail
          columns={columnsReimbursement}
          listData={listReimbursement}
          contentAction={contentActionReimbursement}
          tableChange={tableChangeReimbursement}
          setTableChange={setTableChangeReimbursement}
          popupPermisson={true}
          onRow={true}
          pageSizeOptions={['5', '10', '20', '30', '40', '50']}
        />
      </div>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
        <span className="item-title-text">
          <FormattedMessage id="projectDetailSettingLableGas" defaultMessage="Gas" />
        </span>
        <div>
          <Button01 fontsize="sm" type="primary" onClick={() => handleOpen('gas')}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0px' }} />
      <div>
        <TableProjectDetail
          columns={columnsGas}
          listData={listGas}
          contentAction={contentActionGas}
          tableChange={tableChangeGas}
          setTableChange={setTableChangeGas}
          popupPermisson={true}
          onRow={true}
          pageSizeOptions={['5', '10', '20', '30', '40', '50']}
        />
      </div>

      <ModalReimbursementForm
        visible={openReimbursement}
        setVisibel={setOpenReimbursement}
        setTrigger={setTriggerReimbursement}
        record={recordReimbursement}
        setRecord={setRecordReimbursement}
      />

      <ModalGasForm
        visible={openGas}
        setVisibel={setOpenGas}
        setTrigger={setTriggerGas}
        record={recordGas}
        setRecord={setRecordGas}
      />
    </div>
  );
};

export default ProjectDetailSetting;
