import { CarOutlined, ReloadOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import './css/index.css';
import logoTc from '../../components/image/Logo_TC.png';
import QRCode from 'react-qr-code';
import imgTruck from '../image/truck.svg';
import imgUser from '../image/user.svg';

const DashBoardTab = (props) => {
  const { qrId, setTriggerQr, listMember, listOrder, teamName } = props;
  const qrCodeRef = useRef(null);
  const [order, setOrder] = useState([]);
  const [member, setMember] = useState([]);
  const [timeLeft, setTimeLeft] = useState();
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const date = new Date();
  const currentDate = moment(date).format('DD/MM/YYYY');
  const hours = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const sec = String(date.getSeconds()).padStart(2, '0');
  const formattedTime = `${currentDate} ${hours}:${min}:${sec}`;

  useEffect(() => {
    setTimeLeft(15 * 60);
  }, [qrId])

  useEffect(() => {
    if (listOrder) {
      setOrder([
        {
          text: 'Waiting',
          number: listOrder.waiting,
          color: '#F88511',
          id: 1,
          highlight: true,
        },
        {
          text: 'Ongoing',
          number: listOrder.ongoing,
          color: '#3BA223',
          id: 2,
        },
        {
          text: 'Completed',
          number: listOrder.completed,
          color: '#066738',
          id: 3,
        },
        {
          text: 'Rejected',
          number: listOrder.failed || 0,
          color: '#E73845',
          id: 4,
        },
        {
          text: 'Canceled',
          number: listOrder.canceled || 0,
          color: '#909AAF',
          id: 5,
        },
       
      ]);
    }
  }, [listOrder]);

  useEffect(() => {
    if (listMember) {
      setMember([
        {
          text: 'Active',
          number: listMember.active,
          color: '#066738',
          id: 1,
        },
        {
          text: 'Ongoing',
          number: listMember.ongoing,
          color: '#3BA223',
          id: 2,
        },
        {
          text: 'Available',
          number: listMember.available,
          color: '#51C564',
          id: 3,
        },
        {
          text: 'Offline',
          number: listMember.offline,
          color: '#909AAF',
          id: 4,
        },
      ]);
    }
  }, [listMember]);

  console.log('order', order);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          handleTimeOut();
          return 15 * 60;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleTimeOut = () => {
    setTriggerQr((event) => !event);
  };

  const resetTimer = () => {
    // setTimeLeft(15 * 60);
    setTriggerQr((event) => !event);
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
      <Card
        style={{
          borderRadius: '8px 8px 0px 0px',
          height: '90vh',
          // overflowY: 'auto',
        }}
      >
        <Row style={{ marginBottom: '24px' }}>
          <Col span={12}>
            <div className="dashboard-column-center">
              <img src={logoTc} alt="logo" style={{ width: 'auto', height: '4vw' }} />
              <div className="dashboard-tab-title">{teamName}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="dashboard-column-center ">
              <div className="dashboard-qr">
                <QRCode id={qrId} ref={qrCodeRef} value={qrId} size={(window.innerWidth * 7) / 100} />
              </div>
              <div className="dashboard-qr-time" onClick={resetTimer}>
                <ReloadOutlined className="dashboard-font18" style={{ marginRight: '6px' }} />
                <span className="dashboard-font18" style={{ color: '#000' }}>
                  {minutes}:{seconds < 10 ? '0' : ''}
                  {seconds}
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <div  >
          {order?.map((item) => (
            <Card
              style={{
                padding: 'unset',
                margin: '0.2vw',
                backgroundColor: item.highlight ? '#FFFAE5' : '#fff',
                border: item.highlight ? '1px solid #FFD700' : '1px solid #fff',
                borderRadius: '8px',
              }}
              bodyStyle={{ padding: 'unset' }}
            >
              <div className="dashboard-status">
                <span className="dashboard-font18" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
                  <div
                    style={{
                      width: '1.875vw',
                      height: '3vh ',
                      mask: `url(${imgTruck}) no-repeat center`,
                      WebkitMask: `url(${imgTruck}) no-repeat center`,
                      backgroundColor: item?.color || '#000000',
                      marginRight: '1vw'
                    }}
                  ></div>
                  {item?.text}
                </span>
                <span className={item.highlight ? 'dashboard-font20' : 'dashboard-font18'}>{item?.number}</span>
              </div>
            </Card>
          ))}
          
          <Divider style={{ margin: '1vw 1vw 1vw 0vw', padding: 0 }} />
          {member?.map((item) => (
            <Card
              style={{
                padding: 'unset',
                margin: '0.2vw',
                backgroundColor: item.highlight ? '#FFFAE5' : '#fff',
                border: item.highlight ? '1px solid #FFD700' : '1px solid #fff',
                borderRadius: '8px',
              }}
              bodyStyle={{ padding: 'unset' }}
            >
              <div className="dashboard-status">
                <span className="dashboard-font18" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
                  <div
                    style={{
                      width: '1.875vw',
                      height: '3vh ',
                      mask: `url(${imgUser}) no-repeat center`,
                      WebkitMask: `url(${imgUser}) no-repeat center`,
                      backgroundColor: item?.color || '#000000',
                      marginRight: '1vw'
                    }}
                  ></div>
                  {item?.text}
                </span>
                <span className={item.highlight ? 'dashboard-font20' : 'dashboard-font18'}>{item?.number}</span>
              </div>
            </Card>
          ))}
        </div>
      </Card>

      <div
        className="dashboard-font18"
        style={{
          margin: 0,
          padding: 0,
          // backgroundColor: "#909090",
          backgroundColor: '#F4F4F4',
          borderRadius: '0 0 8px 8px',
          height: '5vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          zIndex: 100
        }}
      >
        {formattedTime}
      </div>
    </div>
  );
};

export default DashBoardTab;
