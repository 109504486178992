import React, { useEffect, useState, useRef } from 'react';
import { Form, Modal, Row, Col, Icon, Divider, Select, Input, InputNumber, DatePicker, TreeSelect } from 'antd';
import './css/index.css';
import Button02 from '../v2/button_02';
import Button01 from '../v2/button_01';
import AppAvatar from '../../components/avatar';
import DefaultProfile from '../../components/image/Profile_Default.png';
import Resizer from 'react-image-file-resizer';
import getTeamPosition from '../../controllers/team/get-team-position';
import PositionModalForm from '../../components/action-position-name/position-modal.js';
import { useIntl, FormattedMessage } from 'react-intl';
import { addNewUserControl, getGenderAndPosition } from '../../pages/users/user/add-user/controller/controller-api';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';

import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const ComponentAddUser = (props) => {
  const intl = useIntl();
  const imageRef = useRef();
  const comCode = localStorage.getItem('comCode');
  const memComId = localStorage.getItem('memComId');
  const { form, visible, setVisible, dataMain, setRefreshTable, routeFrom } = props;
  const { orgId, setOrgId, setOrgIdMain, setExpandedKeys } = dataMain;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;

  const [imageFile, setImageFile] = useState();
  const [imageFileDefault, setImageFileDefault] = useState('');
  const [newImage, setNewImage] = useState();
  const [dataDropDown, setDataDropDown] = useState([]);
  const [selectTeam, setSelectTeam] = useState();

  const [isOpenModalPosition, setIsOpenModalPosition] = useState(false);
  const [triggerPositionApi, setTriggerPositionApi] = useState(false);
  const [positionMasterData, setPositionMasterData] = useState([]);
  const [teamnMasterData, setTeamMasterData] = useState([]);
  const [employeeMasterData, setEmployeeMasterData] = useState([]);
  const [seletePositionData, setSeletePositionData] = useState({});
  const [statusOpen, setStatusOpen] = useState();

  const [positonObject, setPositionObject] = useState();

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span style={{ fontSize: '12px' }}>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '13px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  useEffect(() => {
    const getTeamPositionApi = async () => {
      if (memComId) {
        const response = await getTeamPosition(memComId);
        setPositionMasterData(_.get(response.data, 'positionMaster'));
        setTeamMasterData(_.get(response.data, 'team'));
        setEmployeeMasterData(_.get(response.data, 'employeeTypeMaster'));
      }
    };
    getTeamPositionApi();
  }, [triggerPositionApi]);

  useEffect(() => {
    setImageFileDefault(DefaultProfile);
  }, [DefaultProfile]);

  useEffect(() => {
    getGenderAndPosition(comCode, setDataDropDown);
  }, [comCode]);

  useEffect(() => {
    const posId = getFieldValue('position');
    if (posId) {
      const posObj = positionMasterData.filter((item) => item.positionId === posId);
      setPositionObject(_.get(posObj, '[0]'));
    }
  }, [getFieldValue('position')]);

  const addNewMember = () => {
    const teamId = selectTeam ? selectTeam : orgId;

    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      if (teamId) {
        const resposne = await addNewUserControl(values, newImage, comCode, positonObject);
        if (resposne.status === 200) {
          setOrgId(teamId);
          setOrgIdMain(teamId);
          setExpandedKeys([`${teamId}`]);
          setVisible(false);
          resetFields();
          setImageFile('');
          setNewImage();
          imageRef.current.value = null;
          setTimeout(() => {
            setRefreshTable((current) => !current);
          }, 2000);
        }
      } else {
        const resposne = await addNewUserControl(values, newImage, comCode, positonObject);
        if (resposne.status === 200) {
          setVisible(false);
          resetFields();
          setImageFile('');
          setNewImage();
          imageRef.current.value = null;
          setTimeout(() => {
            setRefreshTable((current) => !current);
          }, 2000);
        }
      }
      // const resposne = await addNewUserControl(values, newImage, comCode, positonObject);
      // if (resposne.status === 200) {
      //   if (teamId || orgId || _.isFunction(setOrgId) || _.isFunction(setExpandedKeys)) {
      //     setOrgId(teamId);
      //     setOrgIdMain(teamId);
      //     setExpandedKeys([`${teamId}`]);
      //     setVisible(false);
      //     resetFields();
      //     setImageFile('');
      //     setNewImage();
      //     imageRef.current.value = null;
      //   }
      //   setVisible(false);
      //   resetFields();
      //   setImageFile('');
      //   setNewImage();
      //   imageRef.current.value = null;
      //   setRefreshTable(current => !current)
      // } else {
      //   setRefreshTable(current => !current)
      // }
    });
  };

  const closeModal = () => {
    setVisible(false);
    resetFields();
    setImageFile('');
    setNewImage();
    imageRef.current.value = null;
  };

  const onChangeTeam = (value) => {
    setSelectTeam(value);
  };

  const handleAddPosition = () => {
    setSeletePositionData({});
    setStatusOpen('add');
    setIsOpenModalPosition(true);
  };

  const handleEditPosition = (positionId) => {
    const filterPosition = positionMasterData.filter((item) => item.positionId.toString() === positionId.toString());

    setSeletePositionData(filterPosition[0]);
    setStatusOpen('edit');
    setIsOpenModalPosition(true);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleChangePicture = async (event) => {
    event.preventDefault();
    let reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      if (file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
        const image = await resizeFile(file);

        reader.onloadend = () => {
          const image = reader.result;
          setImageFile(image);
        };
        reader.readAsDataURL(file);

        const fileChange = dataURLtoFile(image, file.name);
        setNewImage(fileChange);
      } else {
        errorNotification(intl.formatMessage({ id: 'UserManagementAddImageFail', defaultMessage: 'This file is not supported' }));
      }
    }
  };

  const showOpenFileDialog = (e) => {
    imageRef.current.click();
  };

  const deleteImage = (e) => {
    setImageFile('');
    setNewImage();
    imageRef.current.value = null;
  };

  const handleKeyDownEmpcode = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 65 ||
      keyCode === 66
    ) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDownPhone = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 32 || keyCode === 66) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDownEmail = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if (
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 65 ||
      keyCode === 66 ||
      keyCode === 95 ||
      keyCode === 45 ||
      keyCode === 64 ||
      keyCode === 46
    ) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDownName = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 3585 && keyCode <= 3662) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || keyCode === 32) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const validatorEndDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const formateStartDate = moment(startDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateNextDate = moment(value, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    if (formateNextDate <= formateStartDate) {
      callback('Start Date must not be less than End Date');
    } else {
      callback();
    }
  };

  const validatorStartDate = (rule, value, callback) => {
    const endDate = getFieldValue(`endDate`);
    const formateStartDate = moment(value, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    const formateNextDate = moment(endDate, 'ddd, MMM DD, YYYY').format('YYYY-MM-DD');
    if (formateNextDate <= formateStartDate) {
      callback('Start time cannot be greater than the End time.');
    } else {
      callback();
    }
  };

  const handleInputPhone = (value) => {
    setFieldsValue({ ['username']: value });
  };

  return (
    <div>
      <Modal
        title={
          routeFrom === 'org'
            ? intl.formatMessage({ id: 'modalMemberAddEditAddMember', defaultMessage: 'Add Member' })
            : intl.formatMessage({ id: 'UserManagementAddNewUserTiltle', defaultMessage: 'Add New User' })
        }
        width={722}
        visible={visible}
        onCancel={closeModal}
        onOk={addNewMember}
        centered
        footer={[
          <div className="div-button-new">
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={closeModal} className="addbtn_user_ex ">
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={addNewMember} className="addbtn_user_ex2">
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>
          </div>,
        ]}
      >
        <Row>
          <Col span={8}>
            <div className="image-container">
              <AppAvatar size={150} src={`${imageFile ? imageFile : imageFileDefault}`} />

              <input
                ref={imageRef}
                type="file"
                style={{ display: 'none' }}
                accept=".jpg, .png, .JPG, .PNG"
                onChange={handleChangePicture}
              />
              {imageFile ? (
                <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 42, paddingRight: 42 }}>
                  <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                  &nbsp; &nbsp;
                  <Icon type="delete" theme="outlined" style={{ fontSize: '25px' }} onClick={deleteImage} />
                </div>
              ) : (
                <div className="image-container after" style={{ paddingTop: 63, paddingLeft: 62, paddingRight: 62 }}>
                  <Icon type="upload" theme="outlined" style={{ fontSize: '25px' }} onClick={showOpenFileDialog} />
                </div>
              )}
            </div>
          </Col>
          <Col span={16}>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={<LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditName', defaultMessage: 'Name' })} req={true} />}
                >
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalMemberAddEditValidateName', defaultMessage: 'Please input your Name' }),
                      },
                    ],
                  })(
                    <Input
                      className="place-hint"
                      size="default"
                      // onKeyDown={handleKeyDownName}
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerName', defaultMessage: 'Enter Name' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={<LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditEmail', defaultMessage: 'Email' })} req={true} />}
                >
                  {getFieldDecorator('mail', {
                    rules: [
                      {
                        type: 'email',
                        pattern: '/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/',
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateEmailWrong',
                          defaultMessage: 'Please fill in the email format.',
                        }),
                      },
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateEmailWrong',
                          defaultMessage: 'Please fill in the email format.',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="place-hint"
                      size="default"
                      onKeyDown={handleKeyDownEmail}
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerEmail', defaultMessage: 'Enter Email' })}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditEmployeeCode', defaultMessage: 'Employee Code' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('empId', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateEmployeeCode',
                          defaultMessage: 'Please input Employee Code',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="place-hint"
                      size="default"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditPlaceholerEnterEmployeeCode',
                        defaultMessage: 'Enter Employee Code',
                      })}
                      onKeyDown={handleKeyDownEmpcode}
                      autoComplete="off"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditEmployeeType', defaultMessage: 'Employee Type' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('employeeType', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateEmployeeType',
                          defaultMessage: 'Please input your Employee Type',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="member-select-style"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditPlaceholerEmployeeType',
                        defaultMessage: 'Select Employee Type',
                      })}
                      // optionLabelProp="label"
                      showSearch
                      filterOption={(input, option) =>
                        // console.log('filter', input, option)
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {employeeMasterData.length !== 0 &&
                        employeeMasterData.map((e, i) => (
                          <Option key={i} value={e.employeeTypeId}>
                            {e.employeeTypeName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditTeam', defaultMessage: 'Team' })}
                      req={routeFrom === 'org' ? true : false}
                    />
                  }
                >
                  {getFieldDecorator('team', {
                    initialValue: routeFrom === 'org' ? orgId : undefined,
                    rules: [
                      {
                        required: routeFrom === 'org' ? true : false,
                        message: intl.formatMessage({ id: 'modalMemberAddEditValidateTeam', defaultMessage: 'Please input your Team' }),
                      },
                    ],
                  })(
                    <TreeSelect
                      className="member-select-style"
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: '300px', overflow: 'auto', width: '236px' }}
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerEnterTeam', defaultMessage: 'Select Team' })}
                      treeDefaultExpandAll
                      treeData={teamnMasterData}
                      onChange={onChangeTeam}
                      allowClear={routeFrom === 'org' ? false : true}
                      showSearch
                      filterTreeNode={(inputValue, treeNode) =>
                        treeNode.props.title
                          .toString()
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditPosition', defaultMessage: 'Position' })} req={false} />
                  }
                >
                  {getFieldDecorator('position', {
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidatePosition',
                          defaultMessage: 'Please input your Position',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="member-select-style"
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerPosition', defaultMessage: 'Select Position' })}
                      optionLabelProp="label"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.label
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <div className="assign-team-modal-dropdownRender">
                          {menu}
                          <PageSettings.Consumer>
                            {({ checkPermissionAction }) => (
                              <div>
                                {checkPermissionAction(`${props.permissions.page}`, `${props.permissions.add}`) ? (
                                  <div>
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div
                                      style={{ padding: '12px 16px', cursor: 'pointer', color: '#1D3557' }}
                                      onMouseDown={(e) => e.preventDefault()}
                                      onClick={handleAddPosition}
                                    >
                                      <FormattedMessage id="positionAddNew" defaultMessage="Add New Position" />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </PageSettings.Consumer>
                        </div>
                      )}
                    >
                      {positionMasterData && positionMasterData.length > 0
                        ? positionMasterData.map((item) => (
                            <Option className="assign-team-modal-option" key={item.positionId} label={item.positionName}>
                              <Row>
                                <Col className="assign-team-modal-option-text" span={21}>
                                  <span>{item.positionName}</span>
                                </Col>
                                <PageSettings.Consumer>
                                  {({ checkPermissionAction }) => (
                                    <div>
                                      {checkPermissionAction(`${props.permissions.page}`, `${props.permissions.edit}`) ? (
                                        <Col span={3}>
                                          <span onClick={() => handleEditPosition(item.positionId)}>
                                            <FormattedMessage id="positionEdit" defaultMessage="Edit" />
                                          </span>
                                        </Col>
                                      ) : null}
                                    </div>
                                  )}
                                </PageSettings.Consumer>
                              </Row>
                            </Option>
                          ))
                        : null}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditStartDate', defaultMessage: 'Start Date' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('startDate', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalMemberAddEditValidateDate', defaultMessage: 'Please select start Date' }),
                      },
                      // {
                      //   validator: validatorStartDate
                      // }
                    ],
                  })(
                    <DatePicker
                      className="member-date-picker-style"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditPlaceholerSelectStartDate',
                        defaultMessage: 'Select start Date',
                      })}
                      allowClear={false}
                      format="ddd, MMM DD YYYY"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditEndDate', defaultMessage: 'End Date' })} req={false} />
                  }
                >
                  {getFieldDecorator('endDate', {
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'modalMemberAddEditValidateEndDate', defaultMessage: 'Please select end Date' }),
                      },
                      // {
                      //   validator: validatorEndDate
                      // }
                    ],
                  })(
                    <DatePicker
                      className="member-date-picker-style"
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditSelectEndDate', defaultMessage: 'Select end Date' })}
                      allowClear={true}
                      format="ddd, MMM DD YYYY"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider type="horizontal" className="divider-member-org" />

            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditNationalIDNumber', defaultMessage: 'National ID Number' })}
                      req={true}
                    />
                  }
                >
                  {getFieldDecorator('id', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalMemberAddEditValidateNationID', defaultMessage: 'Please input your ID' }),
                      },
                      {
                        min: 13,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateInputCharactorId2',
                          defaultMessage: 'Numbers can not be less than 13 characters.',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="place-hint"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditEnterNationalIDNumber',
                        defaultMessage: 'Enter National ID Number',
                      })}
                      type="text"
                      onKeyDown={handleKeyDownEmpcode}
                      autoComplete="off"
                      maxLength={13}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'modalMemberAddEditDateofBirth', defaultMessage: 'Date of Birth' })}
                      req={false}
                    />
                  }
                >
                  {getFieldDecorator('birthDate', {
                    rules: [
                      {
                        required: false,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateBirthDate',
                          defaultMessage: 'Please input birthDate',
                        }),
                      },
                    ],
                  })(
                    <DatePicker
                      className="member-date-picker-style"
                      allowClear={true}
                      format="ddd, MMM DD YYYY"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditSelectDateofBirth',
                        defaultMessage: 'Select Date of Birth',
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={<LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditGender', defaultMessage: 'Gender' })} req={true} />}
                >
                  {getFieldDecorator('gender', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateYourGender',
                          defaultMessage: 'Please input your Gender',
                        }),
                      },
                    ],
                  })(
                    <Select
                      className="member-select-style"
                      placeholder={intl.formatMessage({ id: 'modalMemberAddEditPlaceholerSelectGender', defaultMessage: 'Select Gender' })}
                    >
                      {dataDropDown.length !== 0 &&
                        dataDropDown.gender.map((e, i) => (
                          <Option key={e.key} value={e.key}>
                            {e.genderName}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="member-org-form-item"
                  colon={false}
                  label={
                    <LabeRequire text={intl.formatMessage({ id: 'modalMemberAddEditPhone', defaultMessage: 'Phone No' })} req={true} />
                  }
                >
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidatePhoneNumber',
                          defaultMessage: 'Please input Phone Number',
                        }),
                      },
                      {
                        min: 6,
                        message: intl.formatMessage({
                          id: 'modalMemberAddEditValidateInputCharactor',
                          defaultMessage: 'Numbers cannot be less than 6 characters.',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="place-hint"
                      placeholder={intl.formatMessage({
                        id: 'modalMemberAddEditPlaceholerEnterPhone',
                        defaultMessage: 'Enter Phone Number',
                      })}
                      type="text"
                      onKeyDown={handleKeyDownPhone}
                      autoComplete="off"
                      onChange={(e) => handleInputPhone(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {(comCode === process.env.REACT_APP_CUSTOMER_TO_KEYCLOAK || true) && (
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <Form.Item
                    className="member-org-form-item"
                    colon={false}
                    label={
                      <LabeRequire
                        text={intl.formatMessage({ id: 'modalMemberAddEditUsername', defaultMessage: 'Username' })}
                        req={true}
                      />
                    }
                  >
                    {getFieldDecorator(
                      'username',
                      {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'modalMemberAddEditValidateUsername',
                              defaultMessage: 'Please input Username',
                            }),
                          },
                        ]
                      }
                    )(
                      <Input
                        className="place-hint"
                        placeholder={intl.formatMessage({
                          id: 'modalMemberAddEditPlaceholerEnterUsername',
                          defaultMessage: 'Enter Username',
                        })}
                        type="text"
                        autoComplete="off"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal>

      <PositionModalForm
        statusOpen={statusOpen}
        isOpenModalPosition={isOpenModalPosition}
        setIsOpenModalPosition={setIsOpenModalPosition}
        seletePositionData={seletePositionData}
        setTriggerPositionApi={setTriggerPositionApi}
        setFieldsValue={setFieldsValue}
      />
    </div>
  );
};

const ModalAddUser = Form.create({ name: 'modal_form_user' })(ComponentAddUser);
export default React.memo(ModalAddUser);
