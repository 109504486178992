import React, { useContext } from 'react';
import { Collapse, Badge, Icon, Input, InputNumber, Select, Col, Row, DatePicker } from 'antd';
import './css/index.css';
import { PlanningContext } from '../../context';
import SettingSvg from '../../../../components/image/settings.svg';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';

const { Panel } = Collapse;
const { Option } = Select;

// intl.formatMessage({ id: 'ModalLocationLocationName', defaultMessage: 'Location Name' })
// <FormattedMessage id="btnSave" defaultMessage="Save" />

export default () => {
  const intl = useIntl();
  const { state, fnc } = useContext(PlanningContext);

  const { handleSearchAll, handleSearchMin, handleSearchMax, handleSelectStatus, handleSelectTeam, handleSelectShippingDate } = fnc;
  const { status, searchTerm, selectStatus, searchMax, searchMin, dataStatus, deliveryGroupList, shippingDate,selectGroup } = state;

  const filterSvg = () => <img className="filterSvg" src={SettingSvg} />;

  const headerTilte = (searchTerm, selectStatus, searchMax, searchMin) => {
    return (
      <div className="header-filter-vehicle">
        <div>
          <span className="header-filter-collapse">
            <FormattedMessage id="planningFilterTxt" defaultMessage="Filter" />
          </span>
        </div>
        <div>
          <Badge className="dot-filter-vehicle" dot={searchTerm || selectStatus || searchMax || searchMin ? true : false}>
            <Icon component={filterSvg} />
          </Badge>
        </div>
      </div>
    );
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const onBlur = (e) => {
    e.preventDefault();
  };

  const renderOptionGroup = (item) => {
    return (
      <Option key={item.group} value={item.group} label={item.groupName}>
        <div>
          <div className="text-group">{item.groupName}</div>
          {/* <div>
            {item.parent.map((ele, index) => (
              <Row key={index} gutter={[12, 0]}>
                <Col style={{ paddingLeft: 16 }} span={21}>
                  <span className="text-content">{ele.statusName}</span>
                </Col>
                <Col span={3}>
                  <span className="text-content">{ele.statusCount}</span>
                </Col>
              </Row>
            ))}
          </div> */}
        </div>
      </Option>
    );
  };

  return (
    <div>
      <div style={{ padding: '10px 16px 0px' }}>
        <span style={{ paddingBottom: 2 }} className="header-filter-collapse">
          <FormattedMessage id="planningFilterDeliveryDate" defaultMessage="Delivery Date" />
        </span>
        <DatePicker
          defaultValue={moment(shippingDate)}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          value={shippingDate}
          onChange={handleSelectShippingDate}
          allowClear={false}
        />
      </div>
      <div style={{ padding: '10px 16px 16px' }}>
        <span style={{ paddingBottom: 2 }} className="header-filter-collapse">
          <FormattedMessage id="planningFilterTeam" defaultMessage="Team" />
        </span>
        <Select
          className="filter-load-select"
          placeholder={intl.formatMessage({ id: 'planningFilterSelectGroup', defaultMessage: 'Select Group' })}
          style={{ width: '100%' }}
          optionLabelProp="label"
          onChange={handleSelectTeam}
          value={selectGroup}
          showSearch
          filterOption={(input, option) =>
            option.props.label
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {deliveryGroupList && deliveryGroupList.map((item) => renderOptionGroup(item))}
        </Select>
      </div>
      <Collapse className="tab-filter-load-route" defaultActiveKey={[]}>
        <Panel header={headerTilte(searchTerm, selectStatus, searchMax, searchMin)} showArrow={false} expandIconPosition={false} key="1">
          <div>
            <div className="filter-space-div">
              <p className="filter-lbl-load">
                <FormattedMessage id="planningFilterOrderNoorLicensePlates" defaultMessage="Order No. or License Plates" />
              </p>
              <Input
                placeholder={intl.formatMessage({ id: 'planningFilterSearch', defaultMessage: 'Search' })}
                onChange={(e) => handleSearchAll(e.target.value)}
                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={searchTerm}
              />
            </div>
            <div className="filter-space-div">
              <p className="filter-lbl-load">
                <FormattedMessage id="planningFilterStatus" defaultMessage="Status" />
              </p>
              <div>
                <Select
                  className="filter-load-select"
                  placeholder={intl.formatMessage({ id: 'planningFilterSelectaStatus', defaultMessage: 'Select a Status Name' })}
                  onChange={handleSelectStatus}
                  allowClear
                  value={selectStatus}
                >
                  {dataStatus &&
                    dataStatus.map((i) => (
                      <Option key={i.statusCode} value={i.statusCode}>
                        {i.statusName}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div>
              <p className="filter-lbl-load">
                <FormattedMessage id="planningFilterLoaded" defaultMessage="Loaded (%)" />
              </p>
              <div className="filter-load-div">
                <div>
                  <Input
                    className="filter-load-number"
                    placeholder={intl.formatMessage({ id: 'planningFilterMin', defaultMessage: 'Min' })}
                    onChange={(e) => handleSearchMin(e.target.value)}
                    onKeyDown={handleKeyDownNumber}
                    maxLength={7}
                    onBlur={onBlur}
                    value={searchMin}
                  />
                </div>
                <div style={{ padding: '0px 10px' }}>-</div>
                <div>
                  <Input
                    className="filter-load-number"
                    placeholder={intl.formatMessage({ id: 'planningFilterMax', defaultMessage: 'Max' })}
                    onChange={(e) => handleSearchMax(e.target.value)}
                    onKeyDown={handleKeyDownNumber}
                    maxLength={7}
                    onBlur={onBlur}
                    value={searchMax}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};
