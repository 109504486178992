import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getMember = async ({ body, projectId }) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/getMember/${projectId}`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createMember = async ({ body }) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/createMember`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateMember = async ({ body, projectId }) => {
  try {
    const response = await httpClient.put(`/v3/project/manager/${comCode}/updateMember/${projectId}`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteMember = async ({ projectId, projectMemberId }) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/deleteMember/${projectId}/${projectMemberId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getUser = async ({ body }) => {
  try {
    const response = await httpClient.post(`/v2/searchusers`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getEmployeeAndTeamType = async () => {
  try {
    const response = await httpClient.get(`/v3/project/manager/RT/getSelectType`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const getMemberTable = async (payload) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/get-member`, payload);
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error("Failed to fetch pet data:", error);
//     throw error;
//   }
// };

export {
  getMember,
  createMember,
  updateMember,
  deleteMember,
  getUser,
  getEmployeeAndTeamType,
  // getMemberTable,
};
