import {
  Table,
  Card,
  Col,
  Row,
  Tabs,
  Button,
  Icon,
  Menu,
  Dropdown,
  Popover,
  Checkbox,
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
} from 'antd';
import React, { useContext, useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Css from './css/index.css';
import _ from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import moment from 'moment';
import { ReciveItemPOContext } from '../reciveitempo-context';
import ButtonActionGroup from '../../../components/action-button';

const { RangePicker } = DatePicker;
const { Option } = Select;

const CardTableRecivePO = () => {
  const { state, setState, fnc } = useContext(ReciveItemPOContext);
  const {
    poList,
    isLoading,
    page,
    total,
    searchGroup,
    brandList,
    dataModel,
    brandId,
    totalBrand,
    totalModel,
    warehouseList,
    totalWarhouse,
    totalGroup,
    totalType,
    itemGroupList,
    itemTypeList,
  } = state;
  const {
    setEvent,
    setSort,
    setSearchGroup,
    setPaginationPage,
    setBrandId,
    dataDefault,
    setTotalBrand,
    setTotalModel,
    setTotalWarhouse,
    setTotalType,
    setTotalGroup,
    setTypeView,
    setWarehouseSerch,
  } = setState;
  const { handleChangePage, handleChangeSize, handleVisibleCreate, handleView, handleEdit, handleCustomerPopupScroll } = fnc;
  const totalItems = total;
  const [scrollTableX, setScrollTableX] = useState(2000);
  const intl = useIntl();
  const ref = useRef();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  console.log('dataDefault', dataDefault);
  const data = [{ total: '1' }];

  const setShowColumn = {
    movementId: true,
    warehouseName: true,
    poNo: true,
    vendorOrSupplier: true,
    itemCode: true,
    itemName: true,
    lotSerialNo: true,
    qtyTxt: true,
    menufacturingDateTxt: true,
    expiredDateTxt: true,
    receivedBy: true,
    receivedDateTxt: true,
  };

  const setShowColumnArr = [
    'movementId',
    'warehouseName',
    'poNo',
    'vendorOrSupplier',
    'itemCode',
    'itemName',
    'lotSerialNo',
    'qtyTxt',
    'menufacturingDateTxt',
    'expiredDateTxt',
    'receivedBy',
    'receivedDateTxt',
  ];

  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      fixed: 'left',
      // render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: <FormattedMessage id="recivePOWarehouse" defaultMessage="Warehouse" />,
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      sorter: true,
      fixed: 'left',
      width: 220,
    },
    {
      title: <FormattedMessage id="recivePOPONo" defaultMessage="PO No." />,
      key: 'poNo',
      dataIndex: 'poNo',
      
      sorter: true,
      width: 170,
    },
    {
      title: <FormattedMessage id="recivePOVendor" defaultMessage="Vendor or Supplier" />,
      key: 'vendorOrSupplier',
      dataIndex: 'vendorOrSupplier',
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOItemCode" defaultMessage="Item Code" />,
      key: 'itemCode',
      dataIndex: 'itemCode',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOItem" defaultMessage="Item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOLotSerial" defaultMessage="Lot No. or Serial No." />,
      key: 'lotSerialNo',
      dataIndex: 'lotSerialNo',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOQty" defaultMessage="Qty" />,
      key: 'qtyTxt',
      dataIndex: 'qtyTxt',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="recivePOManufacturingDate" defaultMessage="Manufacturing Date" />,
      key: 'menufacturingDateTxt',
      dataIndex: 'menufacturingDateTxt',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOExpiredDate" defaultMessage="Expired Date" />,
      key: 'expiredDateTxt',
      dataIndex: 'expiredDateTxt',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="recivePOReceivedby" defaultMessage="Received by" />,
      key: 'receivedBy',
      dataIndex: 'receivedBy',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="recivePOReceivedDate" defaultMessage="Received Date" />,
      key: 'receivedDateTxt',
      dataIndex: 'receivedDateTxt',
      sorter: true,
      width: 150,
    },
  ];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const ignoreColumnButton = ['movementId', 'warehouseName'];
  const columnNew = columns.filter((col) => !ignoreColumnButton.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleView(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                    {_.get(record, 'status') === 'Open' || _.get(record, 'status') === 'Draft' ? (
                      <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEdit(record)}>
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button>
                    ) : null}
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const onChangeTable = (value, index, sort) => {
    setSort(sort);
    setEvent((current) => !current);
  };

  const menuSelectColumn = () => {
    return (
      <Menu>
        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 3) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const onChange = (value, code) => {
    console.log('valueFillter', value);
    setSearchGroup({ ...searchGroup, [code]: value });
    setPaginationPage(1);
  };

  const children =
    dataModel &&
    dataModel.map((item) => (
      <Option key={item.item_model_id} value={item.item_model_id}>
        {item.item_model_name}
      </Option>
    ));

  const formFilterStock = () => {
    return (
      <Form colon={false} style={{ marginLeft: '4px' }}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOWarehouse', defaultMessage: 'Warehouse' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'warehouse')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderWarehouse', defaultMessage: 'Select Warehouse' })}
                onPopupScroll={handleCustomerPopupScroll}
                onSearch={(value) => setWarehouseSerch(value)}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {/* <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setTotalWarhouse(totalWarhouse + 10)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />{' '}
                      <span className="text-add-customer">
                        <FormattedMessage id="RepairTicketBrandLoadmore" defaultMessage="Load More" />
                      </span>
                    </div> */}
                  </div>
                )}
              >
                {warehouseList && warehouseList.map((item, i) => <Option value={item.warehouseId}>{item.warehouseName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOPONo', defaultMessage: 'PO No.' })} req={false} />}>
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'pono')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderPONo', defaultMessage: 'Enter PO No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {' '}
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOItemName', defaultMessage: 'Item Name' })} req={false} />}
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'itemname')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemNamePlaceholder', defaultMessage: 'Enter Item Name' })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'recivePOLotSerial', defaultMessage: 'Lot No. or Serial No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                onChange={(e) => onChange(e.target.value, 'lotser')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderLotSerial', defaultMessage: 'Enter Lot No. or Serial No.' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOItemGroup', defaultMessage: 'Item Group' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'itemgroup')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderItemGroup', defaultMessage: 'Enter Item Group' })}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setTotalGroup(totalGroup + 10)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />{' '}
                      <span className="text-add-customer">
                        <FormattedMessage id="RepairTicketBrandLoadmore" defaultMessage="Load More" />
                      </span>
                    </div>
                  </div>
                )}
              >
                {_.map(itemGroupList, (item) => (
                  <Option key={item.item_group_code} value={item.item_group_code}>
                    {item.item_group_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOItemType', defaultMessage: 'Item Type' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => onChange(value, 'itemtype')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderItemType', defaultMessage: 'Select Item Type' })}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setTotalType(totalType + 10)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />{' '}
                      <span className="text-add-customer">
                        <FormattedMessage id="RepairTicketBrandLoadmore" defaultMessage="Load More" />
                      </span>
                    </div>
                  </div>
                )}
              >
                {_.map(itemTypeList, (item) => (
                  <Option key={item.item_type_code} value={item.item_type_code}>
                    {item.item_type_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOBrand', defaultMessage: 'Brand' })} req={false} />}>
              <Select
                allowClear
                onChange={(value) => {
                  onChange(value, 'brand');
                  setBrandId(value);
                }}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderBrand', defaultMessage: 'Select Brand' })}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setTotalBrand(totalBrand + 10)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />{' '}
                      <span className="text-add-customer">
                        <FormattedMessage id="RepairTicketBrandLoadmore" defaultMessage="Load More" />
                      </span>
                    </div>
                  </div>
                )}
              >
                {brandList && brandList.map((item, i) => <Option value={item.item_brand_id}>{item.item_brand_name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOModel', defaultMessage: 'Model' })} req={false} />}>
              <Select
                allowClear
                disabled={brandId ? false : true}
                onChange={(value) => onChange(value, 'model')}
                placeholder={intl.formatMessage({ id: 'recivePOPlaceholderModel', defaultMessage: 'Select Model' })}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setTotalModel(totalModel + 10)}
                    >
                      <Icon type="plus" className="customer-icon-plus" />{' '}
                      <span className="text-add-customer">
                        <FormattedMessage id="RepairTicketModelLoadmore" defaultMessage="Load More" />
                      </span>
                    </div>
                  </div>
                )}
              >
                {children}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'recivePOManufacturingDate', defaultMessage: 'Menufacturing Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                allowClear
                value={_.get(searchGroup, 'mandate')}
                onChange={(value) => onChange(value, 'mandate')}
                placeholder={[
                  intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'recivePOPlaceholderManufacturingDateTo', defaultMessage: 'Select To Date' }),
                ]}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'recivePOExpiredDate', defaultMessage: 'Expired Date' })} req={false} />
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                allowClear
                value={_.get(searchGroup, 'expiredDate')}
                onChange={(value) => onChange(value, 'expiredDate')}
                placeholder={[
                  intl.formatMessage({ id: 'recivePOPlaceholderExpiredDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'recivePOPlaceholderExpiredDateTo', defaultMessage: 'Select To Date' }),
                ]}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button ghost type="link" onClick={() => handleVisibleCreate()}>
          <FormattedMessage id="recivePOReceiveItemfromPO" defaultMessage="Receive Item from PO" />
        </Button>
      </Menu.Item>

      {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? (
        <Menu.Item>
          <Button ghost type="link" onClick={() => changeToReject()}>
            <FormattedMessage id="ToReviewReject" defaultMessage="Reject" />
          </Button>
        </Menu.Item>
      ) : null} */}
    </Menu>
  );

  return (
    <div>
      {/* <PageSettings.Consumer>
        {({ checkPermissionAction }) =>
          checkPermissionAction(`P1PG1`, `P1PG1A1`) ? ( */}
            <Card
              title={<FormattedMessage id="menuReceiveItemfromPO" defaultMessage="Receive Item from PO" />}
              className="right-cards"
              style={{ margin: '8px' }}
            >
              <div style={{ marginTop: '20px' }}>
                <CollapseCustom
                  label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
                  extra={
                    <div style={{ display: 'flex' }}>
                      <Dropdown
                        overlay={menuSelectColumn}
                        trigger={['click']}
                        placement="bottomRight"
                        onVisibleChange={handleVisibleChange}
                        visible={visible}
                      >
                        <Button_01 key="submit" type="primary" btnsize="wd_df" fontSize="sm" onClick={() => {}}>
                          <FormattedMessage id="reportReapirTicketReportBtnColumns" defaultMessage="Columns" />
                          <DownOutlined style={{ fontSize: '14px', paddingLeft: '4%' }} />
                        </Button_01>
                      </Dropdown>
                      <ButtonActionGroup menu={menuColumn} />
                    </div>
                  }
                >
                  {formFilterStock()}
                </CollapseCustom>
              </div>
              <div
                className="containerTables scroll-sm"
                onMouseLeave={handleMouseLeave}
              >
                <Row gutter={16}>
                  <Table
                    className="custom-table-claim scroll-sm"
                    dataSource={poList || []}
                    columns={newDataColumns}
                    scroll={{ x: scrollTableX, y: `calc(100vh - 400px)` }}
                    loading={isLoading}
                    onChange={onChangeTable}
                    pagination={{
                      total: totalItems,
                      current: page,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: '' },
                      showTotal: showTotal,
                      onChange: handleChangePage,
                      onShowSizeChange: handleChangeSize,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          if (!event.target.href) {
                            const { x, y } = ref.current.getBoundingClientRect();
                            setVisiblePopoverTable(true);
                            setOffsetPopover([event.pageX - x, event.pageY - y]);
                            setRecordPopover(record);
                          }
                        },
                      };
                    }}
                  />
                  {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
                  {total > 0 ? (
                    <Col span={12}>
                    <div className="total-items-member">
                      <span style={{ fontSize: '13px' }}>
                        <FormattedMessage id="lblTotal" defaultMessage="Total" />
                        {` ${total || 0} `}
                        <FormattedMessage id="lblitems" defaultMessage="items" />
                      </span>
                    </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </Card>
          {/* ) : null
        }
      </PageSettings.Consumer> */}
    </div>
  );
};

export default CardTableRecivePO;
