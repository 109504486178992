import React, { useContext } from 'react'
import CollapseCustom from '../../../components/collapse-custom'
import { useIntl } from 'react-intl'
import { Input, Form, Select, DatePicker, Row, Col } from 'antd'
import LabeRequireForm from '../../../components/label-required-form'
import Button_02 from '../../../components/v2/button_02'
import Button_01 from '../../../components/v2/button_01'
import { FormattedMessage } from 'react-intl'
import { ExpenseContext } from '..'
import _ from 'lodash'

const { RangePicker } = DatePicker
const { Option } = Select

const ExpenseAction = (props) => {
  const { showStatus } = props
  const intl = useIntl()
  const { onChangeSearch, poList, setTrigger, onResetSearch, searchGroup, setRangDate, rangDate, statusList } = useContext(ExpenseContext)

  const formFilter = () => {
    return (
      <Form colon={false} className='expenseForm'>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'expenseFilterExpenseNo', defaultMessage: 'Expense NO.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'expenseFilterPHExpenseNo', defaultMessage: 'Enter Expense NO.' })}
                onChange={(e) => onChangeSearch(e.target.value, 'expenseNo')}
                value={_.get(searchGroup, 'expenseNo')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'expenseFilterVendor', defaultMessage: 'Vendor or Supplier' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'expenseFilterPHVendor', defaultMessage: 'Enter Vendor or Supplier' })}
                onChange={(e) => onChangeSearch(e.target.value, 'vendor')}
                value={_.get(searchGroup, 'vendor')}
              />
            </Form.Item>
          </Col>
          {
            showStatus ? (
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'expenseFilterStatus', defaultMessage: 'Status' })}
                      req={false}
                    />
                  }
                >
                  <Select
                    allowClear={true}
                    placeholder={intl.formatMessage({ id: 'expenseFilterPHStatus', defaultMessage: 'Select Status' })}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onChange={(e) => onChangeSearch(e, 'status')}
                    value={_.get(searchGroup, 'status')}
                  >
                    {statusList && statusList.map((item) => <Option key={item.code}>{item.txt}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
            ) : null
          }
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'expenseFilterDueDate', defaultMessage: 'Due Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                style={{ width: '100%' }}
                onChange={(e) => onChangeSearch(e, 'dueDate')}
                value={_.get(searchGroup, 'dueDate')}
                placeholder={[
                  intl.formatMessage({ id: 'expenseFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                  intl.formatMessage({ id: 'expenseFilterPHToDueDate', defaultMessage: 'Selet To Date' })
                ]}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'expenseFilterPONo.', defaultMessage: 'PO No.' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'expenseFilterPHPONo', defaultMessage: 'Select PO No.' })}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(e) => onChangeSearch(e, 'poNo')}
                value={_.get(searchGroup, 'poNo')}
              >
                {poList && poList.map((item) => <Option key={item.poNo}>{item.poNo}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'expenseFilterInvoice', defaultMessage: 'Invoice No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'expenseFilterPHInvoice', defaultMessage: 'Enter Invoice No.' })}
                onChange={(e) => onChangeSearch(e.target.value, 'invoiceNo')}
                value={_.get(searchGroup, 'invoiceNo')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]} style={{ marginTop: '16px' }}>
          <Col span={24} style={{ textAlign: 'end' }}>
            <Button_02 style={{ margin: '0px 10px 0px 0px' }} key="back" btnsize="wd_df" margin="unset" onClick={onResetSearch}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button_02>
            <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: '0px' }} onClick={() => setTrigger(cur => !cur)}>
              <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
            </Button_01>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'expenseFilterPHFormDate', defaultMessage: 'Select Form Date' }),
                intl.formatMessage({ id: 'expenseFilterPHToDate', defaultMessage: 'Selet To Date' })
              ]}
              value={rangDate}
              onChange={(e) => setRangDate(e)}
              format="DD/MM/YYYY"
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div >
  )
}

export default ExpenseAction
