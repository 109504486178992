import httpCiient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getConfigMenu = async (module) => {
  try {
    const response = await httpCiient.get(`/v4/resource/manager/companies/${comCode}/getMenu?configModule=${module}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getConfigMenu };