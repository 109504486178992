import React from 'react';
import httpClient from '../../components/axiosClient';
import moment from 'moment';

export const updateUserPersonalInformation = async (userId, values) => {

  const body = {
    version: 2,
    name: values.name,
    lastname: values.lastname,
    emp_code: values.emp_code,
    employee_type_id: values.employeeType,
    phone: values.phone,
    email: values.mail,
    id_card: values.id_card,
    start_date: moment(values.startDate, 'ddd, MMM DD YYYY').format('YYYY-MM-DD') !== 'Invalid date' ? moment(values.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
    resign_date: moment(values.endDate, 'ddd, MMM DD YYYY').format('YYYY-MM-DD') !== 'Invalid date' ? moment(values.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
    birthday: moment(values.birthDate, 'ddd, MMM DD YYYY').format('YYYY-MM-DD') !== 'Invalid date' ? moment(values.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
    gender: values.gender ? values.gender : '',
    // username: values.username ? values.username : '',
  }

  try {

    const response = await httpClient.post(`/v3/resource/manager/member/${userId}`, body);
    if (response.status === 200) {
      return response
    } else {
      return response
    }
  } catch (e) {
    return e.response
  }
}

export const getUserPersonalInfomation = async (formData) => {

  try {
    const results = await httpClient.post(`/resource/manager/upload/members-profile`, formData);
    return results
  } catch (e) {
    return e.results;
  }
}
