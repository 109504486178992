import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Row, Col, Select, Divider, Table, Progress, InputNumber, Checkbox } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import LabeRequireForm from '../../label-required-form';
import { handleKeyDownNumber, handleKeyDownDecimal2Fixed } from '../../../component-function/fnc-number';
import { getStockListById, getConvertUoM, getStockList, getConvertUoMBigger } from '../../../controllers/po/po';
import _ from 'lodash';
import { numberFormatter, numberParser } from '../../../component-function/fnc-inputnumber-currency';
import SuffixInput from '../../v2/suffix';
import { Round } from '../../../component-function/lodash';
import chagneVatItem from '../calvat';
import '../css/item.css';

const { Option } = Select;

const ListItemEdit = (props) => {
  const { data, visible, setVisible, form, selectItem, setSelectItem, deliveryList } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue } = form;
  const [stockList, setStockList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const intl = useIntl();
  // const price = parseFloat(getFieldValue('price') || _.get(data, 'pricePerUnit') ? _.get(data, 'pricePerUnit') : 0.0);
  // const discount = parseFloat(getFieldValue('discount') || _.get(data, 'discountPercentage') ? _.get(data, 'discountPercentage') : 0);
  // const discountNumber = parseFloat(
  //   getFieldValue('discountNumber') || parseFloat(_.get(data, 'discountPrice')) ? _.get(data, 'discountPrice') : 0.0
  // );
  const qty = parseFloat(getFieldValue('qty') || 0);
  const price = parseFloat(getFieldValue('price') || 0.0);
  const discount = parseFloat(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const amount = Round(qty * price);
  // const percentNumber = Round(price * discount) / 100;
  const totalPercent = Round((amount * discount) / 100);
  const percent = Round((discountNumber / amount) * 100);
  const totalPrice = amount - discountNumber;

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [checkUOM, setCheckUOM] = useState(true);
  const [total, setTotal] = useState(0);
  const inputNumberRef = useRef();
  const inputNumberQTYRef = useRef();
  const inputNumberPrice = useRef();

  const [checkExVat, setCheckExVat] = useState(false);
  const [checkEditExVat, setCheckEditExVat] = useState(false);

  useEffect(() => {
    if (qty || price) {
      setFieldsValue({
        ['amount']: numberFormatter(amount),
      });
    }
  }, [qty, price, paginationPage, paginationSize]);

  // useEffect(() => {
  //   if (price && price > 0) {
  //     setFieldsValue({
  //       ['discountNumber']: totalPercent.toFixed(2),
  //       ['total']: numberFormatter(totalPrice),
  //     });
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0.0,
  //       ['discountNumber']: 0.0,
  //       ['total']: 0.0,
  //     });
  //   }
  // }, [price, getFieldValue('amount')]);

  // useEffect(() => {
  //   if (discountNumber && discountNumber > 0) {
  //     setFieldsValue({
  //       ['discount']: percent.toFixed(2),
  //       ['total']: numberFormatter(totalPrice),
  //     });
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //       ['total']: numberFormatter(amount),
  //     });
  //   }
  // }, [discountNumber]);

  // useEffect(() => {
  //   if (discount && discount > 0) {
  //     setFieldsValue({
  //       ['discountNumber']: totalPercent.toFixed(2),
  //       ['total']: numberFormatter(totalPrice),
  //     });
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //       ['total']: numberFormatter(amount),
  //     });
  //   }
  // }, [discount]);

  useEffect(() => {
    const getStockList = async () => {
      const response = await getStockListById(_.get(data, 'itemMasterId'));
      // const unitData = await getConvertUoM(_.get(data, 'itemMasterId'));
      const unitData = await getConvertUoMBigger(_.get(data, 'itemMasterId'));
      // setStockList([])
      setStockList(_.get(response, 'data.data.stockList'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (data && visible) {
      getStockList();
    }
  }, [data, visible]);

  useEffect(() => {
    if (_.get(data, 'uomId') && visible === true && _.size(uomList) > 0) {
      const filteruom = _.filter(uomList, (item) => {
        return _.get(item, 'uomId') === _.get(data, 'uomId');
      });
      console.log('filteruom', filteruom, '/value:', _.get(data, 'uomId'), '/uomList:', uomList);
      if (_.get(filteruom[0], 'isDefault') === true) {
        setCheckUOM(true);
      } else {
        setCheckUOM(false);
      }
    }
  }, [visible, uomList]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouseCode', defaultMessage: 'Warehouse Code' }),
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      width: 160,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsWarehouse', defaultMessage: 'Warehouse' }),
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record, index) => renderProgress(record),
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMinQty', defaultMessage: 'Min Qty' }),
      dataIndex: 'minQty',
      key: 'minQty',
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'modalListColumnsMaxQty', defaultMessage: 'Max Qty' }),
      dataIndex: 'maxQty',
      key: 'maxQty',
      width: 100,
    },
  ];

  const renderProgress = (record) => {
    let color = '';
    let percent = 0;

    if (record.qty === 0) {
      color = '#e73845';
      percent = 100;
    } else if (record.qty < record.minQty) {
      color = '#febb02';
      percent = (record.qty / record.maxQty) * 100;
    } else if (record.qty > record.minQty) {
      color = '#6490cf';
      percent = (record.qty / record.maxQty) * 100;
    }

    return (
      <div className="progress-item">
        <Progress className="bar-progress-custom" strokeColor={color} percent={percent} showInfo={false} />{' '}
        <span className="progress-qty-value">{record.qtyDisplay}</span>
      </div>
    );
  };

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const response = await getStockList({ itemMasterId: [_.get(data, 'itemMasterId')] });
      const filterUoM = _.filter(uomList, (item) => {
        return item.uomId === _.get(values, 'unit');
      });
      const newPoId = _.toString(_.get(data, 'itemMasterId') + parseFloat(_.get(values, 'price')) + parseFloat(_.get(values, 'discount')));
      const filterPOId = _.findIndex(selectItem, (item) => {
        return item.itemPoId === newPoId;
      });

      const fillChcekDe = _.chain(deliveryList)
        .map((item) => {
          const tempfill = _.filter(_.get(item, 'itemList'), (i) => {
            return i.itemPoId === newPoId;
          });
          // console.log('fillChcekDe',tempfill);
          return _.get(tempfill, '[0]');
        })
        .value();

      const tempfill2 = _.filter(fillChcekDe, (i) => {
        console.log('fillChcekDel', i, '/', newPoId);
        return _.get(i, 'itemPoId') === newPoId;
      });

      console.log('PoIdFill', filterPOId, '/', data);
      if (filterPOId !== -1 && _.get(data, 'itemPoId') !== newPoId) {
        selectItem[filterPOId].qty = selectItem[filterPOId].qty + parseFloat(_.get(values, 'qty'));
        selectItem[filterPOId].count = selectItem[filterPOId].count + parseFloat(_.get(values, 'qty'));
        selectItem[filterPOId].countTemp = selectItem[filterPOId].countTemp + parseFloat(_.get(values, 'qty'));
        selectItem[filterPOId].discountPrice = selectItem[filterPOId].discountPrice + parseFloat(_.get(values, 'discountNumber'));
        selectItem[filterPOId].totalPrice = selectItem[filterPOId].totalPrice + numberParser(_.get(values, 'total'));
        selectItem[filterPOId].itemPoId = newPoId;
        const filterPOIdDelete = _.filter(selectItem, (item) => {
          return item.itemPoId !== _.get(data, 'itemPoId');
        });
        console.log('filterPOIdDelete', filterPOIdDelete);
        setSelectItem(
          _.map(filterPOIdDelete, (item, index) => {
            return {
              ...item,
              index: index + 1,
            };
          })
        );
      } else if (filterPOId !== -1 && _.size(tempfill2) > 0) {
        const qty = parseFloat(_.get(values, 'qty')) - selectItem[filterPOId].qty;
        const count = parseFloat(_.get(values, 'qty')) - selectItem[filterPOId].count;
        const conutTemp = parseFloat(_.get(values, 'qty')) - selectItem[filterPOId].countTemp;
        const discountPrice = parseFloat(_.get(values, 'discountNumber')) - selectItem[filterPOId].discountPrice;
        const totalPrice = numberParser(_.get(values, 'total')) - selectItem[filterPOId].totalPrice;
        console.log('filterPOIdDelete', parseFloat(_.get(values, 'qty')), '/', selectItem[filterPOId].countTemp);

        selectItem[filterPOId].qty = selectItem[filterPOId].qty + parseFloat(qty);
        selectItem[filterPOId].count = selectItem[filterPOId].count + parseFloat(qty);
        selectItem[filterPOId].countTemp = selectItem[filterPOId].countTemp + parseFloat(qty);
        selectItem[filterPOId].discountPrice = selectItem[filterPOId].discountPrice + parseFloat(discountPrice);
        selectItem[filterPOId].totalPrice = selectItem[filterPOId].totalPrice + numberParser(_.toString(totalPrice));
        selectItem[filterPOId].itemPoId = newPoId;

        setSelectItem((e) => _.orderBy(e, ['index'], ['asc']));
      } else {
        const itemBody = {
          id: _.get(data, 'id'),
          index: _.get(data, 'index'),
          itemMasterId: _.get(data, 'itemMasterId'),
          itemCode: _.get(data, 'itemCode'),
          itemName: _.get(data, 'itemName'),
          qty: parseFloat(_.get(values, 'qty')),
          count: parseFloat(_.get(values, 'qty')),
          countTemp: parseFloat(_.get(values, 'qty')),
          uomId: _.get(values, 'unit'),
          uomName: _.get(filterUoM, '[0].name'),
          pricePerUnit: parseFloat(_.get(values, 'price')),
          // "promotionCampaign": _.get(values, 'promotion'),
          discountPercentage: parseFloat(_.get(values, 'discount') || 0),
          discountPrice: parseFloat(_.get(values, 'discountNumber') || 0),
          totalPrice: numberParser(_.toString(_.get(values, 'total'))),
          remark: _.get(values, 'remark') || '',
          instock: _.get(response, 'data.data[0].qty') || 0,
          itemPoId: _.toString(_.get(data, 'itemMasterId') + parseFloat(_.get(values, 'price')) + parseFloat(_.get(values, 'discount'))),
          type: 'product',
          checkVat: checkEditExVat ? checkExVat : _.get(data, 'checkVat'),
        };
        const filterSelect = _.filter(selectItem, (item) => {
          return item.index !== _.get(data, 'index');
        });
        setSelectItem(filterSelect.concat([itemBody]));
       
      }
      setVisible(false);
      resetFields();
      setCheckEditExVat(false);
      setCheckExVat(false)
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setCheckEditExVat(false);
    setCheckExVat(false)
  };

  const validatorQty = (rule, value, callback) => {
    if (value === 0) {
      callback('Qty must more than 0');
    } else if (value) {
      const FilterUom = _.filter(uomList, (item) => {
        return item.uomId === getFieldValue('unit');
      });

      const altQty = _.get(FilterUom, '[0].altQty');

      const parsValue = parseFloat(value * altQty || 0);
      if (parsValue === 0) {
        callback('QTY must more than 0');
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const currencyFormatter = (value) => {
    console.log('currencyFormatterx1', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  const currencyParser = (value) => {
    console.log('currencyParserx2', value);
    if (parseFloat(value) >= 0) {
      return value.replace(/\$\s?|(,*)/g, '');
    }
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleChange = (pagination, sorter, extra) => {
    // let field = extra.field;
    // if (extra.order) {
    //   if (extra.order === 'descend') {
    //     setOrderSort('desc');
    //   } else {
    //     setOrderSort('asc');
    //   }
    // } else {
    //   setOrderSort();
    // }
    // setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleChangeQTY = (e) => {
    const calAmount = Round(parseFloat(e) * price);
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(calAmount),
      });
    } else {
      setFieldsValue({
        ['price']: 0.0,
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleChangeDiscountNumber = (e) => {
    const calPercent = Round((parseFloat(e) / amount) * 100);
    const calTotalPrice = amount - parseFloat(e);
    if (e && e > 0) {
      setFieldsValue({
        ['discount']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangeDiscount = (e) => {
    const calPercent = Round((amount * parseFloat(e)) / 100);
    const calTotalPrice = amount - calPercent;
    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: numberFormatter(amount),
      });
    }
  };

  const handleChangePrice = (e) => {
    const amount = Round(qty * parseFloat(e));
    const calTotalPercent = Round((amount * discount) / 100);
    const calTotalPrice = amount - calTotalPercent;

    if (e && e > 0) {
      setFieldsValue({
        ['discountNumber']: calTotalPercent.toFixed(2),
        ['total']: numberFormatter(calTotalPrice),
      });
    } else {
      setFieldsValue({
        ['discount']: 0.0,
        ['discountNumber']: 0.0,
        ['total']: 0.0,
      });
    }
  };

  const handleCheckUnit = (value) => {
    const filteruom = _.filter(uomList, (item) => {
      return _.get(item, 'uomId') === value;
    });
    console.log('filteruom', filteruom, '/value:', value, '/uomList:', uomList);
    if (_.get(filteruom[0], 'isDefault') === true) {
      setFieldsValue({
        ['qty']: '',
      });
      setCheckUOM(true);
    } else {
      setFieldsValue({
        ['qty']: '',
      });
      setCheckUOM(false);
    }
  };

  const mouseOver = (value) => {
    inputNumberRef.current.blur();
    inputNumberQTYRef.current.blur();
    inputNumberPrice.current.blur();
  };

  const chagneVat = (check) => {
    const finalcal = chagneVatItem(check, getFieldValue('price'));
    setCheckEditExVat(true);
    setCheckExVat(check);
    setFieldsValue({
      ['price']: finalcal,
    });
    handleChangePrice(finalcal);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: `modalListItemTitleEdit`, defaultMessage: 'Edit Item' })}
      centered={true}
      width={900}
      onCancel={handleCancel}
      visible={visible}
      bodyStyle={{ padding: 'unset', height: '650px', overflowY: 'auto' }}
      destroyOnClose={true}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleSave}
          onMouseOver={() => {
            mouseOver();
          }}
          style={{ margin: 'unset' }}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <div style={{ padding: '24px', overflowY: 'auto' }}>
        <div style={{ marginBottom: '28px' }}>
          <div style={{ marginBottom: '8px', color: '#1D3557', fontSize: '12px' }}>
            <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleItem', defaultMessage: 'Item' })} req={false} />
          </div>
          <div style={{ color: '#050505', fontSize: '13px' }}>
            {_.get(data, 'itemCode')} {_.get(data, 'itemName')}
          </div>
        </div>
        <Form form={form} layout="vertical">
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleQty', defaultMessage: 'Qty' })} req={true} />}
              >
                {getFieldDecorator('qty', {
                  initialValue: _.get(data, 'qty'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateQty', defaultMessage: 'Please Enter Qty' }),
                    },
                    {
                      validator: validatorQty,
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderQty', defaultMessage: 'Enter Qty' })}
                    onKeyDown={checkUOM === false ? handleKeyDownDecimal2Fixed : handleKeyDownNumber}
                    ref={inputNumberQTYRef}
                    onChange={handleChangeQTY}
                    //formatter={currencyFormatter}
                    parser={currencyParser}
                    //controls={false}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleUnit', defaultMessage: 'Unit' })} req={true} />
                }
              >
                {getFieldDecorator('unit', {
                  initialValue: _.get(data, 'uomId'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidateUnit', defaultMessage: 'Please Select Unit' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderUnit', defaultMessage: 'Select Unit' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    onChange={(e) => handleCheckUnit(e)}
                  >
                    {_.map(uomList, (item) => (
                      <Option value={item.uomId}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-label"
                label={
                  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                    <Col span={10}>
                      <span>
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'modalListItemFormTitlePrice', defaultMessage: 'Price/Unit' })}
                          req={true}
                        />
                      </span>
                    </Col>
                    {/*                     {/* <Col span={14} style={{ textAlign: 'right' }}>
                      <Checkbox
                        checked={checkEditExVat ? checkExVat : _.get(data, 'checkVat')}
                        onChange={(e) => chagneVat(e.target.checked)}
                      >
                        <FormattedMessage id="PoExclVAT7" defaultMessage="Excl. VAT 7%" />
                      </Checkbox>
                    </Col> */} 
                  </Row>
                }
                labelCol={{ style: { width: '100%', paddingBottom: '2px' } }}
              >
                {getFieldDecorator('price', {
                  initialValue: _.get(data, 'pricePerUnit'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'modalListItemFormValidatePrice', defaultMessage: 'Please Enter Price/Unit' }),
                    },
                  ],
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    onChange={handleChangePrice}
                    //formatter={currencyFormatter}
                    parser={currencyParser}
                    ref={inputNumberPrice}
                    //controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleAmount', defaultMessage: 'Amount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('amount', {
                  initialValue: Round(_.get(data, 'pricePerUnit') * _.get(data, 'qty')),
                })(<Input maxLength={100} disabled={true} />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleDiscount', defaultMessage: 'Discount' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('discount', {
                  initialValue: _.get(data, 'discountPercentage'),
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    ref={inputNumberRef}
                    onChange={handleChangeDiscount}
                    //formatter={currencyFormatter}
                    parser={currencyParser}
                    //controls={false}
                  />
                )}
                <SuffixInput text="%" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('discountNumber', {
                  initialValue: _.get(data, 'discountPrice'),
                })(
                  <InputNumber
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderPrice', defaultMessage: 'Enter Price/Unit' })}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    onChange={handleChangeDiscountNumber}
                    //formatter={currencyFormatter}
                    parser={currencyParser}
                    //controls={false}
                  />
                )}
                <SuffixInput text="THB" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'modalListItemFormTitleTotal', defaultMessage: 'Total' })} req={false} />
                }
              >
                {getFieldDecorator('total', {
                  initialValue: _.get(data, 'totalPrice'),
                })(
                  <Input
                    placeholder={intl.formatMessage({ id: 'modalListItemFormPlaceholderTotal', defaultMessage: 'Enter Total' })}
                    maxLength={100}
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    suffix="THB"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'modalListItemFormTitleRemark', defaultMessage: 'Remark' })}
                    req={false}
                  />
                }
              >
                {getFieldDecorator('remark', {
                  initialValue: _.get(data, 'remark'),
                })(<Input maxLength={100} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div style={{ marginTop: '32px' }}>
          <div>
            <FormattedMessage id="modalListItemFilterTextStockBalance" defaultMessage="Stock Balance" />
          </div>
          <Divider type="horizontal" />
          <div>
            <Table
              columns={columns}
              dataSource={stockList}
              scroll={{ y: 120 }}
              onChange={handleChange}
              pagination={{
                total: total,
                current: paginationPage,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: true,
                locale: { items_per_page: '' },
              }}
            />
            {total > 0 ? (
              <div className="total-items-member">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModalEditListItem = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(ListItemEdit);

export default ModalEditListItem;
