import _ from "lodash";

const chagneVatItem = (check,price) => {
    const value = price;
    const past = _.round(value * (100/107),2)
    const vat = _.round(value * (7 / 100),2);

    if (check) {
      return past;
    } else {
      const cal = value + vat;
      return cal
    }
  };

  export default chagneVatItem