import { useLocation } from 'react-router-dom';

const dd = (data) => { };

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const checkDefaultPath = (pathname) => {
  let openKey = '';
  let selectKey = '';

  if (pathname.indexOf('/dashboard') > -1) {
    openKey = ['dashboard'];
    selectKey = 'dashboard';
  }
  if (pathname.indexOf('/resource') > -1) {
    openKey = ['resource'];
    selectKey = 'resource';
  }
  if (pathname.indexOf('/resource/organization') > -1) {
    openKey = ['resource'];
    selectKey = 'organization';
  }
  if (pathname.indexOf('/resource/allowance') > -1) {
    openKey = ['resource'];
    selectKey = 'resource-allowance';
  }
  if (pathname.indexOf('/resource/member-expense') > -1) {
    openKey = ['resource'];
    selectKey = 'member-expense';
  }
  if (pathname.indexOf('/resource/organization/users-detail/') > -1) {
    openKey = ['resource'];
    selectKey = 'organization';
  }
  if (pathname.indexOf('/resource/report') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'resource-report';
  }
  if (pathname.indexOf('/resource/asset') > -1) {
    openKey = ['resource'];
    selectKey = 'asset';
  }
  if (pathname.indexOf('/resource/fine') > -1) {
    openKey = ['resource'];
    selectKey = 'fine';
  }
  if (pathname.indexOf('/resource/report/time-attendance') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'time-attendance';
  }
  if (pathname.indexOf('/resource/report/benefit-deduct') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'benefit-deduct-report';
  }
  if (pathname.indexOf('/resource/report/overtime') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'overt-time';
  }
  if (pathname.indexOf('/resource/report/leave-report') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'leave';
  }
  if (pathname.indexOf('/resource/report/outside') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'outside';
  }
  if (pathname.indexOf('/resource/report/asset-expense-report') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'asset-expense-report';
  }
  if (pathname.indexOf('/resource/report/borrow') > -1) {
    openKey = ['resource', 'resource-report'];
    selectKey = 'equipment';
  }
  if (pathname.indexOf('/resource/setting') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'resource-setting';
  }
  if (pathname.indexOf('/resource/setting/request-type') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'request-type';
  }
  if (pathname.indexOf('/resource/setting/shift-type') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'shift-type';
  }
  if (pathname.indexOf('/resource/setting/holidays') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'holidays';
  }
  if (pathname.indexOf('/resource/setting/skill') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'skill';
  }
  if (pathname.indexOf('/resource/setting/service-area') > -1) {
    openKey = ['resource', 'resource-setting'];
    selectKey = 'service-area';
  }
  if (pathname.indexOf('/crm') > -1) {
    openKey = ['crm'];
    selectKey = 'crm';
  }
  if (pathname.indexOf('/crm/customers') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-customer';
  }
  if (pathname.indexOf('/customers/customer-detail/') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-customer';
  }
  if (pathname.indexOf('/crm/sale-order') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-sale-order';
  }
  if (pathname.indexOf('/crm/engagement') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-engagement';
  }
  if (pathname.indexOf('/crm/quotation') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-quotation';
  }

  if (pathname.indexOf('/sales') > -1) {
    openKey = ['sales'];
    selectKey = 'sales';
  }
  if (pathname.indexOf('/sales/store') > -1) {
    openKey = ['sales'];
    selectKey = 'sales-store';
  }
  if (pathname.indexOf('/sales/quotation') > -1) {
    openKey = ['sales'];
    selectKey = 'sales-quotation'
  }
  if (pathname.indexOf('/sales/sale-order') > -1) {
    openKey = ['sales'];
    selectKey = 'sales-sale-order'
  }
  if (pathname.indexOf('/sales/invoice') > -1) {
    openKey = ['sales'];
    selectKey = 'sales-invoice'
  }
  if (pathname.indexOf('/sales/report/item-seles') > -1) {
    openKey = ['sales'];
    selectKey = 'item-sale-report'
  }
  if (pathname.indexOf('/sales/report/sale-order-report') > -1) {
    openKey = ['sales'];
    selectKey = 'sale-order-report'
  }


  if (pathname.indexOf('/transporation') > -1) {
    openKey = ['transportation'];
    selectKey = 'transportation';
  }
  if (pathname.indexOf('/transporation/monitor') > -1) {
    openKey = ['transportation'];
    selectKey = 'transportation-monitor';
  }
  if (pathname.indexOf('/transporation/delivery-orders') > -1) {
    openKey = ['transportation'];
    selectKey = 'transportation-order';
  }
  if (pathname.indexOf('/transporation/travel-expenses') > -1) {
    openKey = ['transportation'];
    selectKey = 'travel-expenses';
  }
  if (pathname.indexOf('/transporation/maintenace') > -1) {
    openKey = ['transportation'];
    selectKey = 'transportation-asset-maintenance';
  }
  if (pathname.indexOf('/transporation/report') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'transportation-report';
  }
  if (pathname.indexOf('/transporation/report/resourc-task-summary') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'resourcetasksummary';
  }
  if (pathname.indexOf('/transporation/report/driver-task-summary') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'driver-task-summary';
  }
  if (pathname.indexOf('/transporation/report/task') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'task-report';
  }
  if (pathname.indexOf('/transporation/report/gas-expense') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'gas-expense-report';
  }
  if (pathname.indexOf('/transporation/report/task-assignment') > -1) {
    openKey = ['transportation', 'transportation-report'];
    selectKey = 'task-assignment-report';
  }
  if (pathname.indexOf('/transporation/setting') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'transportation-setting';
  }
  if (pathname.indexOf('/transporation/setting/task-type') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'task-type';
  }
  if (pathname.indexOf('/transporation/setting/task-distance') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'task-distance';
  }
  // if (pathname.indexOf('/transporation/setting/allowance') > -1) {
  //   openKey = ['transportation', 'transportation-setting'];
  //   selectKey = 'allowance';
  // }
  if (pathname.indexOf('/transporation/setting/gasprice') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'gas-price';
  }
  if (pathname.indexOf('/transporation/setting/size') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'parcel-size';
  }
  if (pathname.indexOf('/transporation/setting/freight') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'gas-price-setting';
  }
  if (pathname.indexOf('/transporation/setting/trip-allowance') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'trip-allowance-setting';
  }
  if (pathname.indexOf('/transporation/setting/allowance') > -1) {
    openKey = ['transportation', 'transportation-setting'];
    selectKey = 'allowance-setting';
  }
  if (pathname.indexOf('/transporation/planning') > -1) {
    openKey = ['transportation'];
    selectKey = 'transportation-planning';
  }

  if (pathname.indexOf('/workforce') > -1) {
    openKey = ['workforce'];
    selectKey = 'workforce';
  }
  if (pathname.indexOf('/workforce/monitor') > -1) {
    openKey = ['workforce'];
    selectKey = 'workforce-monitor';
  }
  if (pathname.indexOf('/workforce/travel-expenses') > -1) {
    openKey = ['workforce'];
    selectKey = 'travel-expenses';
  }
  if (pathname.indexOf('/workforce/maintenace') > -1) {
    openKey = ['workforce'];
    selectKey = 'transportation-asset-maintenance';
  }
  if (pathname.indexOf('/workforce/report') > -1) {
    openKey = ['workforce', 'workforce-report'];
    selectKey = 'workforce-report';
  }
  if (pathname.indexOf('/workforce/report/resourc-task-summary') > -1) {
    openKey = ['workforce', 'workforce-report'];
    selectKey = 'workforce-resourcetasksummary';
  }
  if (pathname.indexOf('/workforce/report/task') > -1) {
    openKey = ['workforce', 'workforce-report'];
    selectKey = 'workforce-task-report';
  }
  if (pathname.indexOf('/workforce/report/task-assignment') > -1) {
    openKey = ['workforce', 'workforce-report'];
    selectKey = 'task-assignment-report-w';
  }
  if (pathname.indexOf('/workforce/setting') > -1) {
    openKey = ['workforce', 'workforce-setting'];
    selectKey = 'workforce-setting';
  }
  if (pathname.indexOf('/workforce/setting/task-type') > -1) {
    openKey = ['workforce', 'workforce-setting'];
    selectKey = 'workforce-task-type';
  }

  if (pathname.indexOf('/workdesk') > -1) {
    openKey = ['workdesk'];
    selectKey = 'workdesk';
  }
  if (pathname.indexOf('/workdesk/monitor') > -1) {
    openKey = ['workdesk'];
    selectKey = 'workdesk-monitor';
  }
  if (pathname.indexOf('/workdesk/travel-expenses') > -1) {
    openKey = ['workdesk'];
    selectKey = 'travel-expenses';
  }
  if (pathname.indexOf('/workdesk/maintenace') > -1) {
    openKey = ['workdesk'];
    selectKey = 'transportation-asset-maintenance';
  }
  if (pathname.indexOf('/workdesk/report') > -1) {
    openKey = ['workdesk', 'workdesk-report'];
    selectKey = 'workdesk-report';
  }
  if (pathname.indexOf('/workdesk/report/resourc-task-summary') > -1) {
    openKey = ['workdesk', 'workdesk-report'];
    selectKey = 'workdesk-resourcetasksummary';
  }
  if (pathname.indexOf('/workdesk/report/task') > -1) {
    openKey = ['workdesk', 'workdesk-report'];
    selectKey = 'workdesk-task-report';
  }
  if (pathname.indexOf('/workdesk/setting') > -1) {
    openKey = ['workdesk', 'workdesk-setting'];
    selectKey = 'workdesk-setting';
  }
  if (pathname.indexOf('/workdesk/setting/task-type') > -1) {
    openKey = ['workdesk', 'workdesk-setting'];
    selectKey = 'workdesk-task-type';
  }

  if (pathname.indexOf('/sourcing') > -1) {
    openKey = ['sourcing-procurement'];
    selectKey = 'sourcing-procurement';
  }
  if (pathname.indexOf('/sourcing/vendors') > -1) {
    openKey = ['sourcing-procurement'];
    selectKey = 'sourcing-vendor';
  }
  if (pathname.indexOf('/vendors/vendor-detail/') > -1) {
    openKey = ['sourcing-procurement'];
    selectKey = 'sourcing-vendor';
  }
  if (pathname.indexOf('/sourcing/po') > -1) {
    openKey = ['sourcing-procurement'];
    selectKey = 'sourcing-procurement-po';
  }
  if (pathname.indexOf('/sourcing/report/item-purchase-report') > -1) {
    openKey = ['sourcing', 'sourcing-report'];
    selectKey = 'item-purchase-report';
  }

  if (pathname.indexOf('/inventory') > -1) {
    openKey = ['inventory'];
    selectKey = 'inventory';
  }
  if (pathname.indexOf('/inventory/item-masters') > -1) {
    openKey = ['inventory'];
    selectKey = 'inventory-item-masterdata';
  }
  if (pathname.indexOf('/item-masters/item-detail/') > -1) {
    openKey = ['inventory'];
    selectKey = 'inventory-item-masterdata';
  }
  // if (pathname.indexOf('/inventory/item') > -1) {
  //   openKey = ['inventory'];
  //   selectKey = 'inventory-item';
  // }
  if (pathname.indexOf('/inventory/maintenace') > -1) {
    openKey = ['inventory'];
    selectKey = 'inventory-asset-maintenance';
  }
  if (pathname.indexOf('/inventory/item-request') > -1) {
    openKey = ['inventory'];
    selectKey = 'itemRequest';
  }
  if (pathname.indexOf('/inventory/warehouse-sub') > -1) {
    openKey = ['inventory'];
    selectKey = 'warehouse-sub';
  }
  if (pathname.indexOf('/inventory/setting') > -1) {
    openKey = ['inventory', 'inventory-setting'];
    selectKey = 'inventory-setting';
  }
  if (pathname.indexOf('/inventory/setting/uom') > -1) {
    openKey = ['inventory', 'inventory-setting'];
    selectKey = 'uom';
  }
  if (pathname.indexOf('/inventory/report/instock') > -1) {
    openKey = ['inventory', 'inventory-report'];
    selectKey = 'warehouse-report-instock';
  }
  if (pathname.indexOf('/inventory/report/item-request') > -1) {
    openKey = ['inventory', 'inventory-report'];
    selectKey = 'warehouse-report-itemrequest';
  }
  if (pathname.indexOf('/inventory/report/item-movement') > -1) {
    openKey = ['inventory', 'inventory-report'];
    selectKey = 'item-movement-report';
  }
  if (pathname.indexOf('/inventory/report/receive-item-from-po') > -1) {
    openKey = ['inventory', 'inventory-report'];
    selectKey = 'po-receive-report';
  }
  if (pathname.indexOf('/accounting') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'accounting-finance';
  }
  if (pathname.indexOf('/accounting/invoice') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'accounting-invoice';
  }
  if (pathname.indexOf('/accounting/expense') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'accounting-expense';
  }
  if (pathname.indexOf('/accounting/petty-cash') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'petty-cash';
  }
  if (pathname.indexOf('/accounting/petty-cash/detail/') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'petty-cash';
  }
  if (pathname.indexOf('/accounting/report/receive-payment') > -1) {
    openKey = ['accounting-finance', 'accounting-report'];
    selectKey = 'report-accounting-receive-payment';
  }
  if (pathname.indexOf('/accounting/report/reconcile') > -1) {
    openKey = ['accounting-finance', 'accounting-report'];
    selectKey = 'report-accounting-reconcile';
  }
  if (pathname.indexOf('/notification') > -1) {
    openKey = ['notification'];
    selectKey = 'notification';
  }
  if (pathname.indexOf('/company') > -1) {
    openKey = ['company'];
    selectKey = 'company';
  }
  if (pathname.indexOf('/company/company-profile') > -1) {
    openKey = ['company'];
    selectKey = 'company-profile';
  }
  if (pathname.indexOf('/company/license') > -1) {
    openKey = ['company'];
    selectKey = 'license';
  }

  if (pathname.indexOf('/user-management') > -1) {
    openKey = ['user-management'];
    selectKey = 'user-management';
  }
  if (pathname.indexOf('/user-management/users') > -1) {
    openKey = ['user-management'];
    selectKey = 'users';
  }
  if (pathname.indexOf('/user-management/users/users-detail/') > -1) {
    openKey = ['user-management'];
    selectKey = 'users';
  }
  if (pathname.indexOf('/user-management/my-profile/') > -1) {
    openKey = ['user-management'];
    selectKey = 'users';
  }
  if (pathname.indexOf('/user-management/role') > -1) {
    openKey = ['user-management'];
    selectKey = 'role';
  }
  if (pathname.indexOf('/user-management/permission') > -1) {
    openKey = ['user-management'];
    selectKey = 'permission';
  }
  if (pathname.indexOf('/project') > -1) {
    openKey = ['project'];
    selectKey = 'project';
  }
  if (pathname.indexOf('/project/list') > -1) {
    openKey = ['project'];
    selectKey = 'project-profile';
  }
  if (pathname.indexOf('/project/list/project-detail') > -1) {
    openKey = ['project'];
    selectKey = 'project-profile';
  }
  if (pathname.indexOf('/request') > -1) {
    openKey = ['request'];
    selectKey = 'request';
  }
  if (pathname.indexOf('/request/approval') > -1) {
    openKey = ['request'];
    selectKey = 'approve-request';
  }
  if (pathname.indexOf('/request/myrequest') > -1) {
    openKey = ['request'];
    selectKey = 'my-request';
  }
  if (pathname.indexOf('/ticker/allticket') > -1) {
    openKey = ['ticket'];
    selectKey = 'ticket';
  }
  if (pathname.indexOf('/ticker/allticket') > -1) {
    openKey = ['ticket'];
    selectKey = 'ticket-all';
  }
  if (pathname.indexOf('/ticker/allticket') > -1) {
    openKey = ['ticket', 'ticket-report'];
    selectKey = 'repair-ticket-report';
  }
  if (pathname.indexOf('/campaign') > -1) {
    openKey = ['campaign'];
    selectKey = 'campaign';
  }
  if (pathname.indexOf('/campaign/point') > -1) {
    openKey = ['campaign'];
    selectKey = 'point';
  }
  if (pathname.indexOf('/inventory/receiveItemfromPO') > -1) {
    openKey = ['inventory'];
    selectKey = 'receive-item-po';
  }
  if (pathname.indexOf('/account/account-receivable') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'account-receivable';
  }

  if (pathname.indexOf('/account/account-receivable-report') > -1) {
    openKey = ['accounting-finance'];
    selectKey = 'accounting-receivable-report';
  }

  if (pathname.indexOf('/campaign/voucher') > -1) {
    openKey = ['campaign'];
    selectKey = 'voucher';
  }
  if (pathname.indexOf('/inventory/tranferitem') > -1) {
    openKey = ['inventory'];
    selectKey = 'warehouse-transfer';
  }

  if (pathname.indexOf('/crm/allorder') > -1) {
    openKey = ['crm'];
    selectKey = 'order-report';
  }

  if (pathname.indexOf('/crm/invoice') > -1) {
    openKey = ['crm'];
    selectKey = 'crm-invoice';
  }

  if (pathname.indexOf('/crm/report/item-seles') > -1) {
    openKey = ['crm', 'customer-report'];
    selectKey = 'item-sale-report';
  }

  if (pathname.indexOf('/crm/report/sale-order-report') > -1) {
    openKey = ['crm', 'customer-report'];
    selectKey = 'sale-order-report';
  }

  if (pathname.indexOf('/dashboard-taego/overview') > -1) {
    openKey = ['dashboard-taego'];
    selectKey = 'dashboard-taego-overview';
  }



  // if (pathname.indexOf('/warehouse-parent') > -1) {
  //   openKey = ['warehouse-parent'];
  //   selectKey = 'warehouse-parent';
  // }


  // else if(pathname.indexOf('/user-management/users') > -1) {
  //   openKey = 'User Management';
  //   selectKey = 'User Management-User';
  // }
  // else if(pathname.indexOf('/user-management/users/users-detail') > -1) {
  //   openKey = 'User Management';
  //   selectKey = 'User Management-User';
  // }
  // else if(pathname.indexOf('/user-management/my-profile') > -1) {
  //   openKey = 'User Management';
  //   selectKey = 'User Management-User';
  // }
  // else if(pathname.indexOf('/user-management/role') > -1) {
  //   openKey = 'User Management';
  //   selectKey = 'User Management-Role';
  // }
  // else if(pathname.indexOf('/user-management/permission') > -1) {
  //   openKey = 'User Management';
  //   selectKey = 'User Management-Permission';
  // }
  // else if(pathname.indexOf('/organization') > -1) {
  //   openKey = 'Organization';
  //   selectKey = 'Organization';
  // }
  // else if(pathname.indexOf('/customer') > -1) {
  //   openKey = 'Customer';
  //   selectKey = 'Customer';
  // }
  // else if(pathname.indexOf('/monitor') > -1) {
  //   openKey = 'Monitor';
  //   selectKey = 'Monitor';
  // }
  // else if(pathname.indexOf('/report/time-attendance') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Report-Time Attendance';
  // }
  // else if(pathname.indexOf('/report/overt-time') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Report-Report';
  // }
  // else if(pathname.indexOf('/report/leave') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Report-Leave Report';
  // }
  // else if(pathname.indexOf('/report/outside') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Outside-Report';
  // }
  // else if(pathname.indexOf('/report/task') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Task-Report';
  // }
  // else if(pathname.indexOf('/report/travel') > -1) {
  //   openKey = 'Report';
  //   selectKey = 'Travel-Report';
  // }
  // else if(pathname.indexOf('/notification') > -1) {
  //   openKey = 'Notification';
  //   selectKey = 'Notification';
  // }
  // else if(pathname.indexOf('/settings/request-type') > -1) {
  //   openKey = 'Settings';
  //   selectKey = 'RequestType';
  // }
  // else if(pathname.indexOf('/settings/shift-type') > -1) {
  //   openKey = 'Settings';
  //   selectKey = 'ShiftType';
  // }
  // else if(pathname.indexOf('/settings/task-type') > -1) {
  //   openKey = 'Settings';
  //   selectKey = 'TaskType';
  // }
  // else if(pathname.indexOf('/settings/holidays') > -1) {
  //   openKey = 'Settings';
  //   selectKey = 'holidays';
  // }
  // else if(pathname.indexOf('/invoice') > -1) {
  //   openKey = 'Invoice';
  //   selectKey = 'Invoice';
  // }

  return {
    openKey,
    selectKey,
  };
};

export { dd, useQuery, checkDefaultPath };
