import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';

import _ from 'lodash';

import LabeRequireForm from '../../../../components/label-required-form';
import FilterSetting from './component/filterSetting';
import ModalProjectEditMemeber from './component/modal-edit';
import TableProjectDetail from '../component/table';
import { deleteMember, getMember } from '../../../../controllers/project-new/member';

const MemberProjectDetail = (props) => {
  const { Option } = Select;

  const {
    id,
    trigger,
    setTrigger,
    listData,
    listTotal,
    setSearchMemberValue,
    searchMemberValue,
    handleSearchMember,
    handleSubmitSearch,
    handleResetSearch,
  } = props;

  const intl = useIntl();
  const [search, setSearch] = useState();
  const [projectMemberId, setProjectMemberId] = useState(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [okEdit, setOkEdit] = useState(false);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [cancelDelete, setCancelDelete] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      default: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsName', defaultMessage: 'Name' }),
      dataIndex: 'member_detail.name',
      key: 'member_detail.name',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => {
        const name = _.get(record, 'member_detail.name', '');
        const lastName = _.get(record, 'member_detail.lastName', '');

        return <>{name && name !== '-' ? `${name} ${lastName || ''}`.trim() : name || 'N/A'}</>;
      },
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsMemberType', defaultMessage: 'Member Type' }),
      dataIndex: 'member_type',
      key: 'member_type',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => _.get(record, 'member_type', '-') || '-',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEmployAndType', defaultMessage: 'Employee or Team Type' }),
      dataIndex: 'member_detail.type',
      key: 'member_detail.type',
      sorter: true,
      default: true,
      width: 150,
      render: (text, record, index) => _.get(record, 'member_detail.type', '-') || '-',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsPhone', defaultMessage: 'Phone' }),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      default: true,
      width: 150,
      ellipsis: true,
      render: (text, record, index) => _.get(record, 'phone', '-') || '-',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEmail', defaultMessage: 'Email' }),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      default: true,
      width: 250,
      ellipsis: true,
      render: (text, record, index) => _.get(record, 'email', '-') || '-',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsPriority', defaultMessage: 'Priority' }),
      dataIndex: 'priority',
      key: 'priority',
      sorter: true,
      default: false,
      width: 150,
      render: (text, record, index) => _.get(record, 'priority') || _.get(record, '-'),
    },
  ];

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
  };

  const handleVisibleEdit = (value) => {
    setVisibleEdit(true);
  };

  const handleOkEdit = (value) => {
    setOkEdit(true);
    setVisibleEdit(false);
  };

  const handleCancelEdit = (value) => {
    setCancelEdit(true);
    setVisibleEdit(false);
  };

  const handleVisibleDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleCancelDelete = (value) => {
    setCancelDelete(true);
    setVisibleDelete(false);
  };

  const handleDelete = async () => {
    const response = await deleteMember({ projectId: id, projectMemberId });
    console.log('deleteMember', response);

    setTrigger((prev) => !prev);
    setVisibleDelete(false);
  };

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              setProjectMemberId(record.project_member_id);
              handleVisibleEdit();
            }}
          >
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button
            style={{ width: 100 }}
            type="link"
            ghost
            onClick={() => {
              setProjectMemberId(record.project_member_id);
              handleVisibleDelete();
            }}
          >
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <FilterSetting
        search={search}
        onFilterOrder={onFilterOrder}
        setSearch={setSearch}
        searchMemberValue={searchMemberValue}
        handleSearchMember={handleSearchMember}
        handleSubmitSearch={handleSubmitSearch}
        handleResetSearch={handleResetSearch}
      />
      <TableProjectDetail
        columns={columns}
        listData={listData}
        listTotal={listTotal}
        contentAction={contentAction}
        onRow={true}
        data={listData}
        searchMemberValue={searchMemberValue}
        setSearchMemberValue={setSearchMemberValue}
      />
      <ModalProjectEditMemeber
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
        setTrigger={setTrigger}
        projectId={id}
        projectMemberId={projectMemberId}
      />
      <Modal visible={visibleDelete} footer={null} centered closable={false} onOk={handleDelete} onCancel={handleCancelDelete} width="20%">
        <div style={{ textAlign: 'center' }}>
          <p>Do you want to delete?</p>
          <div style={{ marginTop: '20px' }}>
            <Button style={{ marginRight: '10px', color: '#6490CF', border: '1px solid #6490CF' }} onClick={handleCancelDelete}>
              Close
            </Button>
            <Button style={{ backgroundColor: '#6490CF' }} type="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MemberProjectDetail;
