import React, { useState, useContext } from 'react';
import { ProjectContext } from '../context';
import { useIntl, FormattedMessage } from 'react-intl';
import { Icon, Dropdown, Select, Input, Button, Menu, Checkbox } from 'antd';
import Button01 from '../../../components/v2/button_01';
import _ from 'lodash';

const { Option } = Select;

const ActionProject = (props) => {
  const intl = useIntl();
  const {
    columns,
    setShowColumn,
    setShowColumnArr,
    newDataColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    columnNewSearch,
    setScrollTableX,
  } = props;
  const { state, setState, fnc } = useContext(ProjectContext);
  const { fliterByColumn, fieldChange } = state;
  const { setFieldChange, setFliterByColumn } = setState;

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');

  const [visible, setVisible] = useState(false);

  const ignoreColumn = ['index', 'projectNo', 'projectName', 'projectType'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function (result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    if (_.size(setNewArr) > 9) {
      setScrollTableX(2500);
    } else if (_.size(setNewArr) > 8) {
      setScrollTableX(2000);
    } else if (_.size(setNewArr) > 7) {
      setScrollTableX(1500);
    } else if (_.size(setNewArr) < 8) {
      setScrollTableX(1000);
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(1000);
  };

  const onChangeSearch = (value) => {
    setFieldChange(value);
  };

  const handleSelect = (value) => {
    setFliterByColumn(value);
  };

  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item
          style={{
            color: '#FF1C00',
            textAlign: 'left',
            backgroundColor: '#ffffff',
          }}
        >
          {textErrorSelectColumn}
        </Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="asset-action-search-all">
      <div style={{display: 'flex'}}>
        <Input
          className="user-management-users-input action-input"
          placeholder={intl.formatMessage({
            id: 'hintSearch',
            defaultMessage: 'Search',
          })}
          prefix={<Icon style={{ color: 'rgba(0,0,0,.25)' }} type="search" />}
          onChange={(e) => onChangeSearch(e.target.value)}
          autoComplete="off"
          value={fieldChange}
          defaultValue=""
        />
        <Select
          className="styledAppSelect action-select"
          placeholder={intl.formatMessage({
            id: 'hintSelectNew',
            defaultMessage: 'Select',
          })}
          onSelect={(value) => handleSelect(value)}
          defaultValue="all"
          value={fliterByColumn}
        >
          <Option value="all">
            <FormattedMessage id="reportOutsideReportHintSeleteAllColumns" defaultMessage="All columns" />
          </Option>
          {columnNewSearch.map((item, index) => (
            <Option key={index} value={item.key}>
              {item.title}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Dropdown
          className="list-view-dropdown"
          trigger={['click']}
          placement="bottomRight"
          overlay={menuColumn}
          onVisibleChange={handleVisibleChange}
          visible={visible}
        >
          <Button01 type="primary" btnsize="wd_at" fontSize="sm" onClick={handleOnVisiblecolumns} className="style-buttun-columns">
            <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" /> <Icon type="down" />
          </Button01>
        </Dropdown>
      </div>
    </div>
  );
};

export default ActionProject;
