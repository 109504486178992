import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getTeacoMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/getTeacoMarket`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export {
  getTeacoMarket,
}