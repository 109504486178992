import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tag, Modal } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer } from 'react-sticky';
import Button01 from '../../components/v2/button_01';
import PurchaseOrderTable from './table';
import './index.css';
import PurchaseCreate from './modal/create/index';
import EditPurchase from './modal/edit/index';
import ViewPurchase from './modal/view/index';
import { PurchaseContext } from './po-context';
import getCustomerVenderApi from '../../controllers/getCustomer/get-customer-vender';
import { getStatusList, searchPo, getStatusPaymentList, } from '../../controllers/po/po';
import _ from 'lodash';
import { updateStatusPo, getTaxList, getDeliveryType, getVehicleList, getMemberIssueBy } from '../../controllers/po/po';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import PurchaseOrderAction from './action';
import moment from 'moment';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { getTeamOrg } from '../../controllers/team/team';
import { getConfigMenu } from '../../controllers/get-config-menu/get-config-menu-api';

const { TabPane } = Tabs;

const PerchesOrder = () => {
  const intl = useIntl();

  // const tab = [
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleDraft`, defaultMessage: 'Draft' }),
  //     code: 'draft'
  //   },
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleWaiting`, defaultMessage: 'Waiting' }),
  //     code: 'waiting'
  //   },
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleApproved`, defaultMessage: 'Approved' }),
  //     code: 'approved'
  //   },
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleClosed`, defaultMessage: 'Closed' }),
  //     code: 'closed'
  //   },
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleRejected`, defaultMessage: 'Rejected' }),
  //     code: 'rejected'
  //   },
  //   {
  //     name: intl.formatMessage({ id: `purchesOrderTitleCanceled`, defaultMessage: 'Canceled' }),
  //     code: 'canceled'
  //   },
  // ]

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      align: 'center',
      width: 70,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsPONo" defaultMessage="PO No." />,
      dataIndex: 'poNo',
      key: 'poNo',
      fixed: 'left',
      width: 180,
      sorter: true,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsVendorOrSup" defaultMessage="Vendor or Supplier" />,
      dataIndex: 'vendorSupplier',
      key: 'vendorSupplier',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsItem" defaultMessage="Item" />,
      dataIndex: 'itemAmount',
      key: 'itemAmount',
      sorter: true,
      width: 100,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsTotalPrice" defaultMessage="Total Price" />,
      dataIndex: 'totolPrice',
      key: 'totolPrice',
      sorter: true,
      align: 'right',
      width: 180,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsReferenceNo" defaultMessage="Reference No." />,
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      sorter: true,
      width: 180,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      width: 400,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsTeam" defaultMessage="Team" />,
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      width: 400,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsIssuedBy" defaultMessage="Issued By" />,
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsIssuedDatepo" defaultMessage="Issued Date" />,
      dataIndex: 'poDate',
      key: 'poDate',
      sorter: true,
      width: 150,
      render: (text, record, index) => moment(_.get(record, 'poDate'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: <FormattedMessage id="purchesOrderColumnsCreatedDate" defaultMessage="Created Date" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      width: 150,
    },
  ];

  const totalPaid = {
    title: <FormattedMessage id="purchesOrderColumnsPaidAmount" defaultMessage="Paid Amount" />,
    dataIndex: 'totolPaid',
    key: 'totolPaid',
    sorter: true,
    align: 'right',
    width: 180,
    render: (text, record, index) => numberFormatter(_.get(record, 'totolPaid', 0)),
  };

  const accPayment = {
    title: <FormattedMessage id="purchesOrderColumnsAccruedAmount" defaultMessage="Accrued Amount" />,
    dataIndex: 'totolAccruedPo',
    key: 'totolAccruedPo',
    sorter: true,
    align: 'right',
    width: 180,
    render: (text, record, index) => numberFormatter(_.get(record, 'totolAccruedPo', 0)),
  };

  const statusReceive = {
    title: <FormattedMessage id="purchesOrderColumnsReceive" defaultMessage="Received" />,
    dataIndex: 'statusReceiveCode',
    key: 'statusReceiveCode',
    sorter: true,
    width: 180,
    render: (text, record, index) => (
      <div style={{ textAlign: 'center', display: 'flex' }}>
        <Tag className="tag-center-style" color={record.statusReceiveColor}>
          {record.statusReceiveName}
        </Tag>
      </div>
    ),
  };

  const statusPayment = {
    title: <FormattedMessage id="purchesOrderColumnsPayment" defaultMessage="Payment" />,
    dataIndex: 'statusPaymentCode',
    key: 'statusPaymentCode',
    sorter: true,
    width: 180,
    render: (text, record, index) => (
      <div style={{ textAlign: 'center', display: 'flex' }}>
        <Tag className="tag-center-style" color={record.statusPaymentColor}>
          {record.statusPaymentName}
        </Tag>
      </div>
    ),
  };

  const reason = {
    title: <FormattedMessage id="purchesOrderColumnsReason" defaultMessage="Reason" />,
    dataIndex: 'statusLogClosed',
    key: 'statusLogClosed',
    width: 200,
    render: (text, record, index) => _.get(record, 'statusLogClosed.reason'),
  };

  const setShowColumn = {
    index: true,
    poNo: true,
    vendorSupplier: true,
    itemAmount: true,
    totolPrice: true,
    totolPaid: true,
    totolAccruedPo: true,
    team: true,
    referenceNo: true,
    poDate: true,
    statusName: true,
    createdBy: true,
    remark: true,
    createdDate: true,
  };

  const setShowColumnStatus = {
    statusReceiveCode: true,
    statusPaymentCode: true,
  };

  const setShowColumnArr = [
    'index',
    'poNo',
    'vendorSupplier',
    'itemAmount',
    'totolPrice',
    'totolPaid',
    'totolAccruedPo',
    'team',
    'referenceNo',
    'poDate',
    'createdBy',
    'remark',
    'createdDate',
  ];

  const setShowColumnArrStatus = ['statusReceiveCode', 'statusPaymentCode'];

  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [keyTabMonitor, setKeyTabMonitor] = useState('draft');
  const [tabAllMenu, setTabAllMenu] = useState({});
  const [nextStatus, setNextStatus] = useState(['wating', 'deleted']);
  const [tab, setTab] = useState([]);
  const [searchGroup, setSearchGroup] = useState({});
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [actionColumns, setActionColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [total, setTotal] = useState(0);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [trigger, setTrigger] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [taxList, setTaxList] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [optionsIssueBy, setOptionsIssueBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [countWaiting, setCountWaiting] = useState(0);

  console.log('responseMenu', tabAllMenu, nextStatus, tab);

  useEffect(() => {
    const getDataVender = async () => {
      const responseMenu = await getConfigMenu('PO_status_wf');
      const response = await getCustomerVenderApi(10000);
      const statusData = await getStatusPaymentList();
      const taxData = await getTaxList();
      const deliType = await getDeliveryType();
      const vehicleList = await getVehicleList({ searchVal: '', indexStart: 0, indexEnd: 1000 });
      const getTeam = await getTeamOrg();
      const getIssueBy = await getMemberIssueBy({});

      const tempTax = _.map(_.get(taxData, 'data.data'), (i) => {
        if (i.taxPercent === -7) {
          return {
            ...i,
            taxPercentTxt: <FormattedMessage id={`IncludeVAT`} defaultMessage="In VAT" />,
          };
        } else {
          return { ...i };
        }
      });

      setTab(_.get(responseMenu, 'statusMap'));
      setTabAllMenu(_.get(responseMenu, 'config_val'));
      setVehicleList(_.get(vehicleList, 'data.assetList'));
      setVendorData(_.get(response, 'customers'));
      setStatusList(_.get(statusData, 'data.data'));
      // setTaxList(_.get(taxData, 'data.data'));
      setTaxList(tempTax);
      setDeliveryType(_.get(deliType, 'data.data'));
      setOptionsTeam(_.get(getTeam, 'data'));
      setOptionsIssueBy(getIssueBy || []);
    };
    getDataVender();
  }, []);

  useEffect(() => {
    const SearchData = async () => {
      setLoading(true);
      const body = {
        page: paginationPage,
        limit: paginationSize,
        statusPayment: _.get(searchGroup, 'status') || [],
        referenceNo: _.get(searchGroup, 'refNo') || '',
        statusCode: [keyTabMonitor],
        // "statusGroup": keyTabMonitor,
        poNo: _.get(searchGroup, 'poNo') || '',
        createdDateFrom: _.get(searchGroup, 'createDate[0]') ? moment(_.get(searchGroup, 'createDate[0]')).format('YYYY-MM-DD') : '',
        createdDateTo: _.get(searchGroup, 'createDate[1]') ? moment(_.get(searchGroup, 'createDate[1]')).format('YYYY-MM-DD') : '',
        vendorSupplierId: _.get(searchGroup, 'vendor') || [],

        team: _.get(searchGroup, 'team') ? [`${_.get(searchGroup, 'team')}`] : undefined,
        issuedBy: _.get(searchGroup, 'issuedBy') || undefined,
        issueDateFrom: _.get(searchGroup, 'issuedDate[0]') ? moment(_.get(searchGroup, 'issuedDate[0]')).format('YYYY-MM-DD') : '',
        issueDateTo: _.get(searchGroup, 'issuedDate[1]') ? moment(_.get(searchGroup, 'issuedDate[1]')).format('YYYY-MM-DD') : '',

        orderBy: fieldSort,
        orderType: orderSort,
      };
      const reesponse = await searchPo(body);
      setPoList(_.get(reesponse, 'data.data.poList'));
      setCountWaiting(_.get(reesponse, 'data.data.countWaitingPO'));
      setTotal(_.get(reesponse, 'data.data.totalItem'));
      setLoading(false);
    };
    SearchData();
  }, [fieldSort, orderSort, keyTabMonitor, paginationPage, paginationSize, trigger]);

  useEffect(() => {
    callback('draft');
  }, []);

  useEffect(() => {
    if(tabAllMenu && keyTabMonitor) setNextStatus(tabAllMenu[keyTabMonitor])
  }, [keyTabMonitor, tabAllMenu])

  const callback = (key) => {
    console.log('callback', key, tabAllMenu[key])

    if (key === 'draft') {
      const updatedColumns = _.filter(columns, (item) => item.key !== 'createdDate');

      setNewDataColumns(updatedColumns);
      setListArrayColumns(setShowColumnArr);
      setDefaultShowColumn(setShowColumn);
      setActionColumns(updatedColumns);
    } else if (key === 'approved') {
      let newArray = _.filter(newDataColumns, (item) => {
        return item.key !== 'statusLogClosed';
      });
      const filterStatus = _.filter(newArray, (item) => {
        return item.key === 'statusReceiveCode' || item.key === 'statusPaymentCode';
      });
      if (_.size(filterStatus) === 0) {
        newArray.splice(4, 0, statusReceive);
        newArray.splice(5, 0, statusPayment);
        newArray.splice(7, 0, totalPaid);
        newArray.splice(8, 0, accPayment);

        const createdDateColumn = _.find(columns, { key: 'createdDate' });
        if (createdDateColumn && !newArray.some((col) => col.key === 'createdDate')) {
          newArray.splice(newArray.length, 0, createdDateColumn);
        }

        setNewDataColumns(newArray);
        setListArrayColumns(setShowColumnArr.concat(setShowColumnArrStatus));
        setDefaultShowColumn({ ...setShowColumn, ...setShowColumnStatus });
        setActionColumns(newArray);
      } else {
        const concatList = setShowColumnArr.concat(setShowColumnArrStatus);
        const cloneList = { ...setShowColumn, ...setShowColumnStatus };
        setNewDataColumns(newArray);
        setListArrayColumns(concatList);
        setDefaultShowColumn(cloneList);
        setActionColumns(newArray);
      }
    } else if (key === 'torevise') {
      let newArray = newDataColumns;
      const filterStatus = _.filter(newArray, (item) => {
        return item.key === 'statusReceiveCode' || item.key === 'statusPaymentCode';
      });
      const filterReason = _.filter(newArray, (item) => {
        return item.key === 'statusLogClosed';
      });

      if (_.size(filterStatus) === 0 && _.size(filterReason) === 0) {
        newArray.splice(4, 0, statusReceive);
        newArray.splice(5, 0, statusPayment);
        newArray.splice(7, 0, totalPaid);
        newArray.splice(8, 0, accPayment);
        newArray.splice(9, 0, reason);

        const createdDateColumn = _.find(columns, { key: 'createdDate' });
        if (createdDateColumn && !newArray.some((col) => col.key === 'createdDate')) {
          newArray.splice(newArray.length, 0, createdDateColumn);
        }

        const concatList = setShowColumnArr.concat(setShowColumnArrStatus);
        const cloneList = { ...setShowColumn, ...setShowColumnStatus };
        setNewDataColumns(newArray);
        setListArrayColumns(concatList.concat(['statusLogClosed']));
        setDefaultShowColumn({ ...cloneList, statusLogClosed: true });
        setActionColumns(newArray);
      } else if (_.size(filterReason) === 0) {
        newArray.splice(9, 0, reason);
        setNewDataColumns(newArray);
        setListArrayColumns(listArrayColumns.concat(['statusLogClosed']));
        setDefaultShowColumn({ ...defaultShowColumn, statusLogClosed: true });
        setActionColumns(newArray);
      }
    } else {
      setNewDataColumns(columns);
      setListArrayColumns(setShowColumnArr);
      setDefaultShowColumn(setShowColumn);
      setActionColumns(columns);
    }
    setKeyTabMonitor(key);
    setPoList([]);
    setPaginationPage(1);
    setSearchGroup({});
  };

  const handleOpenModalCreate = () => {
    setVisibleCreate(true);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleEdit = (data, action) => {
    setDefaultData(data);
    if (action === 'edit') setVisibleEdit(true);
    if (action === 'view') setVisibleView(true);
    // if (data.statusCode === 'draft') setVisibleEdit(true);
    // if (data.statusCode !== 'draft') setVisibleView(true);
  };

  const handleEditModal = () => {
    setVisibleEdit(true)
    // setVisibleView(false)
  }

  const actionSaveToStatus = () => { // เผื่อไว้สำหรับย้ายแทบ
    setVisibleView(false)
  }

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await updateStatusPo(id, {
          statusCode: 'deleted',
          remark: '',
          reason: '',
          actionBy: localStorage.getItem('memComId'),
        });
        if (response.status === 200) {
          successNotification(_.get(response, 'data.status.message'));
          setTrigger((cur) => !cur);
        } else {
          errorNotification(_.get(response, 'data.status.message'));
        }
      },
      onCancel() {},
    });
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P56PG1C1', 'P56PG1C1A1') ? (
          <PurchaseContext.Provider
            value={{
              searchGroup,
              setSearchGroup,
              setPaginationPage,
              columns,
              setShowColumn,
              newDataColumns,
              listArrayColumns,
              defaultShowColumn,
              newColumns,
              setNewDataColumns,
              setListArrayColumns,
              setDefaultShowColumn,
              textErrorSelectColumn,
              setTextErrorSelectColumn,
              vendorData,
              statusList,
              poList,
              handleChange,
              paginationPage,
              total,
              handleEdit,
              handleDelete,
              loading,
              keyTabMonitor,
              visible,
              setVisible,
              setTrigger,
              actionColumns,
              optionsTeam,
              optionsIssueBy,
              tab
            }}
          >
            <Card bodyStyle={{ padding: 'unset', height: 'calc(100vh - 110px)' }}>
              <StickyContainer>
                {_.size(tab) > 0 ? (
                  <Tabs
                    className="po-tabs"
                    size="large"
                    defaultActiveKey="1"
                    onChange={callback}
                    animated={false}
                    tabBarExtraContent={
                      checkPermissionAction('P56PG1C1', 'P56PG1C1A3') ? (
                        <div className="po-extra-tabs">
                          <Button01 btnsize="wd_at" type="primary" style={{ width: '101px' }} onClick={handleOpenModalCreate}>
                            <FormattedMessage id={`purchesOrderTitleCreate`} defaultMessage="Create" />
                          </Button01>
                        </div>
                      ) : null
                    }
                  >
                    {_.map(tab, (item) => (
                      <TabPane tab={intl.formatMessage({ id: `purchesOrderTitleTab${item.code}`, defaultMessage: item.code })
                      // + ' ' + (item.code === 'waiting' ? `(${countWaiting})` : '')
                      } key={item.code}>
                        <PurchaseOrderTable />
                      </TabPane>
                    ))}
                  </Tabs>
                ) : null}
              </StickyContainer>
              <PurchaseCreate
                visible={visibleCreate}
                setVisible={setVisibleCreate}
                setTrigger={setTrigger}
                initial={{
                  vendorData,
                  taxList,
                  deliveryType,
                  vehicleList,
                }}
              />
              <EditPurchase
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                setTrigger={setTrigger}
                defaultData={defaultData}
                initial={{
                  vendorData,
                  taxList,
                  deliveryType,
                  vehicleList,
                }}
                nextStatus={nextStatus}
                actionSaveToStatus={actionSaveToStatus}
              />
              <ViewPurchase
                visible={visibleView}
                setVisible={setVisibleView}
                setTrigger={setTrigger}
                defaultData={defaultData}
                setDefaultData={setDefaultData}
                keyTabMonitor={keyTabMonitor}
                nextStatus={nextStatus}
                handleEdit={handleEditModal}
              />
            </Card>
          </PurchaseContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default PerchesOrder;
