import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/getFiles`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/uploadFile`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const upDateAttachment = async (payload) => {
  try {
    const response = await httpClient.put(`/v3/project/manager/${comCode}/update`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/remove`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  getAttachment,
  uploadAttachment,
  upDateAttachment,
  deleteAttachment,
}