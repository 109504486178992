import { Card } from "antd";
import React, { useState } from "react";
import ItemAssignee from "./component/item-assignee";

const DashBoardAssignee = (props) => {

  const { data } = props

  return (
    <div style={{height: '100%', }}>
    <Card
      style={{ 
        height: "95vh", 
        padding: 10, 
        // overflowY: "auto",
        borderRadius: '8px',
      }}
      bodyStyle={{ padding: "unset" }}
    >
      {data?.map((item, index) => (
        <ItemAssignee
          key={item.queueNo}
          id={item.queueNo}
          lot={item.from}
          assignee={item.assignee}
          time={item.minutesPast}
          bgColor={item.statusColor}
          bgMap={index % 2 === 0}
        />
      ))}
    </Card>
    </div>
  )
}

export default DashBoardAssignee