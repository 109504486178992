import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');

const getReceivePOData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/receiveitempo/report`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getReceiveItemGroup = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itemgroup/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getReceiveItemType = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v4/item/manager/company/${comCode}/item-type/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const createReceivePOData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/receiveitempo`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getItemPOID = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/warehouse/receiveitempo/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const updateTicket = async (payload, id) => {
  try {
    const response = await httpClient.put(`/v1/ticket/manager/company/${comCode}/web/ticket/${id}`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getNumberType = async (id) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/warehouse/manager/company/${comCode}/web/movement/numbertpye`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};


const getWareHouseListData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/warehouse/manager/company/${comCode}/web/warehouse/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getItemPOList = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/poheader/waitingtoreceive`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getItemReceivelist = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/podetail/waitingtoreceive`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getStatusPurchase = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.get(`/v1/po/manager/company/${comCode}/postatus/waitingtoreceive`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getBrandListData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itembrand/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const getModelListData = async (payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v5/item/manager/company/${comCode}/web/itemmodel/loadmore`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.responase;
  }
};

const updateCancel = async (id) => {
  const comCode = localStorage.getItem('comCode');
  const url = `${process.env.REACT_APP_URL_MANAGER}/warehouse-manager/v1/warehouse/manager/company/${comCode}/web/warehouse/cancel-receiveitempo/${id}`
 return url
};


export {
  getWareHouseListData,
  getReceivePOData,
  getNumberType,
  getItemPOList,
  getStatusPurchase,
  getItemReceivelist,
  createReceivePOData,
  getReceiveItemGroup,
  getReceiveItemType,
  getItemPOID,
  getBrandListData,
  getModelListData,
  updateCancel,
};
